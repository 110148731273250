import React, { useEffect } from "react";
import { IconButton, Button } from "react-native-paper";

export interface RoutineChipProps {
  updateRoutine?: (objectId: string, routineId: string) => void;
}

interface Props extends RoutineChipProps {
  objectId: string;
  routineId?: string;
  chipsAreEditable?: boolean;
}

export const RoutineChip: React.FC<Props> = ({ objectId, routineId, chipsAreEditable, updateRoutine }) => {
  const [askToUpdate, setAskToUpdate] = React.useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (askToUpdate) {
      interval = setTimeout(() => {
        setAskToUpdate(false);
      }, 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [askToUpdate]);

  return routineId ? (
    <>
      <IconButton
        icon="autorenew"
        size={16}
        iconColor="white"
        mode="contained"
        style={{ backgroundColor: "#aaa" }}
        onPress={
          (chipsAreEditable ?? true) && updateRoutine
            ? () => {
                setAskToUpdate(!askToUpdate);
              }
            : undefined
        }
      />
      {askToUpdate ? (
        <Button
          onPress={
            (chipsAreEditable ?? true) && updateRoutine
              ? () => {
                  updateRoutine(objectId, routineId);
                  setAskToUpdate(false);
                }
              : undefined
          }
        >
          Update Routine
        </Button>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};
