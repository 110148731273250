import React from "react";
import { formatSecondsTimeSimple, SECONDS_IN_A_TIMEBLOCK } from "frontend-shared/util/dateUtils";
import { DropDownSelectMenu } from "./dropDownSelectMenu";

interface Props {
  label: string;
  timeBlockNumber: number;
  setTimeBlockNumber: (timeBlockNumber: number) => void;
}

export const TimeSelectMenu: React.FC<Props> = ({ label, timeBlockNumber, setTimeBlockNumber }) => {
  return (
    <DropDownSelectMenu
      label={label}
      index={timeBlockNumber}
      setIndex={setTimeBlockNumber}
      count={48}
      iconSource={"clock-time-nine-outline"}
      menuLabel={(index) => formatSecondsTimeSimple(index * SECONDS_IN_A_TIMEBLOCK)}
    />
  );
};
