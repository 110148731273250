import React from "react";
import { Button, Menu } from "react-native-paper";
import { formatSecondsAsTimeCompact } from "frontend-shared/util/dateUtils";
import { TimerRunningState } from "frontend-shared/util/timerRunningState";
import { TimerConsumer } from "./timerConsumer";
import { TimerState } from "frontend-shared/store/timerSlice";

interface Props {
  timerState: TimerState;
}

export const TimerControlButtons: React.FC<Props> = ({ timerState }) => {
  const [timerMenuVisible, setTimerMenuVisible] = React.useState(false);

  const showPauseTimer = timerState.timerRunningState === TimerRunningState.Running;
  const showResumeTimer = timerState.timerRunningState === TimerRunningState.Paused;
  const showTimerMenu = timerState.timerRunningState !== TimerRunningState.Stopped;

  return (
    <TimerConsumer>
      {(startTimer, pauseTimer, endTimer, resetTimer) => (
        <>
          {showTimerMenu && (
            <>
              <Menu
                visible={timerMenuVisible}
                onDismiss={() => setTimerMenuVisible(false)}
                anchorPosition="bottom"
                anchor={
                  <Button icon="timer-outline" textColor="white" onPress={() => setTimerMenuVisible(true)}>
                    {formatSecondsAsTimeCompact(timerState.secondsLeftOnTimer)}
                  </Button>
                }
              >
                {showPauseTimer && (
                  <Menu.Item
                    onPress={() => {
                      pauseTimer(timerState);
                      setTimerMenuVisible(false);
                    }}
                    title="Pause Timer"
                  />
                )}
                {showResumeTimer && (
                  <Menu.Item
                    onPress={() => {
                      startTimer(timerState);
                      setTimerMenuVisible(false);
                    }}
                    title="Resume Timer"
                  />
                )}
                <Menu.Item
                  onPress={() => {
                    resetTimer();
                    setTimerMenuVisible(false);
                  }}
                  title="Reset Timer"
                />
                <Menu.Item
                  onPress={() => {
                    endTimer();
                    setTimerMenuVisible(false);
                  }}
                  title="End Timer"
                />
              </Menu>
            </>
          )}
        </>
      )}
    </TimerConsumer>
  );
};
