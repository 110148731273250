import React from "react";
import { FAB } from "react-native-paper";

interface Props {
  icon: string;
  label: string;
  extended?: boolean;
  onPress: () => void;
}

export const BottomFab: React.FC<Props> = ({ icon, label, onPress, extended }) => {
  return (
    <FAB style={{ position: "absolute", right: "16px", bottom: "16px" }} icon={icon} label={extended ?? true ? label : undefined} onPress={onPress} />
  );
};
