import React, { useEffect, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import { PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { TimeCardEmbedded, TimeCardProps } from "./timeCardEmbedded";
import { isLastTimeCardInFilter, timeCardInFilter } from "./timeCardsUtils";

export interface TimeCardsProps extends TimeCardProps {
  taskGroups: PlanTaskGroup[];
  roleViewCompleted: string;
  onMoveTaskGroup: (sourceIndex: number, destinationIndex: number) => void;
}

export const TimeCards: React.FC<TimeCardsProps> = (props) => {
  const { taskGroups, roleViewCompleted, onMoveTaskGroup } = props;
  const outerDivRef = useRef<HTMLDivElement>(null);
  const [tableWidth, setTableWidth] = useState<number>(0);

  const updateWidth = () => {
    if (outerDivRef.current) {
      setTableWidth(outerDivRef.current.offsetWidth);
    }
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [taskGroups]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    onMoveTaskGroup(sourceIndex, destinationIndex);
  };

  return (
    <div style={{ flex: 1, flexGrow: 1, overflowY: "auto", overflowX: "hidden", height: "100%" }} ref={outerDivRef}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="timeCards">
          {(provided) => (
            <table ref={provided.innerRef} {...provided.droppableProps} style={{ width: "100%", flex: 1, flexGrow: 1, tableLayout: "fixed" }}>
              {taskGroups.map((taskGroup, index) => {
                return (
                  <Draggable key={taskGroup.id} draggableId={taskGroup.id} index={index}>
                    {(provided, snapshot) => (
                      <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <td style={{ ...(snapshot.isDragging && { width: `${tableWidth}px` }), maxWidth: "100%" }}>
                          {timeCardInFilter(taskGroup, roleViewCompleted) ? (
                            <div
                              style={{
                                ...{ flex: 1, flexGrow: 1, width: "100%" },
                                ...styles.itemSeparator,
                                ...(snapshot.isDragging && styles.dragging),
                                ...(!snapshot.isDragging && isLastTimeCardInFilter(taskGroup, taskGroups, roleViewCompleted) && styles.lastTimeCard),
                              }}
                            >
                              <TimeCardEmbedded {...props} taskGroup={taskGroup} isDragging={snapshot.isDragging} />
                            </div>
                          ) : (
                            <div style={{ height: 0 }} />
                          )}
                        </td>
                      </tr>
                    )}
                  </Draggable>
                );
              })}

              {provided.placeholder}
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingVertical: 10,
  },
  itemSeparator: {
    marginBottom: 6,
  },
  lastTimeCard: {
    marginBottom: 64,
  },
  dragging: {
    opacity: 0.9,
    transform: "rotate(2deg)",
  },
});
