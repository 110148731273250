import { hasIncompleteTasksOrEmpty } from "frontend-shared/store/todayPageStateUtils";
import { PlanTaskGroup } from "frontend-shared/util/modelTypes";

export const timeCardInFilter = (taskGroup: PlanTaskGroup, roleViewCompleted: string) => {
  return roleViewCompleted !== "Hide" || hasIncompleteTasksOrEmpty(taskGroup);
};

export const previousTimeCardIndexInFilter = (taskGroup: PlanTaskGroup, taskGroups: PlanTaskGroup[], roleViewCompleted: string) => {
  let returnNext = false;
  for (let i = taskGroups.length; i > 0; i--) {
    if (taskGroups[i - 1].id === taskGroup.id) {
      returnNext = true;
    } else if (timeCardInFilter(taskGroups[i - 1], roleViewCompleted) && returnNext) {
      return i - 1;
    }
  }
  return -1;
};

export const nextTimeCardIndexInFilter = (taskGroup: PlanTaskGroup, taskGroups: PlanTaskGroup[], roleViewCompleted: string) => {
  let returnNext = false;
  for (let i = 0; i < taskGroups.length; i++) {
    if (taskGroups[i].id === taskGroup.id) {
      returnNext = true;
    } else if (timeCardInFilter(taskGroups[i], roleViewCompleted) && returnNext) {
      return i;
    }
  }
  return -1;
};

export const isFirstTimeCardInFilter = (taskGroup: PlanTaskGroup, taskGroups: PlanTaskGroup[], roleViewCompleted: string) => {
  for (let i = 0; i < taskGroups.length; i++) {
    if (taskGroups[i].id === taskGroup.id) {
      return true;
    } else if (timeCardInFilter(taskGroups[i], roleViewCompleted)) {
      return false;
    }
  }
  return false;
};

export const isLastTimeCardInFilter = (taskGroup: PlanTaskGroup, taskGroups: PlanTaskGroup[], roleViewCompleted: string) => {
  for (let i = taskGroups.length; i > 0; i--) {
    if (taskGroups[i - 1].id === taskGroup.id) {
      return true;
    } else if (timeCardInFilter(taskGroups[i - 1], roleViewCompleted)) {
      return false;
    }
  }
  return false;
};
