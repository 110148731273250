import React from "react";
import { Card, IconButton, Text } from "react-native-paper";
import { PlanRoleIdentity } from "frontend-shared/util/modelTypes";
import { EditIdentityDialog } from "./editIdentityDialog";
import { textColorForBackgroundColor } from "frontend-shared/util/colorUtils";

export interface IdentityCardProps {
  editIdentity: (identity: PlanRoleIdentity) => void;
  archiveIdentity: (identity: PlanRoleIdentity) => void;
  scoreboardButtonTapped: (identity: PlanRoleIdentity) => void;
}

interface Props extends IdentityCardProps {
  identity: PlanRoleIdentity;
}

export const IdentityCard: React.FC<Props> = ({ identity, editIdentity, archiveIdentity, scoreboardButtonTapped }) => {
  const [editDialogVisible, setEditDialogVisible] = React.useState(false);
  return (
    <>
      <Card
        key={identity.id}
        onPress={(event) => setEditDialogVisible(true)}
        style={{ marginLeft: "16px", marginRight: "16px", backgroundColor: identity.color }}
      >
        <Card.Content style={{ justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
          <Text style={{ color: textColorForBackgroundColor(identity.color) }} variant="headlineSmall">
            {identity.name}
          </Text>
          <IconButton
            size={32}
            icon="chart-bar"
            iconColor={textColorForBackgroundColor(identity.color)}
            onPress={() => scoreboardButtonTapped(identity)}
          />
        </Card.Content>
      </Card>
      <EditIdentityDialog
        visible={editDialogVisible}
        identity={identity}
        save={(editedIdentity) => {
          editIdentity({ ...identity, ...editedIdentity });
          setEditDialogVisible(false);
        }}
        archiveIdentity={() => {
          archiveIdentity(identity);
          setEditDialogVisible(false);
        }}
        cancel={() => setEditDialogVisible(false)}
      />
    </>
  );
};
