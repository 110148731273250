import React from "react";
import { PlanRoleIdentity } from "frontend-shared/util/modelTypes";
import { ScrollView, View } from "react-native";
import { IdentityCard, IdentityCardProps } from "./identityCard";

export interface IdentityListProps extends IdentityCardProps {
  identities: PlanRoleIdentity[];
}

export const IdentitiesList: React.FC<IdentityListProps> = (props) => {
  const { identities } = props;
  return (
    <ScrollView style={{ marginTop: "4px", flexGrow: 1 }} contentContainerStyle={{ gap: "4px" }}>
      {identities
        .filter((identity) => !identity.archived)
        .map((identity) => (
          <IdentityCard key={identity.id} identity={identity} {...props} />
        ))}
      <View style={{ height: 50 }} />
    </ScrollView>
  );
};
