import React from "react";
import { Portal, Snackbar } from "react-native-paper";
import { clearSnackbarState, selectSnackbar } from "frontend-shared/store/appSnackbarSlice";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";

export const MobileSnackbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const snackbarState = useAppSelector(selectSnackbar);
  return (
    <Portal>
      <Snackbar visible={snackbarState.open} onDismiss={() => dispatch(clearSnackbarState())}>
        {snackbarState.text}
      </Snackbar>
    </Portal>
  );
};
