import React from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";

interface Props {
  visible: boolean;
}

export const PortalDialogOverlay: React.FC<Props> = ({ visible }) => {
  return <>{visible && <View style={styles.fullScreenSemiTransparent}></View>}</>;
};

const styles = StyleSheet.create({
  fullScreenSemiTransparent: { position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0, 0, 0, 0.75)" },
});
