import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { selectTimerState, setTimerRunningState, setTimerTimeCard, timerEnd, TimerState } from "frontend-shared/store/timerSlice";
import { timerEffectDispatch } from "frontend-shared/store/timerEffect";
import { TimerRunningState } from "frontend-shared/util/timerRunningState";
import { TimerContext } from "./timerContext";
import { setTimerCookies } from "frontend-shared/cookies/todayPageCookies";
import { EndTimerPromptDialog } from "../planTabScreen/endTimerPromptDialog";
import { apiIncrementCompletedTimeBlockCount } from "frontend-shared/api/today.api.taskGroup";
import { selectTodayPageState } from "frontend-shared/store/todayPageSlice";
import { useApolloClient } from "@apollo/client";
import { selectSettingsState } from "frontend-shared/store/settingsSlice";
import { selectIdentitiesState } from "frontend-shared/store/identitiesSlice";
import { selectBacklogState } from "frontend-shared/store/backlogSlice";
import { selectRoutinesState } from "frontend-shared/store/routinesSlice";

export const TimerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [endTimerDialogOpen, setEndTimerDialogOpen] = React.useState(false);

  const client = useApolloClient();

  const dispatch = useAppDispatch();
  const timerState = useAppSelector(selectTimerState);
  const todayPageState = useAppSelector(selectTodayPageState);
  const settingsState = useAppSelector(selectSettingsState);
  const identitiesState = useAppSelector(selectIdentitiesState);
  const backlogState = useAppSelector(selectBacklogState);
  const routinesState = useAppSelector(selectRoutinesState);

  const apiContext = { client, todayPageState, backlogState, routinesState, settingsState, identitiesState, dispatch };

  // Timer
  useEffect(() => {
    return timerEffectDispatch(timerState.timerRunningState, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerState.timerRunningState]);

  useEffect(() => {
    if (timerState.timerRunningState === TimerRunningState.Running) {
      if (timerState.secondsLeftOnTimer === 0 && !endTimerDialogOpen) {
        setEndTimerDialogOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerState.timerRunningState, timerState.secondsLeftOnTimer, endTimerDialogOpen]);

  const audioRef = React.useRef<HTMLAudioElement>(null);
  const tickingSoundFile = "/sound/timer-ticking.mp3";
  const dingSoundFile = "/sound/timer-ding.mp3";

  setTimerCookies(timerState);

  const playTickingSound = () => {
    if (audioRef?.current) {
      audioRef.current.loop = true;
      audioRef.current.src = tickingSoundFile;
      audioRef.current.play();
    }
  };

  const playDingSound = () => {
    if (audioRef?.current) {
      audioRef.current.loop = false;
      audioRef.current.src = dingSoundFile;
      audioRef.current.play();
    }
  };

  if (timerState.timerRunningState !== TimerRunningState.Running) {
    if (audioRef?.current) {
      const playing = !audioRef.current.paused;
      if (playing && audioRef.current.loop) {
        playDingSound();
      }
    }
  }

  const startTimer = React.useCallback(
    (timerState: TimerState, timeCard?: { id: string; name: string }) => {
      if (timerState.timerRunningState !== TimerRunningState.Running) {
        if (timeCard) {
          dispatch(setTimerTimeCard({ timeCard }));
        }
        dispatch(setTimerRunningState({ timerRunningState: TimerRunningState.Running }));
        playTickingSound();
      }
    },
    [dispatch]
  );

  const pauseTimer = React.useCallback(
    (timerState: TimerState) => {
      if (timerState.timerRunningState === TimerRunningState.Running) {
        dispatch(setTimerRunningState({ timerRunningState: TimerRunningState.Paused }));
      }
      audioRef.current?.pause();
    },
    [dispatch]
  );

  const endTimer = React.useCallback(() => {
    dispatch(timerEnd({ dateISOString: new Date().toISOString() }));
  }, [dispatch]);

  const resetTimer = React.useCallback(() => {
    dispatch(setTimerRunningState({ timerRunningState: TimerRunningState.Stopped }));
    audioRef.current?.pause();
  }, [dispatch]);

  const contextValue = React.useMemo(
    () => ({
      startTimer,
      pauseTimer,
      endTimer,
      resetTimer,
    }),
    [startTimer, pauseTimer, endTimer, resetTimer]
  );

  return (
    <TimerContext.Provider value={contextValue}>
      <audio key="timerProviderAudio" ref={audioRef} src={tickingSoundFile} loop />
      {children}
      <EndTimerPromptDialog
        open={endTimerDialogOpen}
        onClose={() => setEndTimerDialogOpen(false)}
        timeCard={timerState.timeCard}
        markTimeBlockAsCompleted={() => {
          if (timerState.timeCard) {
            apiIncrementCompletedTimeBlockCount(apiContext, timerState.timeCard.id);
          }
        }}
        startTimerOnTimeCard={() => {
          startTimer(timerState);
        }}
      />
    </TimerContext.Provider>
  );
};
