// LoginScreen.tsx
import React, { useState } from "react";
import { View, Image } from "react-native";
import { Text } from "react-native-paper";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../App";
import { TextInput, Button } from "react-native-paper";
import { useLoginMutation } from "frontend-shared/generated/graphql";
import { setAccessToken, setSiteAdmin } from "frontend-shared/util/auth";
import { useAppDispatch } from "frontend-shared/store/hooks";
import { clearIdentitiesState } from "frontend-shared/store/identitiesSlice";
import Cookies from "js-cookie";
import { LAST_TAB_COOKIE } from "./bottomTabs";

interface Props {
  navigation: StackNavigationProp<RootStackParamList, "Login">;
}

export const LoginScreen: React.FC<Props> = ({ navigation }) => {
  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
  });

  const { email, password } = loginInput;
  const [login] = useLoginMutation();
  const [loginError, setLoginError] = useState(false);

  const dispatch = useAppDispatch();

  const handleLogin = async () => {
    try {
      const response = await login({
        variables: {
          email,
          password,
        },
      });
      if (response && response.data) {
        setLoginError(false);
        setAccessToken(response.data.login.accessToken);
        setSiteAdmin(response.data.login.isSiteAdmin);
        dispatch(clearIdentitiesState());

        // get the last tab from the cookie and use it to navigate to the correct tab
        const lastTab = Cookies.get(LAST_TAB_COOKIE);
        if (!lastTab || lastTab === "Identities") {
          navigation.replace("RTG", { screen: "Identities", params: { screen: "IdentitiesMain" } });
        } else if (lastTab === "Plans") {
          navigation.replace("RTG", { screen: "Plans" });
        }
        return;
      }
    } catch {
      setLoginError(true);
      return;
    }
  };

  return (
    <View style={{ backgroundColor: "white", flex: 1, gap: "8px", justifyContent: "center", alignItems: "center" }}>
      <Image
        style={{
          width: 200,
          height: 100,
        }}
        source={{ uri: "/rtg-logo.png" }}
        resizeMode="contain"
        alt="RTG logo"
      />
      {loginError && <Text>Login incorrect</Text>}
      <TextInput
        id="user-text-field"
        autoCapitalize="none"
        label="Username"
        value={email}
        onChangeText={(text) => setLoginInput({ ...loginInput, email: text })}
        autoComplete="username"
      />
      <TextInput
        id="password-text-field"
        label="Password"
        value={password}
        onChangeText={(text) => setLoginInput({ ...loginInput, password: text })}
        secureTextEntry
        autoComplete="current-password"
      />
      <Button onPress={handleLogin}>Login</Button>
    </View>
  );
};
