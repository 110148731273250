import React from "react";
import { View } from "react-native";
import { Button, Dialog, Portal, TextInput, IconButton } from "react-native-paper";
import { DEFAULT_MAX_TIME_BLOCK_COUNT, EditablePlanRoleIdentity, emptyEditablePlanRoleIdentity } from "frontend-shared/util/modelTypes";
import { isHexColor, textColorForBackgroundColor } from "frontend-shared/util/colorUtils";
import { PortalDialogOverlay } from "../components/portalDialogOverlay";
import { ColorPickerDialog } from "../planTabScreen/colorPickerDialog";

export interface IdentityDialogProps {
  visible: boolean;
  cancel: () => void;
  save: (identity: EditablePlanRoleIdentity) => void;
  archiveIdentity?: () => void;
}

export interface Props extends IdentityDialogProps {
  title: string;
  identity: EditablePlanRoleIdentity;
  saveButtonLabel: string;
}

export const IdentityDialog: React.FC<Props> = ({ title, identity, visible, cancel, save, archiveIdentity, saveButtonLabel }) => {
  const [newIdentity, setNewIdentity] = React.useState(identity);
  const [paceText, setPaceText] = React.useState(newIdentity.weeklyPaceTimeBlockCount.toString());
  const [initialized, setInitialized] = React.useState(false);
  const [colorPickerVisible, setColorPickerVisible] = React.useState(false);

  if (!visible && initialized) {
    setNewIdentity(emptyEditablePlanRoleIdentity());
    setInitialized(false);
    return <></>;
  } else if (visible && !initialized) {
    setNewIdentity(identity);
    setInitialized(true);
    return <></>;
  }

  const isValidInputs = () => {
    const pace = parseInt(paceText);
    return newIdentity.name.length > 0 && isHexColor(newIdentity.color) && !isNaN(pace) && pace >= 0 && pace <= DEFAULT_MAX_TIME_BLOCK_COUNT * 7;
  };

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={cancel}>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Content style={{ gap: "8px" }}>
          <TextInput label="Name" value={newIdentity.name} onChangeText={(text) => setNewIdentity({ ...newIdentity, name: text })} />
          <View style={{ flexDirection: "row", gap: 8 }}>
            <TextInput
              label="Color"
              value={newIdentity.color}
              onChangeText={(text) => setNewIdentity({ ...newIdentity, color: text })}
              style={{ flex: 1 }}
            />
            <IconButton
              icon="palette"
              mode="contained"
              onPress={() => setColorPickerVisible(true)}
              iconColor={textColorForBackgroundColor(newIdentity.color)}
              style={{ backgroundColor: newIdentity.color }}
            />
          </View>
          <TextInput label="Weekly Time Block Pace" value={paceText} onChangeText={(text) => setPaceText(text)} />
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={cancel}>Cancel</Button>
          {archiveIdentity && <Button onPress={() => archiveIdentity()}>Archive</Button>}
          <Button
            mode="contained"
            onPress={() => {
              save({ ...newIdentity, weeklyPaceTimeBlockCount: parseInt(paceText) });
            }}
            disabled={!isValidInputs()}
          >
            {saveButtonLabel}
          </Button>
        </Dialog.Actions>
      </Dialog>
      <ColorPickerDialog
        visible={colorPickerVisible}
        onDismiss={() => setColorPickerVisible(false)}
        onConfirm={(color) => {
          setNewIdentity({ ...newIdentity, color });
          setColorPickerVisible(false);
        }}
      />
    </Portal>
  );
};
