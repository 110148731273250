import React from "react";
import { Button, Dialog, Portal } from "react-native-paper";
import { PortalDialogOverlay } from "../components/portalDialogOverlay";
import { DropDownSelectMenu } from "../components/dropDownSelectMenu";
import { CommunityForScreen } from "frontend-shared/util/modelTypes";

interface Props {
  visible: boolean;
  communities: CommunityForScreen[];
  selectedCommunityIndex: number;
  onDismiss: () => void;
  onConfirm: (communityIndex: number) => void;
}

export const CommunityPickerDialog: React.FC<Props> = ({ visible, communities, selectedCommunityIndex, onDismiss, onConfirm }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(selectedCommunityIndex);

  // Reset selection when dialog opens
  React.useEffect(() => {
    if (visible) {
      setSelectedIndex(selectedCommunityIndex);
    }
  }, [visible, selectedCommunityIndex, communities]);

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={onDismiss}>
        <Dialog.Title>Share with Community</Dialog.Title>
        <Dialog.Content style={{ gap: "8px" }}>
          <DropDownSelectMenu
            label="Community"
            index={selectedIndex}
            count={communities.length}
            setIndex={setSelectedIndex}
            menuLabel={(index) => communities[index]?.name ?? ""}
            iconSource="account-group"
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={onDismiss}>Cancel</Button>
          <Button
            mode="contained"
            onPress={() => {
              onConfirm(selectedIndex);
            }}
          >
            OK
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
