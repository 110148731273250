import React, { useEffect, useState } from "react";
import { createMaterialBottomTabNavigator } from "react-native-paper/react-navigation";
import { createStackNavigator } from "@react-navigation/stack";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { useNavigationState } from "@react-navigation/native";
import Cookies from "js-cookie";

import { BacklogStateLoader } from "frontend-shared/store/backlogStateLoader";
import { IdentitiesStateLoader } from "frontend-shared/store/identitiesStateLoader";
import { RoutinesStateLoader } from "frontend-shared/store/routinesStateLoader";
import { SettingsStateLoader } from "frontend-shared/store/settingsStateLoader";

import { IdentityTabScreen } from "./identityTabScreen";
import { PlanTabScreen } from "./planTabScreen";
import { CommunitiesStackParamList, IdentitiesStackParamList, PlansStackParamList } from "../App";
import { IdentityScoreboardScreen } from "./identityTabScreen/identityScoreboardScreen";
import { useTheme } from "react-native-paper";
import { TimeCardScreen } from "./planTabScreen/timeCardScreen";
import { PlanTabScreenHeaderButtons } from "./planTabScreenHeaderButtons";
import { TimeCardScreenHeaderButtons } from "./planTabScreen/timeCardScreen/timeCardScreenHeaderButtons";
import { CommunityTabScreen } from "./communityTabScreen";
import { IdentityTabScreenHeaderButtons } from "./identityTabScreenHeaderButtons";
import { CommunitiesStateLoader } from "frontend-shared/store/communitiesStateLoader";
import { CommunityTabScreenHeaderButtons } from "./communityTabScreenHeaderButtons";
import { requestCommunitiesScreenFullAllResults } from "frontend-shared/generated/graphqlWrappers";
import { useApolloClient } from "@apollo/client";
import { initializeUserCommunitiesFullState } from "frontend-shared/store/userCommunitiesSlice";
import { useAppDispatch } from "frontend-shared/store/hooks";
import { DEFAULT_COOKIE_EXPIRATION } from "frontend-shared/cookies/todayPageCookies";

const Tab = createMaterialBottomTabNavigator();
const IdentitiesStack = createStackNavigator<IdentitiesStackParamList>();
const PlansStack = createStackNavigator<PlansStackParamList>();
const CommunitiesStack = createStackNavigator<CommunitiesStackParamList>();

interface Props {}

export const LAST_TAB_COOKIE = "lastActiveTab";

const IdentitiesNavigator = () => {
  const theme = useTheme();
  return (
    <IdentitiesStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: true,
        headerStyle: {
          backgroundColor: theme.colors.primary,
        },
        headerTintColor: theme.colors.onPrimary,
        headerTitleStyle: {
          fontSize: theme.fonts.headlineSmall.fontSize,
          fontFamily: theme.fonts.headlineSmall.fontFamily,
          fontWeight: theme.fonts.headlineSmall.fontWeight,
        },
      }}
    >
      <IdentitiesStack.Screen
        name="IdentitiesMain"
        component={IdentityTabScreen}
        options={{
          title: "RTG",
          headerRight: () => <IdentityTabScreenHeaderButtons />,
        }}
      />
      <IdentitiesStack.Screen
        name="IdentityScoreboard"
        component={IdentityScoreboardScreen}
        options={({ route }) => ({
          title: route.params?.identity.name || "Scoreboard",
        })}
      />
    </IdentitiesStack.Navigator>
  );
};

const PlansNavigator = () => {
  const theme = useTheme();
  return (
    <PlansStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: true,
        headerStyle: {
          backgroundColor: theme.colors.primary,
        },
        headerTintColor: theme.colors.onPrimary,
        headerTitleStyle: {
          fontSize: theme.fonts.headlineSmall.fontSize,
          fontFamily: theme.fonts.headlineSmall.fontFamily,
          fontWeight: theme.fonts.headlineSmall.fontWeight,
        },
      }}
    >
      <PlansStack.Screen
        name="PlansMain"
        component={PlanTabScreen}
        options={{
          title: "RTG",
          headerRight: () => <PlanTabScreenHeaderButtons />,
        }}
      />
      <PlansStack.Screen
        name="TimeCard"
        component={TimeCardScreen}
        options={({ route }) => ({
          title: route.params?.taskGroup.name || "Time Card",
          headerRight: () => <TimeCardScreenHeaderButtons taskGroupId={route.params?.taskGroup?.id} taskGroupName={route.params?.taskGroup.name} />,
        })}
      />
    </PlansStack.Navigator>
  );
};

const CommunityNavigator = () => {
  const theme = useTheme();
  return (
    <CommunitiesStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: true,
        headerStyle: {
          backgroundColor: theme.colors.primary,
        },
        headerTintColor: theme.colors.onPrimary,
        headerTitleStyle: {
          fontSize: theme.fonts.headlineSmall.fontSize,
          fontFamily: theme.fonts.headlineSmall.fontFamily,
          fontWeight: theme.fonts.headlineSmall.fontWeight,
        },
      }}
    >
      <CommunitiesStack.Screen
        name="CommunitiesMain"
        component={CommunityTabScreen}
        options={{
          title: "Communities",
          headerRight: () => <CommunityTabScreenHeaderButtons />,
        }}
      />
    </CommunitiesStack.Navigator>
  );
};

export const BottomTabs: React.FC<Props> = () => {
  const [initialTab, setInitialTab] = useState<string | null>(null);
  const navigationState = useNavigationState((state) => state);
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  // Load initial tab
  useEffect(() => {
    const tab = Cookies.get(LAST_TAB_COOKIE);
    if (tab === "Identities" || tab === "Plans" || tab === "Communities") {
      setInitialTab(tab);
    } else {
      setInitialTab("Identities");
    }
  }, []);

  // Save tab changes
  useEffect(() => {
    if (navigationState?.routes?.length > 0) {
      const momentumRoute = navigationState.routes[navigationState.index];
      if (momentumRoute.state?.index !== undefined) {
        const currentTab = momentumRoute.state?.routes[momentumRoute.state?.index]?.name;
        if (currentTab) {
          Cookies.set(LAST_TAB_COOKIE, currentTab, { expires: DEFAULT_COOKIE_EXPIRATION });
          if (currentTab === "Communities") {
            requestCommunitiesScreenFullAllResults(
              client,
              {},
              (data) => {
                dispatch(initializeUserCommunitiesFullState({ data }));
              },
              () => {}
            );
          }
        }
      }
    }
  }, [navigationState, client, dispatch]);

  if (!initialTab) {
    return <></>;
  }

  return (
    <>
      <Tab.Navigator initialRouteName={initialTab}>
        <Tab.Screen
          key="Identities"
          name="Identities"
          component={IdentitiesNavigator}
          options={{
            tabBarLabel: "Identities",
            tabBarIcon: ({ color }) => <MaterialCommunityIcons name="account-details" color={color} size={26} />,
          }}
        />
        <Tab.Screen
          key="Plans"
          name="Plans"
          component={PlansNavigator}
          options={{
            tabBarLabel: "Plans",
            tabBarIcon: ({ color }) => <MaterialCommunityIcons name="calendar-today" color={color} size={26} />,
          }}
        />
        <Tab.Screen
          key="Communities"
          name="Communities"
          component={CommunityNavigator}
          options={{
            tabBarLabel: "Communities",
            tabBarIcon: ({ color }) => <MaterialCommunityIcons name="account-group" color={color} size={26} />,
          }}
        />
      </Tab.Navigator>
      <BacklogStateLoader />
      <IdentitiesStateLoader />
      <RoutinesStateLoader />
      <SettingsStateLoader />
      <CommunitiesStateLoader />
    </>
  );
};
