import React from "react";
import { EditablePlanTask, PlanTask } from "frontend-shared/util/modelTypes";
import { View } from "react-native-web";
import { Checkbox, Text } from "react-native-paper";
import { EditTaskDialog } from "./timeCardScreen/editTaskDialog";

export interface TaskProps {
  toggleCheckbox: (task: PlanTask) => void;
  editTask: (originalTask: PlanTask, task: EditablePlanTask) => void;
  deleteTask: (originalTask: PlanTask, task: EditablePlanTask) => void;
}

interface Props extends TaskProps {
  task: PlanTask;
}

export const TaskWithCheckbox: React.FC<Props> = (props) => {
  const { task, toggleCheckbox, editTask } = props;
  const [editTaskDialogVisible, setEditTaskDialogVisible] = React.useState(false);
  return (
    <View key={task.id} style={{ flexDirection: "row", gap: "8px", alignItems: "flex-start" }}>
      <View style={{ transform: [{ scale: 1.3 }] }}>
        <Checkbox status={task.completed ? "checked" : "unchecked"} onPress={() => toggleCheckbox(task)} />
      </View>
      <Text
        style={{ flexWrap: "wrap", flexShrink: 1, marginTop: 5 }}
        selectable={false}
        onPress={() => setEditTaskDialogVisible(true)}
        variant="bodyLarge"
      >
        {task.name}
      </Text>
      <EditTaskDialog
        task={task}
        visible={editTaskDialogVisible}
        cancel={() => setEditTaskDialogVisible(false)}
        save={(editedTask) => {
          editTask(task, editedTask);
          setEditTaskDialogVisible(false);
        }}
        deleteTask={(editedTask) => props.deleteTask(task, editedTask)}
      />
    </View>
  );
};
