import React from "react";
import { Menu, Chip, Icon, IconButton } from "react-native-paper";
import { PlanRoleIdentity } from "frontend-shared/util/modelTypes";

export interface IdentityChipProps {
  roleIdentities: PlanRoleIdentity[];
}

interface Props extends IdentityChipProps {
  objectId: string;
  roleIdentityId?: string;
  setRoleIdentityFor: (objectId: string, roleIdentity?: PlanRoleIdentity) => void;
  chipsAreEditable?: boolean;
}

export const IdentityChip: React.FC<Props> = ({ roleIdentities, setRoleIdentityFor, objectId, roleIdentityId, chipsAreEditable }) => {
  const [identityMenuVisible, setIdentityMenuVisible] = React.useState(false);

  const identity = roleIdentities.find((roleIdentity) => roleIdentity.id === roleIdentityId);
  const identityIconSource = roleIdentityId ? "account-circle-outline" : "account-off";
  const identityText = identity ? identity.name : undefined;
  const identityColor = identity ? identity.color : undefined;

  return (
    <Menu
      anchor={
        identityText ? (
          <Chip
            style={{ backgroundColor: identityColor }}
            icon={() => <Icon size={16} source={identityIconSource} color="white" />}
            onPress={chipsAreEditable ?? true ? () => setIdentityMenuVisible(true) : undefined}
          >
            {identityText}
          </Chip>
        ) : (
          (chipsAreEditable ?? true) && (
            <IconButton
              size={16}
              icon={identityIconSource}
              iconColor="white"
              mode="contained"
              style={{ backgroundColor: "#aaa" }}
              onPress={() => (chipsAreEditable ?? true ? setIdentityMenuVisible(true) : null)}
            />
          )
        )
      }
      visible={identityMenuVisible}
      onDismiss={() => setIdentityMenuVisible(false)}
    >
      <Menu.Item
        title={"<unassigned>"}
        onPress={() => {
          if (roleIdentityId) {
            setRoleIdentityFor(objectId, undefined);
          }
          setIdentityMenuVisible(false);
        }}
      />
      {roleIdentities
        .filter((identity) => !identity.archived)
        .map((roleIdentity) => (
          <Menu.Item
            key={roleIdentity.id}
            title={roleIdentity.name}
            onPress={() => {
              if (roleIdentityId !== roleIdentity.id) {
                setRoleIdentityFor(objectId, roleIdentity);
              }
              setIdentityMenuVisible(false);
            }}
          />
        ))}
    </Menu>
  );
};
