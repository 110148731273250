export const momentumTheme = {
  description: "Momentum theme",
  seed: "#2C4D76",
  coreColors: {
    primary: "#2C4D76",
  },
  extendedColors: [],
  schemes: {
    light: {
      primary: "#3A608F",
      surfaceTint: "#3A608F",
      onPrimary: "#FFFFFF",
      primaryContainer: "#D3E3FF",
      onPrimaryContainer: "#001C39",
      secondary: "#545F71",
      onSecondary: "#FFFFFF",
      secondaryContainer: "#D8E3F8",
      onSecondaryContainer: "#111C2B",
      tertiary: "#6D5677",
      onTertiary: "#FFFFFF",
      tertiaryContainer: "#F6D9FF",
      onTertiaryContainer: "#261430",
      error: "#BA1A1A",
      onError: "#FFFFFF",
      errorContainer: "#FFDAD6",
      onErrorContainer: "#410002",
      background: "#F8F9FF",
      onBackground: "#191C20",
      surface: "#F8F9FF",
      onSurface: "#191C20",
      surfaceVariant: "#DFE2EB",
      onSurfaceVariant: "#43474E",
      outline: "#73777F",
      outlineVariant: "#C3C6CF",
      shadow: "#000000",
      scrim: "#000000",
      inverseSurface: "#2E3035",
      inverseOnSurface: "#EFF0F7",
      inversePrimary: "#A4C9FE",
      primaryFixed: "#D3E3FF",
      onPrimaryFixed: "#001C39",
      primaryFixedDim: "#A4C9FE",
      onPrimaryFixedVariant: "#204876",
      secondaryFixed: "#D8E3F8",
      onSecondaryFixed: "#111C2B",
      secondaryFixedDim: "#BCC7DB",
      onSecondaryFixedVariant: "#3C4758",
      tertiaryFixed: "#F6D9FF",
      onTertiaryFixed: "#261430",
      tertiaryFixedDim: "#D9BDE3",
      onTertiaryFixedVariant: "#543F5E",
      surfaceDim: "#D9DAE0",
      surfaceBright: "#F8F9FF",
      surfaceContainerLowest: "#FFFFFF",
      surfaceContainerLow: "#F2F3FA",
      surfaceContainer: "#EDEDF4",
      surfaceContainerHigh: "#E7E8EE",
      surfaceContainerHighest: "#E1E2E9",
      elevation: {
        level0: "transparent",
        level1: "rgb(242, 244, 251)",
        level2: "rgb(235, 240, 248)",
        level3: "rgb(228, 235, 245)",
        level4: "rgb(226, 233, 244)",
        level5: "rgb(221, 230, 242)",
      },
    },
    "light-medium-contrast": {
      primary: "#1B4472",
      surfaceTint: "#3A608F",
      onPrimary: "#FFFFFF",
      primaryContainer: "#5276A7",
      onPrimaryContainer: "#FFFFFF",
      secondary: "#394354",
      onSecondary: "#FFFFFF",
      secondaryContainer: "#6A7587",
      onSecondaryContainer: "#FFFFFF",
      tertiary: "#503B5A",
      onTertiary: "#FFFFFF",
      tertiaryContainer: "#846C8E",
      onTertiaryContainer: "#FFFFFF",
      error: "#8C0009",
      onError: "#FFFFFF",
      errorContainer: "#DA342E",
      onErrorContainer: "#FFFFFF",
      background: "#F8F9FF",
      onBackground: "#191C20",
      surface: "#F8F9FF",
      onSurface: "#191C20",
      surfaceVariant: "#DFE2EB",
      onSurfaceVariant: "#3F434A",
      outline: "#5B5F67",
      outlineVariant: "#777B83",
      shadow: "#000000",
      scrim: "#000000",
      inverseSurface: "#2E3035",
      inverseOnSurface: "#EFF0F7",
      inversePrimary: "#A4C9FE",
      primaryFixed: "#5276A7",
      onPrimaryFixed: "#FFFFFF",
      primaryFixedDim: "#385D8D",
      onPrimaryFixedVariant: "#FFFFFF",
      secondaryFixed: "#6A7587",
      onSecondaryFixed: "#FFFFFF",
      secondaryFixedDim: "#525D6E",
      onSecondaryFixedVariant: "#FFFFFF",
      tertiaryFixed: "#846C8E",
      onTertiaryFixed: "#FFFFFF",
      tertiaryFixedDim: "#6A5474",
      onTertiaryFixedVariant: "#FFFFFF",
      surfaceDim: "#D9DAE0",
      surfaceBright: "#F8F9FF",
      surfaceContainerLowest: "#FFFFFF",
      surfaceContainerLow: "#F2F3FA",
      surfaceContainer: "#EDEDF4",
      surfaceContainerHigh: "#E7E8EE",
      surfaceContainerHighest: "#E1E2E9",
      elevation: {
        level0: "transparent",
        level1: "rgb(242, 244, 251)",
        level2: "rgb(235, 240, 248)",
        level3: "rgb(228, 235, 245)",
        level4: "rgb(226, 233, 244)",
        level5: "rgb(221, 230, 242)",
      },
    },
    "light-high-contrast": {
      primary: "#002344",
      surfaceTint: "#3A608F",
      onPrimary: "#FFFFFF",
      primaryContainer: "#1B4472",
      onPrimaryContainer: "#FFFFFF",
      secondary: "#182332",
      onSecondary: "#FFFFFF",
      secondaryContainer: "#394354",
      onSecondaryContainer: "#FFFFFF",
      tertiary: "#2E1B37",
      onTertiary: "#FFFFFF",
      tertiaryContainer: "#503B5A",
      onTertiaryContainer: "#FFFFFF",
      error: "#4E0002",
      onError: "#FFFFFF",
      errorContainer: "#8C0009",
      onErrorContainer: "#FFFFFF",
      background: "#F8F9FF",
      onBackground: "#191C20",
      surface: "#F8F9FF",
      onSurface: "#000000",
      surfaceVariant: "#DFE2EB",
      onSurfaceVariant: "#20242B",
      outline: "#3F434A",
      outlineVariant: "#3F434A",
      shadow: "#000000",
      scrim: "#000000",
      inverseSurface: "#2E3035",
      inverseOnSurface: "#FFFFFF",
      inversePrimary: "#E3ECFF",
      primaryFixed: "#1B4472",
      onPrimaryFixed: "#FFFFFF",
      primaryFixedDim: "#002D56",
      onPrimaryFixedVariant: "#FFFFFF",
      secondaryFixed: "#394354",
      onSecondaryFixed: "#FFFFFF",
      secondaryFixedDim: "#222D3D",
      onSecondaryFixedVariant: "#FFFFFF",
      tertiaryFixed: "#503B5A",
      onTertiaryFixed: "#FFFFFF",
      tertiaryFixedDim: "#392542",
      onTertiaryFixedVariant: "#FFFFFF",
      surfaceDim: "#D9DAE0",
      surfaceBright: "#F8F9FF",
      surfaceContainerLowest: "#FFFFFF",
      surfaceContainerLow: "#F2F3FA",
      surfaceContainer: "#EDEDF4",
      surfaceContainerHigh: "#E7E8EE",
      surfaceContainerHighest: "#E1E2E9",
      elevation: {
        level0: "transparent",
        level1: "rgb(242, 244, 251)",
        level2: "rgb(235, 240, 248)",
        level3: "rgb(228, 235, 245)",
        level4: "rgb(226, 233, 244)",
        level5: "rgb(221, 230, 242)",
      },
    },
    dark: {
      primary: "#A4C9FE",
      surfaceTint: "#A4C9FE",
      onPrimary: "#00315D",
      primaryContainer: "#204876",
      onPrimaryContainer: "#D3E3FF",
      secondary: "#BCC7DB",
      onSecondary: "#263141",
      secondaryContainer: "#3C4758",
      onSecondaryContainer: "#D8E3F8",
      tertiary: "#D9BDE3",
      onTertiary: "#3D2946",
      tertiaryContainer: "#543F5E",
      onTertiaryContainer: "#F6D9FF",
      error: "#FFB4AB",
      onError: "#690005",
      errorContainer: "#93000A",
      onErrorContainer: "#FFDAD6",
      background: "#111318",
      onBackground: "#E1E2E9",
      surface: "#111318",
      onSurface: "#E1E2E9",
      surfaceVariant: "#43474E",
      onSurfaceVariant: "#C3C6CF",
      outline: "#8D9199",
      outlineVariant: "#43474E",
      shadow: "#000000",
      scrim: "#000000",
      inverseSurface: "#E1E2E9",
      inverseOnSurface: "#2E3035",
      inversePrimary: "#3A608F",
      primaryFixed: "#D3E3FF",
      onPrimaryFixed: "#001C39",
      primaryFixedDim: "#A4C9FE",
      onPrimaryFixedVariant: "#204876",
      secondaryFixed: "#D8E3F8",
      onSecondaryFixed: "#111C2B",
      secondaryFixedDim: "#BCC7DB",
      onSecondaryFixedVariant: "#3C4758",
      tertiaryFixed: "#F6D9FF",
      onTertiaryFixed: "#261430",
      tertiaryFixedDim: "#D9BDE3",
      onTertiaryFixedVariant: "#543F5E",
      surfaceDim: "#111318",
      surfaceBright: "#37393E",
      surfaceContainerLowest: "#0C0E13",
      surfaceContainerLow: "#191C20",
      surfaceContainer: "#1D2024",
      surfaceContainerHigh: "#272A2F",
      surfaceContainerHighest: "#32353A",
      elevation: {
        level0: "transparent",
        level1: "rgb(33, 37, 41)",
        level2: "rgb(37, 42, 48)",
        level3: "rgb(41, 47, 55)",
        level4: "rgb(42, 49, 57)",
        level5: "rgb(45, 52, 62)",
      },
    },
    "dark-medium-contrast": {
      primary: "#ABCDFF",
      surfaceTint: "#A4C9FE",
      onPrimary: "#001730",
      primaryContainer: "#6E93C5",
      onPrimaryContainer: "#000000",
      secondary: "#C0CBE0",
      onSecondary: "#0B1726",
      secondaryContainer: "#8691A4",
      onSecondaryContainer: "#000000",
      tertiary: "#DDC1E7",
      onTertiary: "#210E2B",
      tertiaryContainer: "#A188AB",
      onTertiaryContainer: "#000000",
      error: "#FFBAB1",
      onError: "#370001",
      errorContainer: "#FF5449",
      onErrorContainer: "#000000",
      background: "#111318",
      onBackground: "#E1E2E9",
      surface: "#111318",
      onSurface: "#FBFAFF",
      surfaceVariant: "#43474E",
      onSurfaceVariant: "#C7CBD4",
      outline: "#9FA3AB",
      outlineVariant: "#80838B",
      shadow: "#000000",
      scrim: "#000000",
      inverseSurface: "#E1E2E9",
      inverseOnSurface: "#272A2F",
      inversePrimary: "#214977",
      primaryFixed: "#D3E3FF",
      onPrimaryFixed: "#001127",
      primaryFixedDim: "#A4C9FE",
      onPrimaryFixedVariant: "#063764",
      secondaryFixed: "#D8E3F8",
      onSecondaryFixed: "#061220",
      secondaryFixedDim: "#BCC7DB",
      onSecondaryFixedVariant: "#2C3747",
      tertiaryFixed: "#F6D9FF",
      onTertiaryFixed: "#1B0925",
      tertiaryFixedDim: "#D9BDE3",
      onTertiaryFixedVariant: "#432E4C",
      surfaceDim: "#111318",
      surfaceBright: "#37393E",
      surfaceContainerLowest: "#0C0E13",
      surfaceContainerLow: "#191C20",
      surfaceContainer: "#1D2024",
      surfaceContainerHigh: "#272A2F",
      surfaceContainerHighest: "#32353A",
      elevation: {
        level0: "transparent",
        level1: "rgb(33, 37, 41)",
        level2: "rgb(37, 42, 48)",
        level3: "rgb(41, 47, 55)",
        level4: "rgb(42, 49, 57)",
        level5: "rgb(45, 52, 62)",
      },
    },
    "dark-high-contrast": {
      primary: "#FBFAFF",
      surfaceTint: "#A4C9FE",
      onPrimary: "#000000",
      primaryContainer: "#ABCDFF",
      onPrimaryContainer: "#000000",
      secondary: "#FBFAFF",
      onSecondary: "#000000",
      secondaryContainer: "#C0CBE0",
      onSecondaryContainer: "#000000",
      tertiary: "#FFF9FB",
      onTertiary: "#000000",
      tertiaryContainer: "#DDC1E7",
      onTertiaryContainer: "#000000",
      error: "#FFF9F9",
      onError: "#000000",
      errorContainer: "#FFBAB1",
      onErrorContainer: "#000000",
      background: "#111318",
      onBackground: "#E1E2E9",
      surface: "#111318",
      onSurface: "#FFFFFF",
      surfaceVariant: "#43474E",
      onSurfaceVariant: "#FBFAFF",
      outline: "#C7CBD4",
      outlineVariant: "#C7CBD4",
      shadow: "#000000",
      scrim: "#000000",
      inverseSurface: "#E1E2E9",
      inverseOnSurface: "#000000",
      inversePrimary: "#002B52",
      primaryFixed: "#DBE7FF",
      onPrimaryFixed: "#000000",
      primaryFixedDim: "#ABCDFF",
      onPrimaryFixedVariant: "#001730",
      secondaryFixed: "#DCE7FC",
      onSecondaryFixed: "#000000",
      secondaryFixedDim: "#C0CBE0",
      onSecondaryFixedVariant: "#0B1726",
      tertiaryFixed: "#F8DEFF",
      onTertiaryFixed: "#000000",
      tertiaryFixedDim: "#DDC1E7",
      onTertiaryFixedVariant: "#210E2B",
      surfaceDim: "#111318",
      surfaceBright: "#37393E",
      surfaceContainerLowest: "#0C0E13",
      surfaceContainerLow: "#191C20",
      surfaceContainer: "#1D2024",
      surfaceContainerHigh: "#272A2F",
      surfaceContainerHighest: "#32353A",
      elevation: {
        level0: "transparent",
        level1: "rgb(33, 37, 41)",
        level2: "rgb(37, 42, 48)",
        level3: "rgb(41, 47, 55)",
        level4: "rgb(42, 49, 57)",
        level5: "rgb(45, 52, 62)",
      },
    },
  },
  palettes: {
    primary: {
      "0": "#000000",
      "5": "#001127",
      "10": "#001C39",
      "15": "#00264A",
      "20": "#093159",
      "25": "#193C65",
      "30": "#264871",
      "35": "#33547D",
      "40": "#40608A",
      "50": "#5979A4",
      "60": "#7392C0",
      "70": "#8DADDC",
      "80": "#A8C8F8",
      "90": "#D3E3FF",
      "95": "#EBF1FF",
      "98": "#F8F9FF",
      "99": "#FDFCFF",
      "100": "#FFFFFF",
    },
    secondary: {
      "0": "#000000",
      "5": "#0B111A",
      "10": "#161C25",
      "15": "#202630",
      "20": "#2A313B",
      "25": "#353C46",
      "30": "#414752",
      "35": "#4C535E",
      "40": "#585F6A",
      "50": "#717783",
      "60": "#8B919D",
      "70": "#A5ABB8",
      "80": "#C1C7D4",
      "90": "#DDE3F0",
      "95": "#EBF1FE",
      "98": "#F8F9FF",
      "99": "#FDFCFF",
      "100": "#FFFFFF",
    },
    tertiary: {
      "0": "#000000",
      "5": "#180C1D",
      "10": "#231729",
      "15": "#2E2133",
      "20": "#392C3E",
      "25": "#44374A",
      "30": "#504256",
      "35": "#5C4D62",
      "40": "#68596E",
      "50": "#827287",
      "60": "#9C8BA1",
      "70": "#B8A5BD",
      "80": "#D4C0D8",
      "90": "#F0DCF5",
      "95": "#FDEBFF",
      "98": "#FFF7FC",
      "99": "#FFFBFF",
      "100": "#FFFFFF",
    },
    neutral: {
      "0": "#000000",
      "5": "#101113",
      "10": "#1B1B1D",
      "15": "#252628",
      "20": "#303032",
      "25": "#3B3B3D",
      "30": "#464749",
      "35": "#525254",
      "40": "#5E5E60",
      "50": "#777779",
      "60": "#919092",
      "70": "#ACABAD",
      "80": "#C7C6C8",
      "90": "#E4E2E4",
      "95": "#F2F0F2",
      "98": "#FBF9FB",
      "99": "#FEFBFE",
      "100": "#FFFFFF",
    },
    "neutral-variant": {
      "0": "#000000",
      "5": "#0F1115",
      "10": "#191C20",
      "15": "#24262A",
      "20": "#2E3035",
      "25": "#393B40",
      "30": "#45474B",
      "35": "#505257",
      "40": "#5C5E63",
      "50": "#75777C",
      "60": "#8F9096",
      "70": "#AAABB0",
      "80": "#C5C6CC",
      "90": "#E2E2E8",
      "95": "#F0F0F6",
      "98": "#F9F9FF",
      "99": "#FDFCFF",
      "100": "#FFFFFF",
    },
  },
};
