import React from "react";
import { EditablePlanTask } from "frontend-shared/util/modelTypes";
import { TaskDialog, TaskDialogProps } from "./taskDialog";

interface Props extends TaskDialogProps {
  task: EditablePlanTask;
}

export const EditTaskDialog: React.FC<Props> = (props) => {
  return <TaskDialog title="Edit Task" saveButtonLabel={"Save Task"} {...props} />;
};
