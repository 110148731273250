import React from "react";
import { Button, Dialog, Portal, TextInput, useTheme } from "react-native-paper";
import { EditablePlanTask, emptyEditablePlanTask } from "frontend-shared/util/modelTypes";
import { PortalDialogOverlay } from "../../components/portalDialogOverlay";

export interface TaskDialogProps {
  visible: boolean;
  cancel: () => void;
  save: (task: EditablePlanTask) => void;
  deleteTask?: (task: EditablePlanTask) => void;
}

export interface Props extends TaskDialogProps {
  title: string;
  task: EditablePlanTask;
  saveButtonLabel: string;
}

export const TaskDialog: React.FC<Props> = ({ title, task, visible, cancel, save, saveButtonLabel, deleteTask }) => {
  const [newTask, setNewTask] = React.useState(task);
  const [initialized, setInitialized] = React.useState(false);

  const theme = useTheme();

  if (!visible && initialized) {
    setNewTask(emptyEditablePlanTask());
    setInitialized(false);
    return <></>;
  } else if (visible && !initialized) {
    setNewTask(task);
    setInitialized(true);
    return <></>;
  }

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={cancel}>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Content style={{ gap: "8px" }}>
          <TextInput
            label="Name"
            autoFocus={true}
            value={newTask.name}
            onChangeText={(text) => setNewTask({ ...newTask, name: text })}
            onSubmitEditing={() => {
              if (newTask.name !== "") {
                save(newTask);
              }
            }}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === "Escape") {
                cancel();
              }
            }}
          />
        </Dialog.Content>
        <Dialog.Actions>
          {deleteTask && (
            <Button
              onPress={() => {
                deleteTask(task);
              }}
              textColor={theme.colors.onError}
              buttonColor={theme.colors.error}
            >
              Delete
            </Button>
          )}
          <Button onPress={cancel}>Cancel</Button>
          <Button
            mode="contained"
            onPress={() => {
              save(newTask);
            }}
            disabled={newTask.name === ""}
          >
            {saveButtonLabel}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
