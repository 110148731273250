import React from "react";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { StackNavigationProp } from "@react-navigation/stack";

import { TodayOptimisticAPIContext } from "frontend-shared/api/today.api.plan";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { selectBacklogState } from "frontend-shared/store/backlogSlice";
import { selectIdentitiesState } from "frontend-shared/store/identitiesSlice";
import { selectRoutinesState } from "frontend-shared/store/routinesSlice";
import { selectSettingsState } from "frontend-shared/store/settingsSlice";
import { selectTodayPageState } from "frontend-shared/store/todayPageSlice";
import { AppErrorScreen } from "../appErrorScreen";
import { PlansStackParamList } from "../../App";
import { apiDeletePlannedTask, apiEditPlannedTaskName, apiToggleTaskCompletion } from "frontend-shared/api/today.api.task";
import { TimeCardDataView } from "./timeCardScreen/timeCardDataView";
import {
  apiAddTaskWithNameToGroup,
  apiSetCompletedTimeBlockCount,
  apiSetRoleIdentityForTimeCard,
  apiSetTimeBlockCount,
  apiSetUserTaskGroupStartTimeBlockNumber,
  apiMoveIncompleteTasksOfTaskGroupToDate,
} from "frontend-shared/api/today.api.taskGroup";
import { apiUpdateRoutineFromTaskGroup } from "frontend-shared/api/today.api.routines";
import { showSnackbarSuccess, showSnackbarError } from "frontend-shared/store/appSnackbarSlice";
import { PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { parseDateSimple } from "frontend-shared/util/dateUtils";
import { selectUserCommunitiesState } from "frontend-shared/store/userCommunitiesSlice";
import { apiShareTaskGroupToCommunity, UserCommunityOptimisticAPIContext } from "frontend-shared/api/userCommunities.api";

interface Props {
  navigation: StackNavigationProp<PlansStackParamList, "TimeCard">;
}

export const TimeCardScreen: React.FC<Props> = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const identitiesState = useAppSelector(selectIdentitiesState);
  const todayPageState = useAppSelector(selectTodayPageState);
  const backlogState = useAppSelector(selectBacklogState);
  const routinesState = useAppSelector(selectRoutinesState);
  const settingsState = useAppSelector(selectSettingsState);
  const userCommunitiesState = useAppSelector(selectUserCommunitiesState);

  const makeAPIContext = (client: ApolloClient<object>): TodayOptimisticAPIContext => {
    return { client, todayPageState, backlogState, routinesState, settingsState, identitiesState, dispatch };
  };

  const makeCommunityAPIContext = (client: ApolloClient<object>): UserCommunityOptimisticAPIContext => {
    return { client, userCommunitiesState, dispatch };
  };

  if (identitiesState.errorMessage) {
    return <AppErrorScreen errorMessage={identitiesState.errorMessage} navigation={navigation} />;
  }

  // Params never change, so we need to use the id to get the task group from the redux store, which changes
  // and will cause a re-render.
  const taskGroupId = navigation.getState().routes[navigation.getState().index].params?.taskGroup.id;
  const taskGroup = todayPageState.taskGroups.find((tg) => tg.id === taskGroupId);
  if (!taskGroup) {
    // This happens wnen the task group has been deleted and we are still on the screen.
    // The user is being navigated back to the plan screen.
    navigation.goBack();
    return <></>;
  }

  return (
    <>
      <ApolloConsumer>
        {(client) => {
          const ctx = makeAPIContext(client);
          const communityCtx = makeCommunityAPIContext(client);
          return (
            <TimeCardDataView
              planDate={parseDateSimple(todayPageState.date) ?? new Date()}
              taskGroup={taskGroup}
              communities={userCommunitiesState.communities}
              objectId={taskGroup.id}
              roleIdentityId={taskGroup.roleIdentityId}
              timeBlockCount={taskGroup.timeBlockCount}
              completedTimeBlockCount={taskGroup.completedTimeBlockCount}
              roleIdentities={identitiesState.roleIdentities}
              setRoleIdentityFor={(objectId, roleIdentityId) =>
                apiSetRoleIdentityForTimeCard(
                  ctx,
                  taskGroup,
                  roleIdentityId,
                  () => {},
                  () => {}
                )
              }
              updateRoutine={(objectId) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objectId);
                if (!taskGroup) {
                  return;
                }
                apiUpdateRoutineFromTaskGroup(ctx, taskGroup);
              }}
              setTimeBlockNumberFor={(objId: string, startTimeBlockNumber?: number) => {
                apiSetUserTaskGroupStartTimeBlockNumber(ctx, taskGroup, startTimeBlockNumber);
              }}
              setStartTime={(taskGroup: PlanTaskGroup, timeBlockNumber?: number) => {
                apiSetUserTaskGroupStartTimeBlockNumber(ctx, taskGroup, timeBlockNumber);
              }}
              toggleCheckbox={(task) => apiToggleTaskCompletion(ctx, task)}
              isLoading={!todayPageState.initialized}
              newTask={(name: string) => {
                apiAddTaskWithNameToGroup(ctx, name, taskGroup);
              }}
              editTask={(originalTask, task) => apiEditPlannedTaskName(ctx, originalTask, task.name)}
              deleteTask={(originalTask, task) => apiDeletePlannedTask(ctx, originalTask)}
              setTimeBlockCount={(timeBlockCount) => apiSetTimeBlockCount(ctx, taskGroup, timeBlockCount)}
              setTimeBlockCompletedCount={(completedTimeBlockCount) => apiSetCompletedTimeBlockCount(ctx, taskGroup, completedTimeBlockCount)}
              moveIncompleteTasks={(objectId, date) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objectId);
                if (!taskGroup) {
                  return;
                }
                apiMoveIncompleteTasksOfTaskGroupToDate(
                  ctx,
                  taskGroup,
                  date,
                  settingsState.grayIsDefault,
                  () => {
                    dispatch(showSnackbarSuccess("Tasks moved"));
                  },
                  (err) => {
                    dispatch(showSnackbarError(err));
                  }
                );
              }}
              shareToCommunity={(objectId, communityId) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objectId);
                if (!taskGroup) {
                  return;
                }
                const community = userCommunitiesState.communities.find((community) => community.id === communityId);
                if (!community) {
                  return;
                }
                apiShareTaskGroupToCommunity(
                  communityCtx,
                  community.id,
                  taskGroup.id,
                  () => {
                    dispatch(showSnackbarSuccess(`${taskGroup.name ?? "Time Card"} shared to ${community.name}`));
                  },
                  (err) => {
                    dispatch(showSnackbarError(err));
                  }
                );
              }}
            />
          );
        }}
      </ApolloConsumer>
    </>
  );
};
