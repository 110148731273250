import React, { useState } from "react";
import { IconButton } from "react-native-paper";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { selectSettingsState } from "frontend-shared/store/settingsSlice";
import { useApolloClient } from "@apollo/client";
import { UserSettingsDialog } from "./userSettingsDialog";
import { apiSetSettings, SettingsOptimisticAPIContext } from "frontend-shared/api/settings.api";
import { TimerControlButtons } from "./timerProvider/timerControlButtons";
import { selectTimerState } from "frontend-shared/store/timerSlice";
import { View } from "react-native-web";

interface Props {}

export const IdentityTabScreenHeaderButtons: React.FC<Props> = () => {
  const [settingsVisible, setSettingsVisible] = useState(false);

  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const settingsState = useAppSelector(selectSettingsState);
  const timerState = useAppSelector(selectTimerState);
  const makeAPIContext = (): SettingsOptimisticAPIContext => {
    return { client, settingsState, dispatch };
  };

  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <TimerControlButtons timerState={timerState} />
      <IconButton
        icon="cog"
        iconColor="white"
        onPress={() => {
          setSettingsVisible(true);
        }}
        disabled={!settingsState.initialized}
      />
      <UserSettingsDialog
        visible={settingsVisible}
        userName={settingsState.displayName}
        onDismiss={() => setSettingsVisible(false)}
        onSave={(userName) => {
          apiSetSettings(
            makeAPIContext(),
            userName,
            settingsState.grayIsDefault,
            settingsState.defaultStartTimeBlockNumber,
            settingsState.defaultEndTimeBlockNumber,
            settingsState.showGuideRailTimeInterval
          );
          setSettingsVisible(false);
        }}
      />
    </View>
  );
};
