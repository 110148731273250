import { optimisticUpdateAndServerRequestWithUndo } from "frontend-shared/api/api.utils";
import {
  requestCreateUserRoleIdentity,
  requestDeleteUserRoleIdentity,
  requestSetUserRoleIdentityArchived,
  requestUpdateUserRoleIdentity,
} from "frontend-shared/generated/graphqlWrappers";
import { showSnackbarError } from "frontend-shared/store/appSnackbarSlice";
import { addNewRoleIdentity, removeRoleIdentity, setRoleIdentityArchived, updateRoleIdentity } from "frontend-shared/store/identitiesSlice";
import { nextOrder, PlanRoleIdentity } from "frontend-shared/util/modelTypes";
import { TodayOptimisticAPIContext } from "./today.api.plan";
import { uuidForId } from "../util/uuidUtils";

export const apiAddRoleIdentity = (
  apiContext: TodayOptimisticAPIContext,
  name: string,
  color: string,
  weeklyPaceTimeBlockCount: number,
  onSuccess: () => void,
  onError: (err: string) => void
) => {
  const { client, dispatch } = apiContext;
  const { roleIdentities } = apiContext.identitiesState;
  const roleIdentityId = uuidForId();
  const order = nextOrder(roleIdentities);
  const roleId = Object.values(apiContext.identitiesState.roles)[0]?.id;
  if (!roleId) {
    onError("No roles available");
    return;
  }
  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: addNewRoleIdentity({
      roleIdentityId,
      name,
      roleId,
      color,
      weeklyPaceTimeBlockCount,
      order,
    }),
    request: requestCreateUserRoleIdentity,
    variables: {
      roleIdentityId,
      roleId,
      name,
      color,
      weeklyPaceTimeBlockCount,
      order,
    },
    undo: removeRoleIdentity({ roleIdentityId }),
  });

  // we always end on success because errors are handled by the optimistic update
  onSuccess();
};

export const apiSetRoleIdentityArchived = (apiContext: TodayOptimisticAPIContext, roleIdentity: PlanRoleIdentity, archived: boolean) => {
  const { client, dispatch, identitiesState } = apiContext;
  const oldRoleIdentity = identitiesState.roleIdentities.find((ri) => ri.id === roleIdentity.id);
  if (!oldRoleIdentity) {
    dispatch(showSnackbarError("Cannot change archived status of identity"));
    return;
  }
  if (oldRoleIdentity.archived === archived) {
    return;
  }

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setRoleIdentityArchived({ roleIdentityId: roleIdentity.id, archived }),
    request: requestSetUserRoleIdentityArchived,
    variables: { roleIdentityId: roleIdentity.id, archived },
    undo: setRoleIdentityArchived({
      roleIdentityId: oldRoleIdentity.id,
      archived: oldRoleIdentity.archived,
    }),
  });
};

export const apiUpdateRoleIdentity = (apiContext: TodayOptimisticAPIContext, roleIdentity: PlanRoleIdentity) => {
  const { client, dispatch, identitiesState } = apiContext;
  const oldRoleIdentity = identitiesState.roleIdentities.find((ri) => ri.id === roleIdentity.id);
  if (!oldRoleIdentity) {
    dispatch(showSnackbarError("Cannot update identity"));
    return;
  }
  const { id: roleIdentityId, name, color, weeklyPaceTimeBlockCount, order } = roleIdentity;
  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: updateRoleIdentity({
      roleIdentityId,
      name,
      color,
      weeklyPaceTimeBlockCount,
      order,
    }),
    request: requestUpdateUserRoleIdentity,
    variables: {
      roleIdentityId,
      name,
      color,
      weeklyPaceTimeBlockCount,
      order,
    },

    undo: updateRoleIdentity({
      roleIdentityId,
      name: oldRoleIdentity.name,
      color: oldRoleIdentity.color,
      weeklyPaceTimeBlockCount: oldRoleIdentity.weeklyPaceTimeBlockCount,
      order: oldRoleIdentity.order,
    }),
  });
};
