export enum TimerRunningState {
  Running,
  Paused,
  Stopped,
}

export const parseTimerState = (state: string | undefined): TimerRunningState => {
  if (state === "Running") {
    return TimerRunningState.Running;
  } else if (state === "Paused") {
    return TimerRunningState.Paused;
  } else {
    return TimerRunningState.Stopped;
  }
};

export const formatTimerState = (state: TimerRunningState): string => {
  if (state === TimerRunningState.Running) {
    return "Running";
  } else if (state === TimerRunningState.Paused) {
    return "Paused";
  } else {
    return "Stopped";
  }
};
