import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Button, Dialog, Portal } from "react-native-paper";
import ColorPicker, { Panel1, Swatches, Preview, HueSlider } from "reanimated-color-picker";
import { PortalDialogOverlay } from "../components/portalDialogOverlay";

interface Props {
  visible: boolean;
  color?: string;
  onDismiss: () => void;
  onConfirm: (color: string) => void;
}

export const ColorPickerDialog: React.FC<Props> = ({ visible, onDismiss, onConfirm, color }: Props) => {
  const [selectedColor, setSelectedColor] = useState(color ?? "#CCCCCC");

  const onSelectColor = ({ hex }: { hex: string }) => {
    setSelectedColor(hex.substring(0, 7));
  };

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={onDismiss}>
        <Dialog.Title>Color Picker</Dialog.Title>
        <Dialog.Content>
          <View style={styles.container}>
            <ColorPicker value={selectedColor} onComplete={onSelectColor}>
              <Preview />
              <Panel1 />
              <HueSlider />
              <Swatches />
            </ColorPicker>
          </View>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={onDismiss}>Cancel</Button>
          <Button mode="contained" onPress={() => onConfirm(selectedColor)}>
            Ok
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
});
