import React from "react";
import { Chip, Icon } from "react-native-paper";
import { TimeCardStartTimeDialog } from "./timeCardStartTimeDialog";
import { formatSecondsTimeVeryShort, SECONDS_IN_A_TIMEBLOCK } from "frontend-shared/util/dateUtils";

export interface StartTimeChipProps {}

interface Props extends StartTimeChipProps {
  objectId: string;
  startTimeBlockNumber?: number;
  timeBlockCount: number;
  setTimeBlockNumberFor: (objectId: string, startTimeBlockNumber?: number) => void;
  chipsAreEditable?: boolean;
}

export const StartTimeChip: React.FC<Props> = ({ objectId, timeBlockCount, startTimeBlockNumber, setTimeBlockNumberFor, chipsAreEditable }) => {
  const [startTimeMenuVisible, setStartTimeMenuVisible] = React.useState(false);

  return (
    <>
      {startTimeBlockNumber && (
        <Chip
          icon={() => <Icon size={16} source="clock-time-nine-outline" color="white" />}
          onPress={chipsAreEditable ?? true ? () => setStartTimeMenuVisible(true) : undefined}
        >
          {`${formatSecondsTimeVeryShort(startTimeBlockNumber * SECONDS_IN_A_TIMEBLOCK)}`}
        </Chip>
      )}
      <TimeCardStartTimeDialog
        visible={startTimeMenuVisible}
        cancel={() => setStartTimeMenuVisible(false)}
        timeBlockNumber={startTimeBlockNumber}
        save={(timeBlockNumber) => {
          setTimeBlockNumberFor(objectId, timeBlockNumber);
          setStartTimeMenuVisible(false);
        }}
      />
    </>
  );
};
