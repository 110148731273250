import { range } from "frontend-shared/util/arrayUtils";
import { PlanRoleIdentity, PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { textColorForBackgroundColor } from "frontend-shared/util/colorUtils";
import { hasIncompleteTasksOrEmpty } from "frontend-shared/store/todayPageStateUtils";
import { defaultIdentityColor, timeBlockBackgroundColor } from "./timeBlocks";

export const GUIDERAIL_BLOCK_SIZE = 20;
export const GUIDERAIL_TIMEBLOCK_PADDING = 8;
export const GUIDERAIL_BETWEEN_BLOCK_PADDING = 4;

export interface GuideRailBlock {
  taskGroupId: string;
  color?: string;
  icon?: string;
  iconColor: string;
  startTimeBlockNumber?: number;
  totalBlocks: number;
  index: number;
}

export const numBlocksOnGuideRail = (startTimeBlockNumber: number, endTimeBlockNumber: number) => endTimeBlockNumber - startTimeBlockNumber + 1;

export const createBlockPropsFromTaskGroupsFiltered = (
  taskGroups: PlanTaskGroup[],
  roleIdentities: PlanRoleIdentity[],
  shouldInclude: (taskGroup: PlanTaskGroup) => boolean
) => {
  return taskGroups.reduce((acc, taskGroup) => {
    if (!shouldInclude(taskGroup)) {
      return acc;
    }

    const identityColor = roleIdentities.find((roleIdentity) => roleIdentity.id === taskGroup.roleIdentityId)?.color;

    const coloredBlocks = taskGroup.timeBlockCount;
    const iconedBlocks = taskGroup.completedTimeBlockCount;

    const hasIncomplete = hasIncompleteTasksOrEmpty(taskGroup);

    const totalBlocks = hasIncomplete ? Math.max(coloredBlocks, iconedBlocks) : iconedBlocks;
    const color = identityColor ?? defaultIdentityColor;

    acc.push(
      ...range(totalBlocks).map((index) => {
        return {
          taskGroupId: taskGroup.id,
          color: index < coloredBlocks ? color : timeBlockBackgroundColor,
          icon: index < iconedBlocks ? "circle" : undefined,
          iconColor: textColorForBackgroundColor(color),
          startTimeBlockNumber: taskGroup.startTimeBlockNumber,
          totalBlocks,
          index,
        };
      })
    );
    return acc;
  }, [] as GuideRailBlock[]);
};

export const createBlockPropsFromTaskGroups = (startTimeBlockNumber: number, taskGroups: PlanTaskGroup[], roleIdentities: PlanRoleIdentity[]) => {
  const blocks = createBlockPropsFromTaskGroupsFiltered(taskGroups, roleIdentities, (taskGroup) => taskGroup.startTimeBlockNumber === undefined);

  // We are going to filter out task groups that don't have a start time block number
  // So it's ok to default them to 0 in the sort
  const sortedTaskGroups = [...taskGroups];
  sortedTaskGroups.sort((a, b) => {
    return (a.startTimeBlockNumber ?? 0) - (b.startTimeBlockNumber ?? 0);
  });
  const scheduledBlocks = createBlockPropsFromTaskGroupsFiltered(
    sortedTaskGroups,
    roleIdentities,
    (taskGroup) => taskGroup.startTimeBlockNumber !== undefined
  );
  for (const scheduledBlock of scheduledBlocks) {
    // startTimeBlockNumber is always defined for scheduled blocks
    const index = scheduledBlock.startTimeBlockNumber! - startTimeBlockNumber + scheduledBlock.index;
    // if blocks does not have this many indexes, add empty blocks
    const newBlocksCount = index - blocks.length;
    while (blocks.length < index) {
      blocks.push({
        taskGroupId: scheduledBlock.taskGroupId,
        iconColor: "black",
        totalBlocks: newBlocksCount,
        index: newBlocksCount + blocks.length - index,
      });
    }
    blocks.splice(index, 0, scheduledBlock);
  }

  return blocks;
};

export const timeInBlocks = (date: Date) => {
  return (date.getHours() + date.getMinutes() / 60) * 2;
};

export const firstBlockInGroup = (blocks: GuideRailBlock[], index: number) => {
  if (index === 0) {
    return true;
  }
  const block = blocks[index];
  if (!block) {
    return blocks[index - 1] !== undefined;
  }
  if (block.index === 0) {
    return true;
  }
  if (lastBlockInGroup(blocks, index - 1)) {
    return true;
  }
  return false;
};

export const lastBlockInGroup = (blocks: GuideRailBlock[], index: number) => {
  const block = blocks[index];
  if (!block) {
    return false;
  }
  if (block.index === block.totalBlocks - 1) {
    return true;
  }
  if (blocks[index + 1]?.index === 0) {
    return true;
  }
  return false;
};

export const numPaddingsInGuideRailToTime = (blockProps: GuideRailBlock[], timeBlock: number) => {
  return blockProps.reduce(
    (acc, block, i, array) => {
      if (i === 0 || i >= timeBlock) {
        return acc;
      }
      if (lastBlockInGroup(array, i - 1)) {
        return acc + 1;
      }
      return acc;
    },
    timeBlock > blockProps.length && blockProps.length > 0 ? 1 : 0
  );
};

export const timeHeightOnGuideRailForTimeBlock = (
  timeInBlocks: number,
  blockProps: GuideRailBlock[],
  startTimeBlockNumber: number,
  endTimeBlockNumber: number
) => {
  const timeBlock = Math.min(Math.max(timeInBlocks - startTimeBlockNumber, 0), numBlocksOnGuideRail(startTimeBlockNumber, endTimeBlockNumber));
  const numPaddings = numPaddingsInGuideRailToTime(blockProps, timeBlock);

  const timeHeight = timeBlock * GUIDERAIL_BLOCK_SIZE + GUIDERAIL_TIMEBLOCK_PADDING + GUIDERAIL_BETWEEN_BLOCK_PADDING * numPaddings + 1;
  return Math.round(timeHeight);
};

export const timeHeightOnGuideRail = (date: Date, blockProps: GuideRailBlock[], startTimeBlockNumber: number, endTimeBlockNumber: number) => {
  return timeHeightOnGuideRailForTimeBlock(timeInBlocks(date), blockProps, startTimeBlockNumber, endTimeBlockNumber);
};
