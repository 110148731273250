import React from "react";
import { View } from "react-native";
import { IconButton, Text } from "react-native-paper";
import { formatDateLong } from "frontend-shared/util/dateUtils";

export interface DateNavigatorProps {
  planDate: Date;
  nextDate: () => void;
  previousDate: () => void;
}

export const DateNavigator: React.FC<DateNavigatorProps> = ({ planDate, nextDate, previousDate }) => {
  const formattedPlanDate = formatDateLong(planDate);
  return (
    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
      <IconButton icon="arrow-left" onPress={previousDate} />
      <Text variant="titleMedium" style={{ margin: "8px" }}>
        {formattedPlanDate}
      </Text>
      <IconButton icon="arrow-right" onPress={nextDate} />
    </View>
  );
};
