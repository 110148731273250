import React, { useEffect } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { ApolloClient, ApolloConsumer, useApolloClient } from "@apollo/client";
import { TodayPageQuery } from "frontend-shared/generated/graphql";
import { dateAddingDays, formatDateSimple } from "frontend-shared/util/dateUtils";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { clearTodayPageState, initializeTodayPage } from "frontend-shared/store/todayPageSlice";
import { getPlanToolbarCookies, getTimerCookies } from "frontend-shared/cookies/todayPageCookies";

import { selectTodayPageState } from "frontend-shared/store/todayPageSlice";
import { selectBacklogState } from "frontend-shared/store/backlogSlice";
import { selectRoutinesState } from "frontend-shared/store/routinesSlice";
import { selectSettingsState } from "frontend-shared/store/settingsSlice";
import { selectUserCommunitiesState } from "frontend-shared/store/userCommunitiesSlice";

import { apiSetStartTimeBlockNumber, apiSetEndTimeBlockNumber, TodayOptimisticAPIContext } from "frontend-shared/api/today.api.plan";
import { apiDeletePlannedTask, apiEditPlannedTaskName, apiToggleTaskCompletion } from "frontend-shared/api/today.api.task";
import {
  apiAddNewTimeCardToPlan,
  apiReorderTaskGroup,
  apiSetRoleIdentityForTimeCard,
  apiSetUserTaskGroupName,
  apiSetUserTaskGroupStartTimeBlockNumber,
  apiSetUserTaskGroupCollapsed,
} from "frontend-shared/api/today.api.taskGroup";
import { apiMoveIncompleteTasksOfTaskGroupToDate } from "frontend-shared/api/today.api.taskGroup";
import { apiMakeTaskGroupIntoRoutine, apiPlanRoutine, apiUpdateRoutineFromTaskGroup } from "frontend-shared/api/today.api.routines";

import { PlansStackParamList } from "../App";
import { AppErrorScreen } from "./appErrorScreen";
import { PlanDataView } from "./planTabScreen/planDataView";
import { selectIdentitiesState } from "frontend-shared/store/identitiesSlice";
import { requestTodayPageAllResults } from "frontend-shared/generated/graphqlWrappers";
import { routinesForDay } from "frontend-shared/store/routinesStateUtils";
import { showSnackbarSuccess, showSnackbarError } from "frontend-shared/store/appSnackbarSlice";
import { apiShareTaskGroupToCommunity, UserCommunityOptimisticAPIContext } from "frontend-shared/api/userCommunities.api";
import { MobileSnackbar } from "./components/mobileSnackbar";
import { apiSetSettings } from "frontend-shared/api/settings.api";
import { initializeTimerState } from "frontend-shared/store/timerSlice";
interface Props {
  navigation: StackNavigationProp<PlansStackParamList, "PlansMain">;
}

export const PlanTabScreen: React.FC<Props> = ({ navigation }) => {
  const [planDate, setPlanDate] = React.useState(new Date());

  const dispatch = useAppDispatch();
  const todayPageState = useAppSelector(selectTodayPageState);
  const backlogState = useAppSelector(selectBacklogState);
  const routinesState = useAppSelector(selectRoutinesState);
  const settingsState = useAppSelector(selectSettingsState);
  const identitiesState = useAppSelector(selectIdentitiesState);
  const userCommunitiesState = useAppSelector(selectUserCommunitiesState);

  const makeAPIContext = (client: ApolloClient<object>): TodayOptimisticAPIContext => {
    return { client, todayPageState, backlogState, routinesState, settingsState, identitiesState, dispatch };
  };

  const makeCommunityAPIContext = (client: ApolloClient<object>): UserCommunityOptimisticAPIContext => {
    return { client, userCommunitiesState, dispatch };
  };

  const apolloClient = useApolloClient();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const initializeFromQueryData = (data: TodayPageQuery) => {
    dispatch(
      initializeTodayPage({
        data,
        date: formatDateSimple(planDate),
        ...getPlanToolbarCookies(),
      })
    );
    dispatch(initializeTimerState({ timerState: getTimerCookies() }));
  };

  const loadData = () => {
    setLoading(true);
    dispatch(clearTodayPageState());
    requestTodayPageAllResults(
      apolloClient,
      { date: formatDateSimple(planDate) },
      (data) => {
        initializeFromQueryData(data);
        setLoading(false);
      },
      (errorMessage) => {
        setError(errorMessage);
        setLoading(false);
      }
    );
  };

  // You need an effect in order to call Redux dispatch
  useEffect(() => {
    if (todayPageState.initialized && formatDateSimple(planDate) !== todayPageState.date) {
      dispatch(clearTodayPageState());
    } else if (!todayPageState.initialized && !loading) {
      loadData();
    }
  });

  if (error) {
    return <AppErrorScreen errorMessage={error} navigation={navigation} />;
  }

  const todayRoutines = routinesForDay(routinesState, todayPageState.taskGroups, planDate);

  return (
    <>
      <ApolloConsumer>
        {(client) => {
          const ctx = makeAPIContext(client);
          const communityCtx = makeCommunityAPIContext(client);
          return (
            <PlanDataView
              planDate={planDate}
              taskGroups={todayPageState.taskGroups}
              communities={userCommunitiesState.communities}
              showGuideRail={todayPageState.dailyPlanId !== ""}
              startTimeBlockNumber={todayPageState.startTimeBlockNumber}
              endTimeBlockNumber={todayPageState.endTimeBlockNumber}
              defaultStartTimeBlockNumber={settingsState.defaultStartTimeBlockNumber}
              defaultEndTimeBlockNumber={settingsState.defaultEndTimeBlockNumber}
              showGuideRailTimeInterval={settingsState.showGuideRailTimeInterval}
              setGuideRailSettings={(
                startTimeBlockNumber,
                endTimeBlockNumber,
                defaultStartTimeBlockNumber,
                defaultEndTimeBlockNumber,
                showGuideRailTimeInterval
              ) => {
                apiSetStartTimeBlockNumber(ctx, startTimeBlockNumber);
                apiSetEndTimeBlockNumber(ctx, endTimeBlockNumber);
                apiSetSettings(
                  ctx,
                  settingsState.displayName,
                  settingsState.grayIsDefault,
                  defaultStartTimeBlockNumber,
                  defaultEndTimeBlockNumber,
                  showGuideRailTimeInterval
                );
              }}
              roleViewCompleted={todayPageState.roleViewCompleted}
              roleIdentities={identitiesState.roleIdentities}
              setRoleIdentityFor={(objId, roleIdentity) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objId);
                if (!taskGroup) {
                  return;
                }
                apiSetRoleIdentityForTimeCard(
                  ctx,
                  taskGroup,
                  roleIdentity,
                  () => {},
                  () => {}
                );
              }}
              toggleCheckbox={(task) => apiToggleTaskCompletion(ctx, task)}
              addTimeCard={(timeCard) =>
                apiAddNewTimeCardToPlan(
                  ctx,
                  timeCard.name,
                  todayPageState.defaultRoleId,
                  timeCard.roleIdentityId ?? null,
                  settingsState.grayIsDefault,
                  () => {}
                )
              }
              nextDate={() => setPlanDate(dateAddingDays(planDate, 1))}
              previousDate={() => setPlanDate(dateAddingDays(planDate, -1))}
              refetchData={() => loadData()}
              isLoading={loading || !todayPageState.initialized}
              timeCardTapped={(taskGroup) => navigation.navigate("TimeCard", { taskGroup })}
              editTimeCard={(originalTimeCard, timeCard) => apiSetUserTaskGroupName(ctx, originalTimeCard, timeCard.name ?? "")}
              editTask={(originalTask, task) => apiEditPlannedTaskName(ctx, originalTask, task.name)}
              deleteTask={(originalTask, task) => apiDeletePlannedTask(ctx, originalTask)}
              setStartTime={(taskGroup, startTime) => {
                apiSetUserTaskGroupStartTimeBlockNumber(ctx, taskGroup, startTime);
              }}
              setTimeBlockNumberFor={(objId: string, startTimeBlockNumber?: number) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objId);
                if (!taskGroup) {
                  return;
                }
                apiSetUserTaskGroupStartTimeBlockNumber(ctx, taskGroup, startTimeBlockNumber);
              }}
              routines={routinesState.routines}
              todayRoutines={todayRoutines}
              addRoutineToPlan={(routine) => {
                apiPlanRoutine(ctx, routine);
              }}
              updateRoutine={(objectId) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objectId);
                if (!taskGroup) {
                  return;
                }
                apiUpdateRoutineFromTaskGroup(ctx, taskGroup);
              }}
              createRoutine={(objectId, routineName) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objectId);
                if (!taskGroup) {
                  return;
                }
                apiMakeTaskGroupIntoRoutine(
                  ctx,
                  taskGroup,
                  routineName,
                  () => {
                    dispatch(showSnackbarSuccess("Routine Created"));
                  },
                  (err) => {
                    dispatch(showSnackbarError(err));
                  }
                );
              }}
              moveIncompleteTasks={(objectId, date) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objectId);
                if (!taskGroup) {
                  return;
                }
                apiMoveIncompleteTasksOfTaskGroupToDate(
                  ctx,
                  taskGroup,
                  date,
                  settingsState.grayIsDefault,
                  () => {
                    dispatch(showSnackbarSuccess("Tasks moved"));
                  },
                  (err) => {
                    dispatch(showSnackbarError(err));
                  }
                );
              }}
              shareToCommunity={(objectId, communityId) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objectId);
                if (!taskGroup) {
                  return;
                }
                const community = userCommunitiesState.communities.find((community) => community.id === communityId);
                if (!community) {
                  return;
                }
                apiShareTaskGroupToCommunity(
                  communityCtx,
                  community.id,
                  taskGroup.id,
                  () => {
                    dispatch(showSnackbarSuccess(`${taskGroup.name ?? "Time Card"} shared to ${community.name}`));
                  },
                  (err) => {
                    dispatch(showSnackbarError(err));
                  }
                );
              }}
              setCollapsed={(objectId, collapsed) => {
                const taskGroup = todayPageState.taskGroups.find((taskGroup) => taskGroup.id === objectId);
                if (!taskGroup) {
                  return;
                }
                apiSetUserTaskGroupCollapsed(ctx, taskGroup, collapsed);
              }}
              onMoveTaskGroup={(sourceIndex, destinationIndex) => {
                apiReorderTaskGroup(ctx, sourceIndex, destinationIndex);
              }}
            />
          );
        }}
      </ApolloConsumer>
      <MobileSnackbar />
    </>
  );
};
