import React from "react";
import { View } from "react-native-web";
import { IconButton } from "react-native-paper";
import { useApolloClient } from "@apollo/client";
import { useAppDispatch } from "frontend-shared/store/hooks";
import { apiLoadUserCommunitiesFull } from "frontend-shared/api/userCommunities.api";
import { useAppSelector } from "frontend-shared/store/hooks";
import { selectUserCommunitiesState } from "frontend-shared/store/userCommunitiesSlice";
import { showSnackbarError, showSnackbarSuccess } from "frontend-shared/store/appSnackbarSlice";
import { TimerControlButtons } from "./timerProvider/timerControlButtons";
import { selectTimerState } from "frontend-shared/store/timerSlice";

interface Props {}

export const CommunityTabScreenHeaderButtons: React.FC<Props> = () => {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const userCommunitiesState = useAppSelector(selectUserCommunitiesState);
  const timerState = useAppSelector(selectTimerState);
  const apiContext = {
    client,
    dispatch,
    userCommunitiesState,
  };
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <TimerControlButtons timerState={timerState} />
      <IconButton
        icon="refresh"
        iconColor="white"
        onPress={() => {
          apiLoadUserCommunitiesFull(
            apiContext,
            () => {
              dispatch(showSnackbarSuccess("Communities refreshed"));
            },
            (err) => {
              dispatch(showSnackbarError(err));
            }
          );
        }}
      />
    </View>
  );
};
