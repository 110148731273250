import React from "react";
import { Button, Dialog, Portal, TextInput } from "react-native-paper";
import { PortalDialogOverlay } from "./components/portalDialogOverlay";

interface Props {
  visible: boolean;
  userName: string;
  onDismiss: () => void;
  onSave: (userName: string) => void;
}

export const UserSettingsDialog: React.FC<Props> = ({ visible, userName, onDismiss, onSave }) => {
  const [newUserName, setNewUserName] = React.useState(userName);

  React.useEffect(() => {
    setNewUserName(userName);
  }, [userName]);

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={onDismiss}>
        <Dialog.Title>User Settings</Dialog.Title>
        <Dialog.Content style={{ gap: "8px" }}>
          <TextInput
            label="User Name"
            value={newUserName}
            onChangeText={setNewUserName}
            onSubmitEditing={() => {
              if (newUserName.trim() !== "") {
                onSave(newUserName.trim());
              }
            }}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === "Escape") {
                onDismiss();
              }
            }}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={onDismiss}>Cancel</Button>
          <Button mode="contained" onPress={() => onSave(newUserName)} disabled={newUserName.trim() === ""}>
            OK
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
