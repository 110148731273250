import React from "react";
import { Text } from "react-native-paper";
import { RootStackParamList } from "src/App";
import { StackActionHelpers } from "@react-navigation/routers";

interface Props {
  errorMessage: string;
  navigation: StackActionHelpers<RootStackParamList>;
}

export const AppErrorScreen: React.FC<Props> = ({ errorMessage, navigation }) => {
  let message = `Error:  ${errorMessage}`;
  if (errorMessage.indexOf("not authenticated") >= 0) {
    navigation.replace("Login");
  }
  if (errorMessage.indexOf("Unexpected token") >= 0) {
    message = "Could not connect to the server. Try again later.";
  }
  return <Text>{message}</Text>;
};
