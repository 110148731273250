import React from "react";
import { TimerContext } from "./timerContext";
import { TimerState } from "frontend-shared/store/timerSlice";

interface TimerConsumerProps {
  children: (
    startTimer: (timerState: TimerState, timeCard?: { id: string; name: string }) => void,
    pauseTimer: (timerState: TimerState) => void,
    endTimer: () => void,
    resetTimer: () => void
  ) => React.ReactNode;
}

export const TimerConsumer: React.FC<TimerConsumerProps> = ({ children }) => {
  return (
    <TimerContext.Consumer>
      {(context) => {
        if (!context) {
          throw new Error("TimerConsumer must be used within a TimerProvider");
        }
        return children(context.startTimer, context.pauseTimer, context.endTimer, context.resetTimer);
      }}
    </TimerContext.Consumer>
  );
};
