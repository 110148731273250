import React from "react";
import { Button, Dialog, List, Portal } from "react-native-paper";
import { PortalDialogOverlay } from "../components/portalDialogOverlay";
import { TimeSelectMenu } from "../components/timeSelectMenu";
import { Text } from "react-native-paper";
import { formatDateLong } from "frontend-shared/util/dateUtils";
import { DropDownSelectMenu } from "../components/dropDownSelectMenu";
import { View } from "react-native-web";

export interface GuideRailDialogProps {
  planDate: Date;
  visible: boolean;
  cancel: () => void;
  save: (
    startTimeBlockNumber: number,
    endTimeBlockNumber: number,
    defaultStartTimeBlockNumber: number,
    defaultEndTimeBlockNumber: number,
    showGuideRailTimeInterval: number
  ) => void;
  startTimeBlockNumber: number;
  endTimeBlockNumber: number;
  defaultStartTimeBlockNumber: number;
  defaultEndTimeBlockNumber: number;
  showGuideRailTimeInterval: number;
}

export const GuideRailDialog: React.FC<GuideRailDialogProps> = ({
  planDate,
  visible,
  save,
  cancel,
  startTimeBlockNumber,
  endTimeBlockNumber,
  defaultStartTimeBlockNumber,
  defaultEndTimeBlockNumber,
  showGuideRailTimeInterval,
}) => {
  const [startTimeBlockNumberState, setStartTimeBlockNumberState] = React.useState(startTimeBlockNumber);
  const [endTimeBlockNumberState, setEndTimeBlockNumberState] = React.useState(endTimeBlockNumber);

  const [defaultStartTimeBlockNumberState, setDefaultStartTimeBlockNumberState] = React.useState(defaultStartTimeBlockNumber);
  const [defaultEndTimeBlockNumberState, setDefaultEndTimeBlockNumberState] = React.useState(defaultEndTimeBlockNumber);

  const [showGuideRailTimeIntervalState, setShowGuideRailTimeIntervalState] = React.useState(showGuideRailTimeInterval);

  const [defaultSettingsExpanded, setDefaultSettingsExpanded] = React.useState(false);

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={cancel} style={{ maxWidth: "400px", alignSelf: "center" }}>
        <Dialog.Title>Guiderail Settings</Dialog.Title>
        <Dialog.Content style={{ gap: "8px" }}>
          <Text variant="bodyMedium">Settings for {formatDateLong(planDate)}</Text>
          <TimeSelectMenu label="Start Time" timeBlockNumber={startTimeBlockNumberState} setTimeBlockNumber={setStartTimeBlockNumberState} />
          <TimeSelectMenu label="End Time" timeBlockNumber={endTimeBlockNumberState} setTimeBlockNumber={setEndTimeBlockNumberState} />
          <List.AccordionGroup>
            <List.Accordion
              id="default-settings"
              title="Guiderail Default Settings"
              expanded={defaultSettingsExpanded}
              onPress={() => setDefaultSettingsExpanded(!defaultSettingsExpanded)}
            >
              <View style={{ gap: "8px", marginTop: "8px" }}>
                <TimeSelectMenu
                  label="Default Start Time"
                  timeBlockNumber={defaultStartTimeBlockNumberState}
                  setTimeBlockNumber={setDefaultStartTimeBlockNumberState}
                />
                <TimeSelectMenu
                  label="Default End Time"
                  timeBlockNumber={defaultEndTimeBlockNumberState}
                  setTimeBlockNumber={setDefaultEndTimeBlockNumberState}
                />
                <DropDownSelectMenu
                  label="Guiderail Time Shown"
                  index={showGuideRailTimeIntervalState}
                  setIndex={setShowGuideRailTimeIntervalState}
                  count={3}
                  iconSource={"timeline-clock"}
                  menuLabel={(index) => (index === 0 ? "Never" : `Every ${index * 2} hours`)}
                />
              </View>
            </List.Accordion>
          </List.AccordionGroup>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={cancel}>Cancel</Button>
          <Button
            mode="contained"
            onPress={() => {
              save(
                startTimeBlockNumberState,
                endTimeBlockNumberState,
                defaultStartTimeBlockNumberState,
                defaultEndTimeBlockNumberState,
                showGuideRailTimeIntervalState
              );
            }}
            disabled={startTimeBlockNumberState >= endTimeBlockNumberState}
          >
            OK
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
