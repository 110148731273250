import { createContext } from "react";
import { TimerState } from "frontend-shared/store/timerSlice";
export interface TimerContextType {
  startTimer: (timerState: TimerState, timeCard?: { id: string; name: string }) => void;
  pauseTimer: (timerState: TimerState) => void;
  endTimer: () => void;
  resetTimer: () => void;
}

export const TimerContext = createContext<TimerContextType | null>(null);
