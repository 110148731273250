// This code is generated by gqlWrappersPlugin.ts. Do not edit.

import * as gql from "./graphql";
import { ApolloClient } from "@apollo/client";
import { gqlMutate, gqlQuery } from "../gqlUtil/gqlHelpers";

// Calls the AdminCommunities query GQL with the variables and calls the appropriate callback
export async function requestAdminCommunities(apolloClient: ApolloClient<object>, variables: gql.AdminCommunitiesQueryVariables, onSuccess: (data: gql.AdminCommunitiesQuery["adminCommunities"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.AdminCommunitiesDocument, variables, (data) => onSuccess(data["adminCommunities"]), onError);
}
// Calls the AdminCommunities query GQL with the variables and calls the appropriate callback
export async function requestAdminCommunitiesAllResults(apolloClient: ApolloClient<object>, variables: gql.AdminCommunitiesQueryVariables, onSuccess: (data: gql.AdminCommunitiesQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.AdminCommunitiesDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the AdminStatsPage query GQL with the variables and calls the appropriate callback
export async function requestAdminStatsPage(apolloClient: ApolloClient<object>, variables: gql.AdminStatsPageQueryVariables, onSuccess: (data: gql.AdminStatsPageQuery["adminStats"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.AdminStatsPageDocument, variables, (data) => onSuccess(data["adminStats"]), onError);
}
// Calls the AdminStatsPage query GQL with the variables and calls the appropriate callback
export async function requestAdminStatsPageAllResults(apolloClient: ApolloClient<object>, variables: gql.AdminStatsPageQueryVariables, onSuccess: (data: gql.AdminStatsPageQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.AdminStatsPageDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the Backlog query GQL with the variables and calls the appropriate callback
export async function requestBacklog(apolloClient: ApolloClient<object>, variables: gql.BacklogQueryVariables, onSuccess: (data: gql.BacklogQuery["userUnplannedTasks"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.BacklogDocument, variables, (data) => onSuccess(data["userUnplannedTasks"]), onError);
}
// Calls the Backlog query GQL with the variables and calls the appropriate callback
export async function requestBacklogAllResults(apolloClient: ApolloClient<object>, variables: gql.BacklogQueryVariables, onSuccess: (data: gql.BacklogQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.BacklogDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the CommunitiesScreen query GQL with the variables and calls the appropriate callback
export async function requestCommunitiesScreen(apolloClient: ApolloClient<object>, variables: gql.CommunitiesScreenQueryVariables, onSuccess: (data: gql.CommunitiesScreenQuery["userAccount"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.CommunitiesScreenDocument, variables, (data) => onSuccess(data["userAccount"]), onError);
}
// Calls the CommunitiesScreen query GQL with the variables and calls the appropriate callback
export async function requestCommunitiesScreenAllResults(apolloClient: ApolloClient<object>, variables: gql.CommunitiesScreenQueryVariables, onSuccess: (data: gql.CommunitiesScreenQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.CommunitiesScreenDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the CommunitiesScreenFull query GQL with the variables and calls the appropriate callback
export async function requestCommunitiesScreenFull(apolloClient: ApolloClient<object>, variables: gql.CommunitiesScreenFullQueryVariables, onSuccess: (data: gql.CommunitiesScreenFullQuery["userAccount"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.CommunitiesScreenFullDocument, variables, (data) => onSuccess(data["userAccount"]), onError);
}
// Calls the CommunitiesScreenFull query GQL with the variables and calls the appropriate callback
export async function requestCommunitiesScreenFullAllResults(apolloClient: ApolloClient<object>, variables: gql.CommunitiesScreenFullQueryVariables, onSuccess: (data: gql.CommunitiesScreenFullQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.CommunitiesScreenFullDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the CreateCommunity mutation GQL with the variables and calls the appropriate callback
export async function requestCreateCommunity(apolloClient: ApolloClient<object>, variables: gql.CreateCommunityMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateCommunityDocument, "createCommunity", variables, onSuccess, onError);
}

// Calls the AddMemberToCommunity mutation GQL with the variables and calls the appropriate callback
export async function requestAddMemberToCommunity(apolloClient: ApolloClient<object>, variables: gql.AddMemberToCommunityMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddMemberToCommunityDocument, "addMemberToCommunity", variables, onSuccess, onError);
}

// Calls the ShareTaskGroupToCommunity mutation GQL with the variables and calls the appropriate callback
export async function requestShareTaskGroupToCommunity(apolloClient: ApolloClient<object>, variables: gql.ShareTaskGroupToCommunityMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.ShareTaskGroupToCommunityDocument, "shareTaskGroupToCommunity", variables, onSuccess, onError);
}

// Calls the ReactToSharedObject mutation GQL with the variables and calls the appropriate callback
export async function requestReactToSharedObject(apolloClient: ApolloClient<object>, variables: gql.ReactToSharedObjectMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.ReactToSharedObjectDocument, "reactToSharedObject", variables, onSuccess, onError);
}

// Calls the UnreactToSharedObject mutation GQL with the variables and calls the appropriate callback
export async function requestUnreactToSharedObject(apolloClient: ApolloClient<object>, variables: gql.UnreactToSharedObjectMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UnreactToSharedObjectDocument, "unreactToSharedObject", variables, onSuccess, onError);
}

// Calls the UserDailyPlan query GQL with the variables and calls the appropriate callback
export async function requestUserDailyPlan(apolloClient: ApolloClient<object>, variables: gql.UserDailyPlanQueryVariables, onSuccess: (data: gql.UserDailyPlanQuery["userDailyPlanForDate"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserDailyPlanDocument, variables, (data) => onSuccess(data["userDailyPlanForDate"]), onError);
}
// Calls the UserDailyPlan query GQL with the variables and calls the appropriate callback
export async function requestUserDailyPlanAllResults(apolloClient: ApolloClient<object>, variables: gql.UserDailyPlanQueryVariables, onSuccess: (data: gql.UserDailyPlanQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserDailyPlanDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the CreateUserDailyPlanForDate mutation GQL with the variables and calls the appropriate callback
export async function requestCreateUserDailyPlanForDate(apolloClient: ApolloClient<object>, variables: gql.CreateUserDailyPlanForDateMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateUserDailyPlanForDateDocument, "createUserDailyPlanForDate", variables, onSuccess, onError);
}

// Calls the AddTaskToPlan mutation GQL with the variables and calls the appropriate callback
export async function requestAddTaskToPlan(apolloClient: ApolloClient<object>, variables: gql.AddTaskToPlanMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddTaskToPlanDocument, "addTaskToPlan", variables, onSuccess, onError);
}

// Calls the AddNewTaskToPlan mutation GQL with the variables and calls the appropriate callback
export async function requestAddNewTaskToPlan(apolloClient: ApolloClient<object>, variables: gql.AddNewTaskToPlanMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddNewTaskToPlanDocument, "addNewTaskToPlan", variables, onSuccess, onError);
}

// Calls the SetPlanStartTimeBlockNumber mutation GQL with the variables and calls the appropriate callback
export async function requestSetPlanStartTimeBlockNumber(apolloClient: ApolloClient<object>, variables: gql.SetPlanStartTimeBlockNumberMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetPlanStartTimeBlockNumberDocument, "setPlanStartTimeBlockNumber", variables, onSuccess, onError);
}

// Calls the SetPlanEndTimeBlockNumber mutation GQL with the variables and calls the appropriate callback
export async function requestSetPlanEndTimeBlockNumber(apolloClient: ApolloClient<object>, variables: gql.SetPlanEndTimeBlockNumberMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetPlanEndTimeBlockNumberDocument, "setPlanEndTimeBlockNumber", variables, onSuccess, onError);
}

// Calls the SetPlanTimeBlockNumbers mutation GQL with the variables and calls the appropriate callback
export async function requestSetPlanTimeBlockNumbers(apolloClient: ApolloClient<object>, variables: gql.SetPlanTimeBlockNumbersMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetPlanTimeBlockNumbersDocument, "setPlanTimeBlockNumbers", variables, onSuccess, onError);
}

// Calls the SetUserPlanBlock mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserPlanBlock(apolloClient: ApolloClient<object>, variables: gql.SetUserPlanBlockMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserPlanBlockDocument, "setUserPlanBlock", variables, onSuccess, onError);
}

// Calls the SetUserPlanCollapsedForTaskGroups mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserPlanCollapsedForTaskGroups(apolloClient: ApolloClient<object>, variables: gql.SetUserPlanCollapsedForTaskGroupsMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserPlanCollapsedForTaskGroupsDocument, "setUserPlanCollapsedForTaskGroups", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupRole mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupRole(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupRoleMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupRoleDocument, "setUserTaskGroupRole", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupTimeBlockCount mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupTimeBlockCount(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupTimeBlockCountMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupTimeBlockCountDocument, "setUserTaskGroupTimeBlockCount", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupCompletedTimeBlockCount mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupCompletedTimeBlockCount(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupCompletedTimeBlockCountMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupCompletedTimeBlockCountDocument, "setUserTaskGroupCompletedTimeBlockCount", variables, onSuccess, onError);
}

// Calls the IncrementUserTaskGroupCompletedTimeBlockCount mutation GQL with the variables and calls the appropriate callback
export async function requestIncrementUserTaskGroupCompletedTimeBlockCount(apolloClient: ApolloClient<object>, variables: gql.IncrementUserTaskGroupCompletedTimeBlockCountMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.IncrementUserTaskGroupCompletedTimeBlockCountDocument, "incrementUserTaskGroupCompletedTimeBlockCount", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupOrder mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupOrder(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupOrderMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupOrderDocument, "setUserTaskGroupOrder", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupCollapsed mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupCollapsed(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupCollapsedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupCollapsedDocument, "setUserTaskGroupCollapsed", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupGrayTime mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupGrayTime(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupGrayTimeMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupGrayTimeDocument, "setUserTaskGroupGrayTime", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupName mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupName(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupNameMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupNameDocument, "setUserTaskGroupName", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupStartTimeBlockNumber mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupStartTimeBlockNumber(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupStartTimeBlockNumberMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupStartTimeBlockNumberDocument, "setUserTaskGroupStartTimeBlockNumber", variables, onSuccess, onError);
}

// Calls the AddNewTaskToGroup mutation GQL with the variables and calls the appropriate callback
export async function requestAddNewTaskToGroup(apolloClient: ApolloClient<object>, variables: gql.AddNewTaskToGroupMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddNewTaskToGroupDocument, "addNewTaskToGroup", variables, onSuccess, onError);
}

// Calls the AddTimeCardToPlan mutation GQL with the variables and calls the appropriate callback
export async function requestAddTimeCardToPlan(apolloClient: ApolloClient<object>, variables: gql.AddTimeCardToPlanMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddTimeCardToPlanDocument, "addTimeCardToPlan", variables, onSuccess, onError);
}

// Calls the DeleteTimeCardFromPlan mutation GQL with the variables and calls the appropriate callback
export async function requestDeleteTimeCardFromPlan(apolloClient: ApolloClient<object>, variables: gql.DeleteTimeCardFromPlanMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.DeleteTimeCardFromPlanDocument, "deleteTimeCardFromPlan", variables, onSuccess, onError);
}

// Calls the MoveUserTaskToGroup mutation GQL with the variables and calls the appropriate callback
export async function requestMoveUserTaskToGroup(apolloClient: ApolloClient<object>, variables: gql.MoveUserTaskToGroupMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.MoveUserTaskToGroupDocument, "moveUserTaskToGroup", variables, onSuccess, onError);
}

// Calls the MakeRoutineFromTaskGroup mutation GQL with the variables and calls the appropriate callback
export async function requestMakeRoutineFromTaskGroup(apolloClient: ApolloClient<object>, variables: gql.MakeRoutineFromTaskGroupMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.MakeRoutineFromTaskGroupDocument, "makeRoutineFromTaskGroup", variables, onSuccess, onError);
}

// Calls the UpdateRoutineFromTaskGroup mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateRoutineFromTaskGroup(apolloClient: ApolloClient<object>, variables: gql.UpdateRoutineFromTaskGroupMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateRoutineFromTaskGroupDocument, "updateRoutineFromTaskGroup", variables, onSuccess, onError);
}

// Calls the MoveIncompleteTasksOfTaskGroupToDate mutation GQL with the variables and calls the appropriate callback
export async function requestMoveIncompleteTasksOfTaskGroupToDate(apolloClient: ApolloClient<object>, variables: gql.MoveIncompleteTasksOfTaskGroupToDateMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.MoveIncompleteTasksOfTaskGroupToDateDocument, "moveIncompleteTasksOfTaskGroupToDate", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupProject mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupProject(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupProjectMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupProjectDocument, "setUserTaskGroupProject", variables, onSuccess, onError);
}

// Calls the UnsetUserTaskGroupProject mutation GQL with the variables and calls the appropriate callback
export async function requestUnsetUserTaskGroupProject(apolloClient: ApolloClient<object>, variables: gql.UnsetUserTaskGroupProjectMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UnsetUserTaskGroupProjectDocument, "unsetUserTaskGroupProject", variables, onSuccess, onError);
}

// Calls the SetUserTaskGroupRoleIdentity mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskGroupRoleIdentity(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskGroupRoleIdentityMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskGroupRoleIdentityDocument, "setUserTaskGroupRoleIdentity", variables, onSuccess, onError);
}

// Calls the UnsetUserTaskGroupRoleIdentity mutation GQL with the variables and calls the appropriate callback
export async function requestUnsetUserTaskGroupRoleIdentity(apolloClient: ApolloClient<object>, variables: gql.UnsetUserTaskGroupRoleIdentityMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UnsetUserTaskGroupRoleIdentityDocument, "unsetUserTaskGroupRoleIdentity", variables, onSuccess, onError);
}

// Calls the IdentitiesScreen query GQL with the variables and calls the appropriate callback
export async function requestIdentitiesScreen(apolloClient: ApolloClient<object>, variables: gql.IdentitiesScreenQueryVariables, onSuccess: (data: gql.IdentitiesScreenQuery["userAccount"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.IdentitiesScreenDocument, variables, (data) => onSuccess(data["userAccount"]), onError);
}
// Calls the IdentitiesScreen query GQL with the variables and calls the appropriate callback
export async function requestIdentitiesScreenAllResults(apolloClient: ApolloClient<object>, variables: gql.IdentitiesScreenQueryVariables, onSuccess: (data: gql.IdentitiesScreenQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.IdentitiesScreenDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the GetRoleIdentityScoreboards query GQL with the variables and calls the appropriate callback
export async function requestGetRoleIdentityScoreboards(apolloClient: ApolloClient<object>, variables: gql.GetRoleIdentityScoreboardsQueryVariables, onSuccess: (data: gql.GetRoleIdentityScoreboardsQuery["userRoleIdentity"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.GetRoleIdentityScoreboardsDocument, variables, (data) => onSuccess(data["userRoleIdentity"]), onError);
}
// Calls the GetRoleIdentityScoreboards query GQL with the variables and calls the appropriate callback
export async function requestGetRoleIdentityScoreboardsAllResults(apolloClient: ApolloClient<object>, variables: gql.GetRoleIdentityScoreboardsQueryVariables, onSuccess: (data: gql.GetRoleIdentityScoreboardsQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.GetRoleIdentityScoreboardsDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the CreateUserProject mutation GQL with the variables and calls the appropriate callback
export async function requestCreateUserProject(apolloClient: ApolloClient<object>, variables: gql.CreateUserProjectMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateUserProjectDocument, "createUserProject", variables, onSuccess, onError);
}

// Calls the UpdateUserProject mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateUserProject(apolloClient: ApolloClient<object>, variables: gql.UpdateUserProjectMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateUserProjectDocument, "updateUserProject", variables, onSuccess, onError);
}

// Calls the GetProjectScoreboard query GQL with the variables and calls the appropriate callback
export async function requestGetProjectScoreboard(apolloClient: ApolloClient<object>, variables: gql.GetProjectScoreboardQueryVariables, onSuccess: (data: gql.GetProjectScoreboardQuery["userProject"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.GetProjectScoreboardDocument, variables, (data) => onSuccess(data["userProject"]), onError);
}
// Calls the GetProjectScoreboard query GQL with the variables and calls the appropriate callback
export async function requestGetProjectScoreboardAllResults(apolloClient: ApolloClient<object>, variables: gql.GetProjectScoreboardQueryVariables, onSuccess: (data: gql.GetProjectScoreboardQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.GetProjectScoreboardDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the CreateUserRoleIdentity mutation GQL with the variables and calls the appropriate callback
export async function requestCreateUserRoleIdentity(apolloClient: ApolloClient<object>, variables: gql.CreateUserRoleIdentityMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.CreateUserRoleIdentityDocument, "createUserRoleIdentity", variables, onSuccess, onError);
}

// Calls the DeleteUserRoleIdentity mutation GQL with the variables and calls the appropriate callback
export async function requestDeleteUserRoleIdentity(apolloClient: ApolloClient<object>, variables: gql.DeleteUserRoleIdentityMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.DeleteUserRoleIdentityDocument, "deleteUserRoleIdentity", variables, onSuccess, onError);
}

// Calls the SetUserRoleIdentityArchived mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserRoleIdentityArchived(apolloClient: ApolloClient<object>, variables: gql.SetUserRoleIdentityArchivedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserRoleIdentityArchivedDocument, "setUserRoleIdentityArchived", variables, onSuccess, onError);
}

// Calls the UpdateUserRoleIdentity mutation GQL with the variables and calls the appropriate callback
export async function requestUpdateUserRoleIdentity(apolloClient: ApolloClient<object>, variables: gql.UpdateUserRoleIdentityMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UpdateUserRoleIdentityDocument, "updateUserRoleIdentity", variables, onSuccess, onError);
}

// Calls the Routines query GQL with the variables and calls the appropriate callback
export async function requestRoutines(apolloClient: ApolloClient<object>, variables: gql.RoutinesQueryVariables, onSuccess: (data: gql.RoutinesQuery["userRoutines"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.RoutinesDocument, variables, (data) => onSuccess(data["userRoutines"]), onError);
}
// Calls the Routines query GQL with the variables and calls the appropriate callback
export async function requestRoutinesAllResults(apolloClient: ApolloClient<object>, variables: gql.RoutinesQueryVariables, onSuccess: (data: gql.RoutinesQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.RoutinesDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the AddRoutineToPlan mutation GQL with the variables and calls the appropriate callback
export async function requestAddRoutineToPlan(apolloClient: ApolloClient<object>, variables: gql.AddRoutineToPlanMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddRoutineToPlanDocument, "addRoutineToPlan", variables, onSuccess, onError);
}

// Calls the ArchiveUserRoutine mutation GQL with the variables and calls the appropriate callback
export async function requestArchiveUserRoutine(apolloClient: ApolloClient<object>, variables: gql.ArchiveUserRoutineMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.ArchiveUserRoutineDocument, "archiveUserRoutine", variables, onSuccess, onError);
}

// Calls the SetUserRoutineDaysOfTheWeek mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserRoutineDaysOfTheWeek(apolloClient: ApolloClient<object>, variables: gql.SetUserRoutineDaysOfTheWeekMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserRoutineDaysOfTheWeekDocument, "setUserRoutineDaysOfTheWeek", variables, onSuccess, onError);
}

// Calls the RoutinesPage query GQL with the variables and calls the appropriate callback
export async function requestRoutinesPage(apolloClient: ApolloClient<object>, variables: gql.RoutinesPageQueryVariables, onSuccess: (data: gql.RoutinesPageQuery["userRoutines"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.RoutinesPageDocument, variables, (data) => onSuccess(data["userRoutines"]), onError);
}
// Calls the RoutinesPage query GQL with the variables and calls the appropriate callback
export async function requestRoutinesPageAllResults(apolloClient: ApolloClient<object>, variables: gql.RoutinesPageQueryVariables, onSuccess: (data: gql.RoutinesPageQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.RoutinesPageDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the Settings query GQL with the variables and calls the appropriate callback
export async function requestSettings(apolloClient: ApolloClient<object>, variables: gql.SettingsQueryVariables, onSuccess: (data: gql.SettingsQuery["userAccount"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SettingsDocument, variables, (data) => onSuccess(data["userAccount"]), onError);
}
// Calls the Settings query GQL with the variables and calls the appropriate callback
export async function requestSettingsAllResults(apolloClient: ApolloClient<object>, variables: gql.SettingsQueryVariables, onSuccess: (data: gql.SettingsQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.SettingsDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the SetUserSettings mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserSettings(apolloClient: ApolloClient<object>, variables: gql.SetUserSettingsMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserSettingsDocument, "setUserSettings", variables, onSuccess, onError);
}

// Calls the UserTasks query GQL with the variables and calls the appropriate callback
export async function requestUserTasks(apolloClient: ApolloClient<object>, variables: gql.UserTasksQueryVariables, onSuccess: (data: gql.UserTasksQuery["userTasks"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserTasksDocument, variables, (data) => onSuccess(data["userTasks"]), onError);
}
// Calls the UserTasks query GQL with the variables and calls the appropriate callback
export async function requestUserTasksAllResults(apolloClient: ApolloClient<object>, variables: gql.UserTasksQueryVariables, onSuccess: (data: gql.UserTasksQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserTasksDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the SetUserTaskCompleted mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskCompleted(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskCompletedMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskCompletedDocument, "setUserTaskCompleted", variables, onSuccess, onError);
}

// Calls the UnplanTask mutation GQL with the variables and calls the appropriate callback
export async function requestUnplanTask(apolloClient: ApolloClient<object>, variables: gql.UnplanTaskMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.UnplanTaskDocument, "unplanTask", variables, onSuccess, onError);
}

// Calls the AddUnplannedTask mutation GQL with the variables and calls the appropriate callback
export async function requestAddUnplannedTask(apolloClient: ApolloClient<object>, variables: gql.AddUnplannedTaskMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.AddUnplannedTaskDocument, "addUnplannedTask", variables, onSuccess, onError);
}

// Calls the DeleteUnplannedTask mutation GQL with the variables and calls the appropriate callback
export async function requestDeleteUnplannedTask(apolloClient: ApolloClient<object>, variables: gql.DeleteUnplannedTaskMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.DeleteUnplannedTaskDocument, "deleteUnplannedTask", variables, onSuccess, onError);
}

// Calls the SetUserTaskOrder mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskOrder(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskOrderMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskOrderDocument, "setUserTaskOrder", variables, onSuccess, onError);
}

// Calls the SetUserTaskName mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskName(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskNameMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskNameDocument, "setUserTaskName", variables, onSuccess, onError);
}

// Calls the SetUserTaskRole mutation GQL with the variables and calls the appropriate callback
export async function requestSetUserTaskRole(apolloClient: ApolloClient<object>, variables: gql.SetUserTaskRoleMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.SetUserTaskRoleDocument, "setUserTaskRole", variables, onSuccess, onError);
}

// Calls the DeletePlannedTask mutation GQL with the variables and calls the appropriate callback
export async function requestDeletePlannedTask(apolloClient: ApolloClient<object>, variables: gql.DeletePlannedTaskMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.DeletePlannedTaskDocument, "deletePlannedTask", variables, onSuccess, onError);
}

// Calls the TodayPage query GQL with the variables and calls the appropriate callback
export async function requestTodayPage(apolloClient: ApolloClient<object>, variables: gql.TodayPageQueryVariables, onSuccess: (data: gql.TodayPageQuery["userDailyPlanForDate"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.TodayPageDocument, variables, (data) => onSuccess(data["userDailyPlanForDate"]), onError);
}
// Calls the TodayPage query GQL with the variables and calls the appropriate callback
export async function requestTodayPageAllResults(apolloClient: ApolloClient<object>, variables: gql.TodayPageQueryVariables, onSuccess: (data: gql.TodayPageQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.TodayPageDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the UserAccount query GQL with the variables and calls the appropriate callback
export async function requestUserAccount(apolloClient: ApolloClient<object>, variables: gql.UserAccountQueryVariables, onSuccess: (data: gql.UserAccountQuery["userAccount"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserAccountDocument, variables, (data) => onSuccess(data["userAccount"]), onError);
}
// Calls the UserAccount query GQL with the variables and calls the appropriate callback
export async function requestUserAccountAllResults(apolloClient: ApolloClient<object>, variables: gql.UserAccountQueryVariables, onSuccess: (data: gql.UserAccountQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserAccountDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the UserForInvite query GQL with the variables and calls the appropriate callback
export async function requestUserForInvite(apolloClient: ApolloClient<object>, variables: gql.UserForInviteQueryVariables, onSuccess: (data: gql.UserForInviteQuery["userForInvite"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserForInviteDocument, variables, (data) => onSuccess(data["userForInvite"]), onError);
}
// Calls the UserForInvite query GQL with the variables and calls the appropriate callback
export async function requestUserForInviteAllResults(apolloClient: ApolloClient<object>, variables: gql.UserForInviteQueryVariables, onSuccess: (data: gql.UserForInviteQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.UserForInviteDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the Invitations query GQL with the variables and calls the appropriate callback
export async function requestInvitations(apolloClient: ApolloClient<object>, variables: gql.InvitationsQueryVariables, onSuccess: (data: gql.InvitationsQuery["invitedUsers"]) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.InvitationsDocument, variables, (data) => onSuccess(data["invitedUsers"]), onError);
}
// Calls the Invitations query GQL with the variables and calls the appropriate callback
export async function requestInvitationsAllResults(apolloClient: ApolloClient<object>, variables: gql.InvitationsQueryVariables, onSuccess: (data: gql.InvitationsQuery) => void, onError: (err: string) => void) {
  await gqlQuery(apolloClient, gql.InvitationsDocument, variables, (data) => onSuccess(data), onError);
}

// Calls the Login mutation GQL with the variables and calls the appropriate callback
export async function requestLogin(apolloClient: ApolloClient<object>, variables: gql.LoginMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.LoginDocument, "login", variables, onSuccess, onError);
}

// Calls the ChangePassword mutation GQL with the variables and calls the appropriate callback
export async function requestChangePassword(apolloClient: ApolloClient<object>, variables: gql.ChangePasswordMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.ChangePasswordDocument, "changePassword", variables, onSuccess, onError);
}

// Calls the InviteSiteUser mutation GQL with the variables and calls the appropriate callback
export async function requestInviteSiteUser(apolloClient: ApolloClient<object>, variables: gql.InviteSiteUserMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.InviteSiteUserDocument, "inviteSiteUser", variables, onSuccess, onError);
}

// Calls the InviteRequestedSiteUser mutation GQL with the variables and calls the appropriate callback
export async function requestInviteRequestedSiteUser(apolloClient: ApolloClient<object>, variables: gql.InviteRequestedSiteUserMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.InviteRequestedSiteUserDocument, "inviteRequestedSiteUser", variables, onSuccess, onError);
}

// Calls the RequestInviteForSiteUser mutation GQL with the variables and calls the appropriate callback
export async function requestRequestInviteForSiteUser(apolloClient: ApolloClient<object>, variables: gql.RequestInviteForSiteUserMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RequestInviteForSiteUserDocument, "requestInviteForSiteUser", variables, onSuccess, onError);
}

// Calls the RegisterWithInvitation mutation GQL with the variables and calls the appropriate callback
export async function requestRegisterWithInvitation(apolloClient: ApolloClient<object>, variables: gql.RegisterWithInvitationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.RegisterWithInvitationDocument, "registerWithInvitation", variables, onSuccess, onError);
}

// Calls the ResendInvitation mutation GQL with the variables and calls the appropriate callback
export async function requestResendInvitation(apolloClient: ApolloClient<object>, variables: gql.ResendInvitationMutationVariables, onSuccess: (response: gql.SuccessResponse) => void, onError: (err: string) => void): Promise<gql.SuccessResponse> {
  return await gqlMutate(apolloClient, gql.ResendInvitationDocument, "resendInvitation", variables, onSuccess, onError);
}
