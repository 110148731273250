import React from "react";
import { EditablePlanTaskGroup } from "frontend-shared/util/modelTypes";
import { TimeCardDialog, TimeCardDialogProps } from "./timeCardDialog";

interface Props extends TimeCardDialogProps {
  objectId: string;
  timeCard: EditablePlanTaskGroup;
}

export const EditTimeCardDialog: React.FC<Props> = (props) => {
  return <TimeCardDialog title="Edit Time Card" saveButtonLabel={"Save Time Card"} showChips={false} {...props} />;
};
