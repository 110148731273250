import React from "react";
import { View } from "react-native-web";
import { Card, Text } from "react-native-paper";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppErrorScreen } from "../appErrorScreen";
import { IdentitiesStackParamList } from "../../App";
import { dateStringAddDays, nextDateOnDayOfWeek, toDateOnlyString, todayUTCDateMidnight } from "frontend-shared/util/dateUtils";
import { GetRoleIdentityScoreboardsQuery, RoleIdentityDataForScoreboardResponse } from "frontend-shared/generated/graphql";
import { range } from "frontend-shared/util/arrayUtils";
import { useApolloClient } from "@apollo/client";
import { requestGetRoleIdentityScoreboardsAllResults } from "frontend-shared/generated/graphqlWrappers";

interface Props {
  navigation: StackNavigationProp<IdentitiesStackParamList, "IdentityScoreboard">;
}

export const IdentityScoreboardScreen: React.FC<Props> = ({ navigation }) => {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [responseData, setResponseData] = React.useState<GetRoleIdentityScoreboardsQuery | null>(null);

  const weeksCount = 6;

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      if (responseData) {
        setResponseData(null);
      } else if (!responseData && !loading) {
        loadData();
      }
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  const roleIdentity = navigation.getState().routes[navigation.getState().index].params?.identity;
  if (!roleIdentity) {
    return <AppErrorScreen errorMessage={"No Role Identity"} navigation={navigation} />;
  }

  const loadData = () => {
    setLoading(true);
    setResponseData(null);
    requestGetRoleIdentityScoreboardsAllResults(
      apolloClient,
      { roleIdentityId: roleIdentity.id, weeks: weeksCount },
      (queryData) => {
        setResponseData(queryData);
        setLoading(false);
      },
      (errorMessage) => {
        setError(errorMessage);
        setLoading(false);
      }
    );
  };

  if (error) {
    return <AppErrorScreen errorMessage={error} navigation={navigation} />;
  }

  if (!loading && responseData === null) {
    return <AppErrorScreen errorMessage={"No Data"} navigation={navigation} />;
  }

  const todayString = toDateOnlyString(new Date().toISOString());
  const dataForDate = responseData?.userRoleIdentity.dataForWeekScoreboard.reduce((acc, d) => {
    acc[d.periodEndDate] = d;
    return acc;
  }, {} as { [key: string]: RoleIdentityDataForScoreboardResponse });
  const maxDailyCompletedBlocks =
    responseData?.userRoleIdentity.dataForWeekScoreboard.reduce((acc, d) => Math.max(acc, d.completedTimeBlockCount), 0) ?? 0;

  const dataForWeek = responseData?.userRoleIdentity.dataForWeeksScoreboard.reduce((acc, d) => {
    acc[d.periodEndDate] = d;
    return acc;
  }, {} as { [key: string]: RoleIdentityDataForScoreboardResponse });
  const maxWeeklyCompletedBlocks =
    responseData?.userRoleIdentity.dataForWeeksScoreboard.reduce((acc, d) => Math.max(acc, d.completedTimeBlockCount), 0) ?? 0;

  const nextSundayString = toDateOnlyString(nextDateOnDayOfWeek(todayUTCDateMidnight(), 0).toISOString());

  const goal = roleIdentity.weeklyPaceTimeBlockCount;
  const dayChartBlockWidth = maxDailyCompletedBlocks > 20 ? (maxDailyCompletedBlocks > 40 ? 2 : 5) : 10;
  const weekWeeklyBlocksOrGoal = Math.max(goal, maxWeeklyCompletedBlocks);
  const weekChartBlockWidth = weekWeeklyBlocksOrGoal > 20 ? (weekWeeklyBlocksOrGoal > 40 ? 2 : 5) : 10;
  const goalLeft = weekChartBlockWidth * goal;

  return (
    <>
      {!loading ? (
        <View style={{}}>
          <Card style={{ margin: 16 }}>
            <Card.Title title="Daily" />
            <Card.Content>
              {range(8).map((i) => {
                const dateString = dateStringAddDays(todayString, -i);
                const completedBlocks = dateString ? dataForDate?.[dateString]?.completedTimeBlockCount ?? 0 : 0;
                const barLength = dayChartBlockWidth * completedBlocks;
                return (
                  <View key={i} style={{ flexDirection: "row", gap: 8, height: 20, alignItems: "center" }}>
                    <Text>{dateString}</Text>
                    <View style={{ width: barLength, height: 10, backgroundColor: roleIdentity.color }} />
                    <Text>{completedBlocks === 0 ? "" : completedBlocks}</Text>
                  </View>
                );
              })}
            </Card.Content>
          </Card>
          <Card style={{ margin: 16 }}>
            <Card.Title title="Weekly" />
            <Card.Content>
              <View style={{ flexDirection: "row", gap: 8 }}>
                <View>
                  {range(weeksCount).map((i) => {
                    const dateString = dateStringAddDays(nextSundayString, -i * 7);
                    return (
                      <View key={i} style={{ flexDirection: "row", height: 20, gap: 8, alignItems: "center" }}>
                        <Text>{dateString}</Text>
                      </View>
                    );
                  })}
                </View>
                <View style={{ position: "relative" }}>
                  {range(weeksCount).map((i) => {
                    const dateString = dateStringAddDays(nextSundayString, -i * 7);
                    const completedBlocks = dateString ? dataForWeek?.[dateString]?.completedTimeBlockCount ?? 0 : 0;
                    const barLength = weekChartBlockWidth * completedBlocks;
                    return (
                      <View key={i} style={{ flexDirection: "row", height: 20, gap: 8, alignItems: "center" }}>
                        <View style={{ width: barLength, height: 10, backgroundColor: roleIdentity.color }} />
                        <Text>{completedBlocks === 0 ? "" : completedBlocks}</Text>
                      </View>
                    );
                  })}
                  {goal > 0 && (
                    <>
                      <View style={{ position: "absolute", left: goalLeft, top: -2, bottom: -2, width: 1, backgroundColor: "black" }} />
                      <View
                        style={{
                          position: "absolute",
                          left: goalLeft - 20,
                          top: -20,
                          width: 40,
                        }}
                      >
                        <Text style={{ textAlign: "center" }}>{goal}</Text>
                      </View>
                    </>
                  )}
                </View>
              </View>
            </Card.Content>
          </Card>
        </View>
      ) : (
        <>Loading...</>
      )}
    </>
  );
};
