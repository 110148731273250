import React from "react";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { selectIdentitiesState } from "frontend-shared/store/identitiesSlice";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { IdentityDataView } from "./identityTabScreen/identityDataView";
import { selectTodayPageState } from "frontend-shared/store/todayPageSlice";
import { selectBacklogState } from "frontend-shared/store/backlogSlice";
import { selectRoutinesState } from "frontend-shared/store/routinesSlice";
import { selectSettingsState } from "frontend-shared/store/settingsSlice";
import { TodayOptimisticAPIContext } from "frontend-shared/api/today.api.plan";
import { AppErrorScreen } from "./appErrorScreen";
import { IdentitiesStackParamList } from "src/App";
import { StackNavigationProp } from "@react-navigation/stack";
import { apiAddRoleIdentity, apiSetRoleIdentityArchived, apiUpdateRoleIdentity } from "frontend-shared/api/identities.api";
import { showSnackbarError } from "frontend-shared/store/appSnackbarSlice";
import { MobileSnackbar } from "./components/mobileSnackbar";

interface Props {
  navigation: StackNavigationProp<IdentitiesStackParamList, "IdentitiesMain">;
}

export const IdentityTabScreen: React.FC<Props> = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const identitiesState = useAppSelector(selectIdentitiesState);
  const todayPageState = useAppSelector(selectTodayPageState);
  const backlogState = useAppSelector(selectBacklogState);
  const routinesState = useAppSelector(selectRoutinesState);
  const settingsState = useAppSelector(selectSettingsState);
  const makeAPIContext = (client: ApolloClient<object>): TodayOptimisticAPIContext => {
    return { client, todayPageState, backlogState, routinesState, settingsState, identitiesState, dispatch };
  };

  if (identitiesState.errorMessage) {
    return <AppErrorScreen errorMessage={identitiesState.errorMessage} navigation={navigation} />;
  }

  return (
    <>
      {identitiesState.initialized ? (
        <ApolloConsumer>
          {(client) => {
            const ctx = makeAPIContext(client);
            return (
              <IdentityDataView
                identities={identitiesState.roleIdentities}
                newIdentity={(name, color, weeklyPaceTimeBlockCount) => {
                  apiAddRoleIdentity(
                    ctx,
                    name,
                    color,
                    weeklyPaceTimeBlockCount,
                    () => {},
                    (error) => {
                      dispatch(showSnackbarError(error));
                    }
                  );
                }}
                editIdentity={(identity) => {
                  apiUpdateRoleIdentity(ctx, identity);
                }}
                archiveIdentity={(identity) => {
                  apiSetRoleIdentityArchived(ctx, identity, true);
                }}
                scoreboardButtonTapped={(identity) => {
                  navigation.navigate("IdentityScoreboard", { identity });
                }}
              />
            );
          }}
        </ApolloConsumer>
      ) : (
        <>Loading...</>
      )}
      <MobileSnackbar />
    </>
  );
};
