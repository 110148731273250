import React from "react";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { TimeCards, TimeCardsProps } from "./timeCards";
import { DateNavigator, DateNavigatorProps } from "./dateNavigator";
import { NewTimeCardDialog } from "./newTimeCardDialog";
import { EditablePlanTaskGroup } from "frontend-shared/util/modelTypes";
import { GuideRail, GuideRailProps } from "./guideRail";
import { BottomFabGroup } from "../components/bottomFabGroup";
import { AddRoutinesDialog, AddRoutinesDialogProps } from "./addRoutinesDialog";

interface Props extends TimeCardsProps, DateNavigatorProps, GuideRailProps, AddRoutinesDialogProps {
  addTimeCard: (timeCard: EditablePlanTaskGroup) => void;
  showGuideRail: boolean;
  refetchData: () => void;
  isLoading: boolean;
}

export const PlanDataView: React.FC<Props> = (props: Props) => {
  const { taskGroups, isLoading, addTimeCard, roleIdentities, showGuideRail } = props;
  const [extendedFAB, setExtendedFAB] = React.useState(false);
  const [newTimeCardDialogVisible, setNewTimeCardDialogVisible] = React.useState(false);

  const [addRoutinesDialogVisible, setAddRoutinesDialogVisible] = React.useState(false);

  if (!isLoading) {
    const newExtendedFAB = taskGroups.length < 2;
    if (newExtendedFAB !== extendedFAB) {
      setExtendedFAB(newExtendedFAB);
    }
  }

  return (
    <View style={{ flex: 1, gap: "8px" }}>
      <DateNavigator {...props} />
      <View style={{ flexDirection: "row", flex: 1 }}>
        {showGuideRail && (
          <View style={{ flexGrow: 0 }}>
            <GuideRail {...props} />
          </View>
        )}
        {isLoading ? <LoadingState /> : taskGroups.length > 0 ? <TimeCards {...props} /> : <EmptyState />}
      </View>
      <BottomFabGroup
        closedIcon="plus"
        openIcon="plus"
        actions={[
          {
            icon: "clock-outline",
            label: "Add a Time Card",
            onPress: () => setNewTimeCardDialogVisible(true),
          },
          {
            icon: "autorenew",
            label: "Add a Routine",
            onPress: () => {
              setAddRoutinesDialogVisible(true);
            },
          },
        ]}
      />
      <NewTimeCardDialog
        roleIdentities={roleIdentities}
        visible={newTimeCardDialogVisible}
        cancel={() => {
          setNewTimeCardDialogVisible(false);
        }}
        save={(timeCard) => {
          addTimeCard(timeCard);
          setNewTimeCardDialogVisible(false);
        }}
      />
      <AddRoutinesDialog {...props} visible={addRoutinesDialogVisible} dismiss={() => setAddRoutinesDialogVisible(false)} />
    </View>
  );
};

const EmptyState = () => {
  return (
    <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
      <Text variant="bodyLarge" style={{ fontStyle: "italic" }}>
        No Time Cards yet
      </Text>
    </View>
  );
};

const LoadingState = () => {
  return (
    <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
      <ActivityIndicator size="large" />
      <Text variant="bodyLarge" style={{ fontStyle: "italic" }}>
        Loading...
      </Text>
    </View>
  );
};
