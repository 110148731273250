import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { TimerRunningState } from "../util/timerRunningState";
import { TimerType, timeForTimerType } from "../util/timerType";
import { TimerCookies } from "../cookies/todayPageCookies";

export interface TimerState {
  initialized: boolean;
  timerRunningState: TimerRunningState;
  timerType: TimerType;
  secondsLeftOnTimer: number;
  dateOfLastTickISOString: string;
  timeCard?: { id: string; name: string };
}

const initialState: TimerState = {
  initialized: false,
  timerRunningState: TimerRunningState.Stopped,
  timerType: TimerType.Work,
  secondsLeftOnTimer: 0,
  dateOfLastTickISOString: new Date().toISOString(),
  timeCard: undefined,
};

function setTimerStateUtil<T extends TimerState>(state: T, timerRunningState: TimerRunningState) {
  const oldTimerRunningState = state.timerRunningState;
  if (oldTimerRunningState === timerRunningState) {
    return;
  }
  state.timerRunningState = timerRunningState;
  if (timerRunningState === TimerRunningState.Running) {
    state.dateOfLastTickISOString = new Date().toISOString();
  } else if (timerRunningState === TimerRunningState.Stopped) {
    state.secondsLeftOnTimer = timeForTimerType(state.timerType);
    state.timeCard = undefined;
  }
}

export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    clearTimerState: (state) => {
      state.initialized = false;
      state.timerRunningState = initialState.timerRunningState;
      state.timerType = initialState.timerType;
      state.secondsLeftOnTimer = initialState.secondsLeftOnTimer;
      state.dateOfLastTickISOString = initialState.dateOfLastTickISOString;
      state.timeCard = initialState.timeCard;
    },
    initializeTimerState: (state, action: PayloadAction<{ timerState: TimerCookies }>) => {
      state.initialized = true;
      state.timerType = action.payload.timerState.timerType;
      state.secondsLeftOnTimer =
        action.payload.timerState.secondsLeft >= 0 ? action.payload.timerState.secondsLeft : timeForTimerType(action.payload.timerState.timerType);
      state.timerRunningState = action.payload.timerState.timerRunningState;
      state.dateOfLastTickISOString = action.payload.timerState.dateOfLastTickISOString;
      if (action.payload.timerState.timeCardId !== "" && action.payload.timerState.timeCardName !== "") {
        state.timeCard = { id: action.payload.timerState.timeCardId, name: action.payload.timerState.timeCardName };
      }
    },
    setTimerRunningState: (state, action: PayloadAction<{ timerRunningState: TimerRunningState }>) => {
      setTimerStateUtil(state, action.payload.timerRunningState);
    },
    setTimerTimeCard: (state, action: PayloadAction<{ timeCard?: { id: string; name: string } }>) => {
      if (action.payload.timeCard?.id !== "" && action.payload.timeCard?.name !== "") {
        state.timeCard = action.payload.timeCard;
      } else {
        state.timeCard = undefined;
      }
    },
    timerTick: (state, action: PayloadAction<{ dateISOString: string }>) => {
      const oldDateOfLastTick = new Date(state.dateOfLastTickISOString);
      const newDateOfLastTick = new Date(action.payload.dateISOString);

      const dateDiff = (newDateOfLastTick.getTime() - oldDateOfLastTick.getTime()) / 1000;
      state.dateOfLastTickISOString = action.payload.dateISOString;

      if (state.timerRunningState === TimerRunningState.Running) {
        if (state.secondsLeftOnTimer > 0) {
          if (dateDiff < state.secondsLeftOnTimer) {
            state.secondsLeftOnTimer = state.secondsLeftOnTimer - dateDiff;
          } else {
            state.secondsLeftOnTimer = 0;
          }
        } else {
          state.timerRunningState = TimerRunningState.Stopped;
          state.secondsLeftOnTimer = timeForTimerType(state.timerType);
        }
      }
    },
    timerEnd: (state, action: PayloadAction<{ dateISOString: string }>) => {
      const { dateISOString } = action.payload;
      state.dateOfLastTickISOString = dateISOString;

      if (state.timerRunningState === TimerRunningState.Running) {
        state.secondsLeftOnTimer = 0.1;
      } else if (state.timerRunningState === TimerRunningState.Paused) {
        state.secondsLeftOnTimer = 0.1;
        state.timerRunningState = TimerRunningState.Running;
      }
    },
    setTimerType: (state, action: PayloadAction<{ timerType: TimerType }>) => {
      setTimerStateUtil(state, TimerRunningState.Stopped);
      state.timerType = action.payload.timerType;
      state.secondsLeftOnTimer = timeForTimerType(state.timerType);
    },
  },
});

export const { clearTimerState, initializeTimerState, setTimerRunningState, timerTick, timerEnd, setTimerType, setTimerTimeCard } =
  timerSlice.actions;

export const selectTimerState = (state: RootState) => state.timer as TimerState;

export default timerSlice.reducer;
