import React from "react";
import { FlatList, View } from "react-native-web";
import { TaskWithCheckbox, TaskProps } from "../taskWithCheckbox";
import { BottomFab } from "src/app/components/bottomFab";
import { NewTaskDialog } from "./newTaskDialog";
import { TimeBlocks, TimeBlocksProps } from "../timeBlocks";
import { TimeCardChips, TimeCardChipsProps } from "../timeCardChips";
import { PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { TimeCardMenu, TimeCardMenuProps } from "../timeCardMenu";

interface Props extends TaskProps, TimeBlocksProps, TimeCardChipsProps, TimeCardMenuProps {
  taskGroup: PlanTaskGroup;
  isLoading: boolean;
  newTask: (name: string) => void;
}

export const TimeCardDataView: React.FC<Props> = (props) => {
  const { taskGroup, isLoading, newTask } = props;
  const [newTaskDialogVisible, setNewTaskDialogVisible] = React.useState(false);
  const [dialogKey, setDialogKey] = React.useState(0);

  return (
    <View style={{ flex: 1, flexGrow: 1 }}>
      <TimeBlocks
        {...props}
        pixelWidth={44}
        pixelHeight={44}
        borderThickness={2}
        containerStyle={{ margin: "8px", gap: "4px" }}
        showCompleted={true}
      />
      <View style={{ flexDirection: "row" }}>
        <TimeCardChips
          routineId={taskGroup.routineId}
          roleIdentityId={taskGroup.roleIdentityId}
          startTimeBlockNumber={taskGroup.startTimeBlockNumber}
          containerStyle={{ margin: "8px", gap: "4px" }}
          {...props}
        />
        <View style={{ flexGrow: 1 }}></View>
        <TimeCardMenu {...props} />
      </View>
      <FlatList
        keyExtractor={(item) => item.id}
        data={taskGroup.tasks}
        scrollEnabled={true}
        style={{ overscrollBehavior: "auto", flexGrow: 1, margin: "8px" }}
        renderItem={({ item }) => <TaskWithCheckbox {...props} task={item} />}
        refreshing={isLoading}
      />
      <BottomFab icon="plus" label="Add a Task" onPress={() => setNewTaskDialogVisible(true)} />
      <NewTaskDialog
        key={dialogKey}
        visible={newTaskDialogVisible}
        save={(task) => {
          newTask(task.name);
          setDialogKey(dialogKey + 1);
        }}
        cancel={() => setNewTaskDialogVisible(false)}
      />
    </View>
  );
};
