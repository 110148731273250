import React from "react";
import { Button, Dialog, Portal, TextInput } from "react-native-paper";
import { EditablePlanTaskGroup, emptyEditablePlanTaskGroup, PlanRoleIdentity } from "frontend-shared/util/modelTypes";
import { PortalDialogOverlay } from "../components/portalDialogOverlay";
import { IdentityChip } from "./identityChip";
import { View } from "react-native-web";

export interface TimeCardDialogProps {
  visible: boolean;
  cancel: () => void;
  save: (task: EditablePlanTaskGroup) => void;
  roleIdentities: PlanRoleIdentity[];
}

export interface Props extends TimeCardDialogProps {
  title: string;
  objectId: string;
  timeCard: EditablePlanTaskGroup;
  saveButtonLabel: string;
  showChips?: boolean;
}

export const TimeCardDialog: React.FC<Props> = ({ title, objectId, timeCard, visible, cancel, save, saveButtonLabel, roleIdentities, showChips }) => {
  const [newTimeCard, setNewTimeCard] = React.useState(timeCard);
  const [initialized, setInitialized] = React.useState(false);

  if (!visible && initialized) {
    setNewTimeCard(emptyEditablePlanTaskGroup());
    setInitialized(false);
    return <></>;
  } else if (visible && !initialized) {
    setNewTimeCard(timeCard);
    setInitialized(true);
    return <></>;
  }

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={cancel}>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Content style={{ gap: "8px" }}>
          <TextInput
            label="Name"
            autoFocus={true}
            value={newTimeCard.name ?? ""}
            onChangeText={(text) => setNewTimeCard({ ...newTimeCard, name: text !== "" ? text : null })}
            onSubmitEditing={() => {
              save(newTimeCard);
            }}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === "Escape") {
                cancel();
              }
            }}
          />
          {(showChips ?? true) && (
            <View style={{ flexDirection: "row" }}>
              <IdentityChip
                objectId={objectId}
                roleIdentityId={newTimeCard.roleIdentityId}
                roleIdentities={roleIdentities}
                setRoleIdentityFor={(objId, roleIdentity) => {
                  setNewTimeCard({ ...newTimeCard, roleIdentityId: roleIdentity?.id });
                }}
              />
            </View>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={cancel}>Cancel</Button>
          <Button
            mode="contained"
            onPress={() => {
              save(newTimeCard);
            }}
          >
            {saveButtonLabel}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
