import React from "react";
import { Button, Dialog, Portal, SegmentedButtons, Text } from "react-native-paper";
import { FlatList } from "react-native-gesture-handler";
import { PortalDialogOverlay } from "../components/portalDialogOverlay";
import { RoutinePlanTaskGroup } from "frontend-shared/util/modelTypes";
import { RoutineCard, RoutineCardProps } from "./routineCard";
import { View } from "react-native-web";
import { TimeCardChipsProps } from "./timeCardChips";

export interface AddRoutinesDialogProps extends TimeCardChipsProps, RoutineCardProps {
  routines: RoutinePlanTaskGroup[];
  todayRoutines: RoutinePlanTaskGroup[];
}

export interface Props extends AddRoutinesDialogProps {
  visible: boolean;
  dismiss: () => void;
}

export const AddRoutinesDialog: React.FC<Props> = (props) => {
  const [routineFilter, setRoutineFilter] = React.useState("today");
  const [initialized, setInitialized] = React.useState(false);

  const { routines, todayRoutines, visible, dismiss } = props;

  const data = routineFilter === "today" ? todayRoutines : routines;

  if (!visible && initialized) {
    setInitialized(false);
    return <></>;
  } else if (visible && !initialized) {
    setRoutineFilter("today");
    setInitialized(true);
    return <></>;
  }

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={dismiss}>
        <Dialog.Title>Add Routines</Dialog.Title>
        <Dialog.Content style={{ gap: "8px" }}>
          <View style={{ height: "350px", gap: 8, alignItems: data.length === 0 ? "center" : undefined }}>
            <SegmentedButtons
              value={routineFilter}
              onValueChange={setRoutineFilter}
              buttons={[
                { value: "today", label: "Today", icon: "calendar-today" },
                { value: "all", label: "All", icon: "dots-grid" },
              ]}
              style={{ alignSelf: "center", minWidth: "250px" }}
            />
            {data.length > 0 ? (
              <FlatList
                keyExtractor={(item) => item.id}
                scrollEnabled={true}
                data={data}
                renderItem={({ item }) => <RoutineCard routine={item} {...props} />}
                contentContainerStyle={{ gap: 4 }}
              />
            ) : (
              <Text style={{ flexGrow: 1 }}>No routines{routineFilter === "today" && " for today"}</Text>
            )}
          </View>
        </Dialog.Content>
        <Dialog.Actions>
          <Button mode="contained" onPress={dismiss}>
            Dismiss
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
