import { CommunityFullForScreen } from "frontend-shared/util/modelTypes";
import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

import { SharedTimeCard, SharedTimeCardProps } from "../planTabScreen/sharedTimeCard";

interface Props extends SharedTimeCardProps {
  communities: CommunityFullForScreen[];
}

export const CommunityDataView: React.FC<Props> = (props) => {
  const { communities } = props;
  if (communities.length === 0 || communities[0].sharedObjects.length === 0) {
    return <EmptyState />;
  }
  return (
    <View style={{ flex: 1, gap: "8px", overflowY: "scroll", overflowX: "hidden" }}>
      {communities[0].sharedObjects.map((sharedObject) => (
        <View key={sharedObject.id} style={{ gap: "8px", padding: "8px" }}>
          <Text variant="titleMedium">{`${sharedObject.userName} shared`}</Text>
          <SharedTimeCard sharedObject={sharedObject} {...props} />
        </View>
      ))}
    </View>
  );
};

const EmptyState = () => {
  return (
    <View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
      <Text variant="bodyLarge" style={{ fontStyle: "italic" }}>
        No Community Content yet
      </Text>
    </View>
  );
};
