import React from "react";
import { View } from "react-native";
import { IconButton } from "react-native-paper";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { getPlanToolbarCookies, setPlanToolbarCookies } from "frontend-shared/cookies/todayPageCookies";
import { selectTodayPageState, setRoleViewCompleted } from "frontend-shared/store/todayPageSlice";
import { apiSetAllTaskGroupsCollapsed } from "frontend-shared/api/today.api.taskGroup";
import { TodayOptimisticAPIContext } from "frontend-shared/api/today.api.plan";
import { useApolloClient } from "@apollo/client";
import { selectBacklogState } from "frontend-shared/store/backlogSlice";
import { selectRoutinesState } from "frontend-shared/store/routinesSlice";
import { selectSettingsState } from "frontend-shared/store/settingsSlice";
import { selectIdentitiesState } from "frontend-shared/store/identitiesSlice";
import { selectTimerState } from "frontend-shared/store/timerSlice";
import { TimerControlButtons } from "./timerProvider/timerControlButtons";

interface Props {}

export const PlanTabScreenHeaderButtons: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const todayPageState = useAppSelector(selectTodayPageState);
  const backlogState = useAppSelector(selectBacklogState);
  const routinesState = useAppSelector(selectRoutinesState);
  const settingsState = useAppSelector(selectSettingsState);
  const identitiesState = useAppSelector(selectIdentitiesState);
  const timerState = useAppSelector(selectTimerState);
  const planTabBarCookies = getPlanToolbarCookies();

  const showCompleted = (todayPageState.initialized ? todayPageState.roleViewCompleted : planTabBarCookies.roleViewCompleted) === "Normal";
  const [showCollapse, setShowCollapse] = React.useState(true);

  const makeAPIContext = (): TodayOptimisticAPIContext => {
    return { client, todayPageState, backlogState, routinesState, settingsState, identitiesState, dispatch };
  };

  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <TimerControlButtons timerState={timerState} />
      {showCollapse ? (
        <IconButton
          onPress={() => {
            setShowCollapse(false);
            apiSetAllTaskGroupsCollapsed(makeAPIContext(), true);
          }}
          icon="unfold-less-horizontal"
          iconColor="white"
        />
      ) : (
        <IconButton
          onPress={() => {
            setShowCollapse(true);
            apiSetAllTaskGroupsCollapsed(makeAPIContext(), false);
          }}
          icon="unfold-more-horizontal"
          iconColor="white"
        />
      )}
      <IconButton
        onPress={() => {
          const newRoleViewCompleted = showCompleted ? "Hide" : "Normal";
          dispatch(setRoleViewCompleted({ roleViewCompleted: newRoleViewCompleted }));

          planTabBarCookies.roleViewCompleted = newRoleViewCompleted;
          setPlanToolbarCookies(planTabBarCookies);
        }}
        icon={showCompleted ? "filter-off" : "filter"}
        iconColor="white"
      />
    </View>
  );
};
