import React from "react";
import { CommunityDataView } from "./communityTabScreen/communityDataView";
import { MobileSnackbar } from "./components/mobileSnackbar";
import { selectUserCommunitiesState } from "frontend-shared/store/userCommunitiesSlice";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { apiReactToSharedObject, apiUnreactToSharedObject, UserCommunityOptimisticAPIContext } from "frontend-shared/api/userCommunities.api";
import { useApolloClient } from "@apollo/client";

interface Props {}

export const CommunityTabScreen: React.FC<Props> = () => {
  const userCommunitiesState = useAppSelector(selectUserCommunitiesState);
  const dispatch = useAppDispatch();
  const client = useApolloClient();

  const apiContext: UserCommunityOptimisticAPIContext = {
    client,
    userCommunitiesState: userCommunitiesState,
    dispatch,
  };

  return (
    <>
      <CommunityDataView
        communities={userCommunitiesState.communitiesFull}
        reactToSharedObject={(sharedObjectId: string, reactionType: string) => {
          apiReactToSharedObject(apiContext, userCommunitiesState.communitiesFull[0].id ?? "", sharedObjectId, reactionType);
        }}
        unreactToSharedObject={(sharedObjectId: string, reactionType: string) => {
          apiUnreactToSharedObject(apiContext, userCommunitiesState.communitiesFull[0].id ?? "", sharedObjectId, reactionType);
        }}
      />
      <MobileSnackbar />
    </>
  );
};
