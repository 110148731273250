import { Text, FAB } from "react-native-paper";
import { View } from "react-native-web";

interface Props {
  addIdentity: () => void;
}

export const EmptyIdentityTabScreen: React.FC<Props> = ({ addIdentity }) => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text style={{ textAlign: "center", margin: "32px" }} variant="headlineSmall">
        You don't have any identities in RTG yet.
      </Text>
      <FAB style={{}} icon="plus" label="Add an Identity" onPress={addIdentity} />
    </View>
  );
};
