import { ApolloClient } from "@apollo/client";
import {
  SettingsState,
  setSettingsDefaultEndTimeBlockNumber,
  setSettingsDefaultStartTimeBlockNumber,
  setSettingsDisplayName,
  setSettingsGrayIsDefault,
  setSettingsShowGuideRailTimeInterval,
} from "frontend-shared/store/settingsSlice";
import { AppDispatch } from "frontend-shared/store/store";
import { optimisticUpdateAndServerRequestWithUndo } from "frontend-shared/api/api.utils";
import { requestSetUserSettings } from "frontend-shared/generated/graphqlWrappers";

export interface SettingsOptimisticAPIContext {
  client: ApolloClient<object>;
  settingsState: SettingsState;
  dispatch: AppDispatch;
}

export const apiSetSettings = (
  apiContext: SettingsOptimisticAPIContext,
  displayName: string,
  grayIsDefault: boolean,
  defaultStartTimeBlockNumber: number,
  defaultEndTimeBlockNumber: number,
  showGuideRailTimeInterval: number
) => {
  const { client, settingsState, dispatch } = apiContext;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: [
      setSettingsDisplayName({ displayName }),
      setSettingsGrayIsDefault({ grayIsDefault }),
      setSettingsDefaultStartTimeBlockNumber({ defaultStartTimeBlockNumber }),
      setSettingsDefaultEndTimeBlockNumber({ defaultEndTimeBlockNumber }),
      setSettingsShowGuideRailTimeInterval({ showGuideRailTimeInterval }),
    ],
    request: requestSetUserSettings,
    variables: { displayName, grayIsDefault, defaultStartTimeBlockNumber, defaultEndTimeBlockNumber, showGuideRailTimeInterval },
    undo: [
      setSettingsDisplayName({ displayName: settingsState.displayName }),
      setSettingsGrayIsDefault({ grayIsDefault: settingsState.grayIsDefault }),
      setSettingsDefaultStartTimeBlockNumber({
        defaultStartTimeBlockNumber: settingsState.defaultStartTimeBlockNumber,
      }),
      setSettingsDefaultEndTimeBlockNumber({ defaultEndTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }),
      setSettingsShowGuideRailTimeInterval({ showGuideRailTimeInterval: settingsState.showGuideRailTimeInterval }),
    ],
  });
};
