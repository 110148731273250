import React from "react";
import { Text, Card, FAB, useTheme, Icon } from "react-native-paper";
import { PlanRoleIdentity, RoutinePlanTaskGroup } from "frontend-shared/util/modelTypes";
import { TimeCardChips, TimeCardChipsProps } from "./timeCardChips";
import { TimeBlocks } from "./timeBlocks";
import { View } from "react-native-web";
import { daysOfTheWeekAbbreviations } from "frontend-shared/util/dateUtils";

export interface RoutineCardProps extends TimeCardChipsProps {
  addRoutineToPlan: (routine: RoutinePlanTaskGroup) => void;
  roleIdentities: PlanRoleIdentity[];
}

interface Props extends RoutineCardProps {
  routine: RoutinePlanTaskGroup;
}

export const RoutineCard: React.FC<Props> = (props) => {
  const { routine, addRoutineToPlan, roleIdentities } = props;
  const theme = useTheme();

  return (
    <Card style={{ marginLeft: "16px", marginRight: "16px", paddingBottom: "16px" }}>
      <Card.Title
        title={
          <Text style={{ userSelect: "none", WebkitUserSelect: "none" }} variant="titleLarge">
            {routine.name}
          </Text>
        }
        right={(cardProps) => (
          <TimeBlocks
            showCompleted={true}
            containerStyle={{ marginRight: "16px" }}
            roleIdentities={roleIdentities}
            objectId={routine.id}
            roleIdentityId={routine.roleIdentityId}
            timeBlockCount={routine.timeBlockCount}
            completedTimeBlockCount={0}
          />
        )}
      />
      <Card.Content style={{ position: "relative", gap: 14 }}>
        <TimeCardChips
          {...props}
          objectId={routine.id}
          roleIdentityId={routine.roleIdentityId}
          timeBlockCount={0}
          startTimeBlockNumber={undefined}
          chipsAreEditable={false}
        />
        <View style={{ gap: "4px" }}>
          {routine.tasks.map((task) => (
            <View key={task.id} style={{ flexDirection: "row", gap: "2px", alignItems: "flex-start" }}>
              <Icon source="square-outline" size={20} color="#ccc" />
              <Text>{task.name}</Text>
            </View>
          ))}
        </View>
        <View style={{ flexDirection: "row", gap: "8px", alignItems: "center" }}>
          {daysOfTheWeekAbbreviations.map((dayAbbreviation, index) => (
            <Text key={index} style={{ color: routine.daysOfTheWeek & (1 << index) ? theme.colors.primary : "#999" }}>
              {dayAbbreviation}
            </Text>
          ))}
        </View>

        <FAB
          style={{ position: "absolute", bottom: "-4px", right: "8px" }}
          size="small"
          icon="plus"
          onPress={() => {
            addRoutineToPlan(routine);
          }}
        />
      </Card.Content>
    </Card>
  );
};
