import React from "react";
import { NewIdentityDialog } from "./newIdentityDialog";
import { IdentitiesList, IdentityListProps } from "./identitiesList";
import { EmptyIdentityTabScreen } from "./emptyIdentityTabScreen";
import { BottomFab } from "../components/bottomFab";

interface Props extends IdentityListProps {
  newIdentity: (name: string, color: string, weeklyPaceTimeBlockCount: number) => void;
}

export const IdentityDataView: React.FC<Props> = (props) => {
  const { identities, newIdentity } = props;
  const [newIdentityDialogVisible, setNewIdentityDialogVisible] = React.useState(false);

  return (
    <>
      {identities.length > 0 ? (
        <>
          <IdentitiesList {...props} />
          <BottomFab icon="plus" label="Add an Identity" onPress={() => setNewIdentityDialogVisible(true)} />
        </>
      ) : (
        <EmptyIdentityTabScreen addIdentity={() => setNewIdentityDialogVisible(true)} />
      )}
      <NewIdentityDialog
        visible={newIdentityDialogVisible}
        save={(identity) => {
          newIdentity(identity.name, identity.color, identity.weeklyPaceTimeBlockCount);
          setNewIdentityDialogVisible(false);
        }}
        cancel={() => setNewIdentityDialogVisible(false)}
      />
    </>
  );
};
