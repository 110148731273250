import React from "react";
import { View } from "react-native";
import { PlanTask } from "frontend-shared/util/modelTypes";
import { TaskProps, TaskWithCheckbox } from "./taskWithCheckbox";
import { IconButton } from "react-native-paper";

interface TaskListProps {
  objectId: string;
  setCollapsed?: (objectId: string, collapsed: boolean) => void;
}

interface Props extends TaskListProps, TaskProps {
  tasks: PlanTask[];
  collapsed: boolean;
}

export const TaskList: React.FC<Props> = (props) => {
  const { tasks, collapsed, setCollapsed, objectId } = props;
  const firstIncompleteTask = tasks.find((task) => !task.completed) ?? tasks[0];
  const tasksToShow = collapsed && tasks.length > 0 ? [firstIncompleteTask] : tasks;

  const showCollapsedButton = tasks.length > 1;

  return (
    <View style={{ flexDirection: "row", alignContent: "space-between" }}>
      <View style={{ flexShrink: 1 }}>
        {tasksToShow.map((task) => (
          <TaskWithCheckbox key={task.id} task={task} {...props} />
        ))}
      </View>

      {showCollapsedButton && (
        <View style={{ flexGrow: 1, justifyContent: "center", alignItems: "flex-end" }}>
          <IconButton
            size={16}
            icon={collapsed ? "unfold-more-horizontal" : "unfold-less-horizontal"}
            onPress={() => setCollapsed?.(objectId, !collapsed)}
          />
        </View>
      )}
    </View>
  );
};
