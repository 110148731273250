import React from "react";
import { Button, Dialog, Portal } from "react-native-paper";
import { TimeSelectMenu } from "../components/timeSelectMenu";
import { PortalDialogOverlay } from "../components/portalDialogOverlay";

interface Props {
  visible: boolean;
  timeBlockNumber?: number;
  cancel: () => void;
  save: (timeBlockNumber?: number) => void;
}

export const TimeCardStartTimeDialog: React.FC<Props> = ({ timeBlockNumber, visible, cancel, save }) => {
  const [initialized, setInitialized] = React.useState(false);
  const [startTimeBlockNumberState, setStartTimeBlockNumberState] = React.useState<number>(timeBlockNumber ?? 24);

  if (!visible && initialized) {
    setStartTimeBlockNumberState(0);
    setInitialized(false);
    return <></>;
  } else if (visible && !initialized) {
    setStartTimeBlockNumberState(timeBlockNumber ?? 24);
    setInitialized(true);
    return <></>;
  }

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={cancel}>
        <Dialog.Title>Set Start Time</Dialog.Title>
        <Dialog.Content>
          <TimeSelectMenu label="Start Time" timeBlockNumber={startTimeBlockNumberState} setTimeBlockNumber={setStartTimeBlockNumberState} />
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={cancel}>Cancel</Button>
          {timeBlockNumber && (
            <Button
              onPress={() => {
                save(undefined);
              }}
            >
              Clear Start Time
            </Button>
          )}
          <Button
            mode="contained"
            onPress={() => {
              save(startTimeBlockNumberState);
            }}
          >
            OK
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
