import { CommunityForScreen, PlanTaskGroup } from "frontend-shared/util/modelTypes";
import React from "react";
import { IconButton, Menu } from "react-native-paper";
import { TimeCardStartTimeDialog } from "./timeCardStartTimeDialog";
import { RoutineDialog } from "./timeCardScreen/routineDialog";
import { DatePickerDialog } from "./datePickerDialog";
import { dateAddingDays, formatDateSimple, parseDateSimple } from "frontend-shared/util/dateUtils";
import { CommunityPickerDialog } from "./communityPickerDialog";

export interface TimeCardMenuProps {
  planDate: Date;
  setStartTime(taskGroup: PlanTaskGroup, timeBlockNumber?: number): void;
  updateRoutine?: (objectId: string, routineId: string) => void;
  createRoutine?: (objectId: string, routineName: string) => void;
  moveIncompleteTasks?: (objectId: string, date: Date) => void;
  setCollapsed?: (objectId: string, collapsed: boolean) => void;
  shareToCommunity?: (objectId: string, communityId: string) => void;
  communities: CommunityForScreen[];
}

interface Props extends TimeCardMenuProps {
  taskGroup: PlanTaskGroup;
}

export const TimeCardMenu: React.FC<Props> = ({
  planDate,
  taskGroup,
  setStartTime,
  updateRoutine,
  createRoutine,
  moveIncompleteTasks,
  shareToCommunity,
  communities,
}) => {
  const [timeCardMenuVisible, setTimeCardMenuVisible] = React.useState(false);
  const [startTimeDialogVisible, setStartTimeDialogVisible] = React.useState(false);
  const [routineDialogVisible, setRoutineDialogVisible] = React.useState(false);
  const [datePickerVisible, setDatePickerVisible] = React.useState(false);
  const [communityPickerVisible, setCommunityPickerVisible] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(planDate);

  const hasIncompleteTasks = taskGroup.tasks.some((task) => !task.completed);

  return (
    <>
      <Menu
        anchor={
          <IconButton
            size={16}
            icon="dots-horizontal"
            iconColor="white"
            mode="contained"
            style={{ backgroundColor: "#aaa" }}
            onPress={() => {
              setTimeCardMenuVisible(true);
            }}
          />
        }
        visible={timeCardMenuVisible}
        onDismiss={() => setTimeCardMenuVisible(false)}
      >
        <Menu.Item
          title={"Set Start Time…"}
          onPress={() => {
            setStartTimeDialogVisible(true);
            setTimeCardMenuVisible(false);
          }}
        />
        {taskGroup.routineId ? (
          <Menu.Item
            title={"Update Routine"}
            onPress={() => {
              if (taskGroup.routineId) {
                updateRoutine?.(taskGroup.id, taskGroup.routineId);
              }
              setTimeCardMenuVisible(false);
            }}
          />
        ) : (
          <Menu.Item
            title={"Create Routine…"}
            onPress={() => {
              setRoutineDialogVisible(true);
              setTimeCardMenuVisible(false);
            }}
          />
        )}
        <Menu.Item
          title={"Move Incomplete Tasks…"}
          titleStyle={{ color: !hasIncompleteTasks ? "#aaa" : "black" }}
          onPress={() => {
            setSelectedDate(dateAddingDays(planDate, 1));
            setDatePickerVisible(true);
            setTimeCardMenuVisible(false);
          }}
          disabled={!hasIncompleteTasks}
        />
        <Menu.Item
          title="Share…"
          onPress={() => {
            setCommunityPickerVisible(true);
            setTimeCardMenuVisible(false);
          }}
          disabled={communities.length === 0}
        />
      </Menu>
      <TimeCardStartTimeDialog
        timeBlockNumber={taskGroup.startTimeBlockNumber}
        visible={startTimeDialogVisible}
        cancel={() => setStartTimeDialogVisible(false)}
        save={(timeBlockNumber) => {
          setStartTime(taskGroup, timeBlockNumber);
          setStartTimeDialogVisible(false);
        }}
      />
      <RoutineDialog
        routineName={taskGroup.name ?? ""}
        visible={routineDialogVisible}
        cancel={() => {
          setRoutineDialogVisible(false);
        }}
        save={(routineName) => {
          createRoutine?.(taskGroup.id, routineName);
          setRoutineDialogVisible(false);
        }}
      />
      <DatePickerDialog
        title={"Move Incomplete Tasks"}
        visible={datePickerVisible}
        dateString={formatDateSimple(selectedDate)}
        onDismiss={() => setDatePickerVisible(false)}
        isDateDisabled={(dateString) => dateString === formatDateSimple(planDate)}
        onConfirm={(dateString) => {
          const date = parseDateSimple(dateString);
          if (date) {
            moveIncompleteTasks?.(taskGroup.id, date);
          }
          setDatePickerVisible(false);
        }}
      />
      <CommunityPickerDialog
        visible={communityPickerVisible}
        communities={communities}
        selectedCommunityIndex={0}
        onDismiss={() => setCommunityPickerVisible(false)}
        onConfirm={(communityIndex) => {
          setCommunityPickerVisible(false);
          shareToCommunity?.(taskGroup.id, communities[communityIndex].id);
        }}
      />
    </>
  );
};
