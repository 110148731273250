import React from "react";
import { View } from "react-native-web";
import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import { PlanRoleIdentity } from "frontend-shared/util/modelTypes";
import { range } from "frontend-shared/util/arrayUtils";
import { Icon, MD3Theme, useTheme } from "react-native-paper";
import { calculateTimeBlocksToRender, countToSetBasedOnIndexAndCurrent } from "frontend-shared/util/timeCardUtils";
import { textColorForBackgroundColor } from "frontend-shared/util/colorUtils";

export interface TimeBlocksProps {
  objectId: string;
  roleIdentityId?: string;
  fallbackIdentityColor?: string;
  timeBlockCount: number;
  completedTimeBlockCount: number;
  roleIdentities: PlanRoleIdentity[];
  setTimeBlockCount?: (index: number) => void;
  setTimeBlockCompletedCount?: (index: number) => void;
}

interface Props extends TimeBlocksProps {
  pixelWidth?: number;
  pixelHeight?: number;
  borderThickness?: number;
  containerStyle?: ViewStyle;
  showCompleted?: boolean;
}

export const defaultIdentityColor = "#aaa";
const defaultPixelWidth = 10;
const defaultPixelHeight = 24;
export const timeBlockBackgroundColor = "#ddd";

export const TimeBlocks: React.FC<Props> = ({
  objectId,
  roleIdentityId,
  timeBlockCount,
  completedTimeBlockCount,
  setTimeBlockCount,
  setTimeBlockCompletedCount,
  pixelWidth,
  pixelHeight,
  borderThickness,
  containerStyle,
  showCompleted,
  roleIdentities,
  fallbackIdentityColor,
}) => {
  const identityColor = roleIdentities.find((roleIdentity) => roleIdentity.id === roleIdentityId)?.color ?? fallbackIdentityColor;
  const theme = useTheme();
  const styles = makeStyles(theme, pixelWidth, pixelHeight, borderThickness, identityColor);
  const containerStyles: ViewStyle[] = [styles.container, containerStyle ?? {}];

  const timeBlocksToRender = calculateTimeBlocksToRender(5, timeBlockCount, completedTimeBlockCount);

  return (
    <View style={containerStyles}>
      {range(timeBlocksToRender).map((i) => {
        const buttonStyles = [styles.timeBlockButton, i < timeBlockCount ? styles.timeBlockButtonFilled : styles.timeBlockButtonEmpty];
        const buttonBorderStyles = [{ backgroundColor: "black" }];
        const iconVisibility = (showCompleted ?? false) && i < completedTimeBlockCount ? "visible" : "hidden";
        return (
          <TouchableOpacity
            key={i}
            style={buttonBorderStyles}
            onLongPress={() => setTimeBlockCompletedCount?.(countToSetBasedOnIndexAndCurrent(i, completedTimeBlockCount))}
            onPress={() => setTimeBlockCount?.(countToSetBasedOnIndexAndCurrent(i, timeBlockCount))}
            activeOpacity={0.9}
          >
            <View style={buttonStyles}>
              <View style={[styles.icon, { visibility: iconVisibility }]}>
                <Icon
                  size={(pixelWidth ?? defaultPixelWidth) * 0.8}
                  source="circle"
                  color={textColorForBackgroundColor(identityColor ?? defaultIdentityColor)}
                />
              </View>
            </View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const makeStyles = (theme: MD3Theme, pixelWidth?: number, pixelHeight?: number, borderThickness?: number, identityColor?: string) => {
  const height = pixelHeight ?? defaultPixelHeight;
  const width = pixelWidth ?? defaultPixelWidth;

  const margin = borderThickness ?? 1;
  return StyleSheet.create({
    container: {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "2px",
      flexGrow: 0,
      maxWidth: "95%",
    },
    timeBlockButton: {
      width: `${width - 2 * margin}px`,
      height: `${height - 2 * margin}px`,
      margin: `${margin}px`,
    },
    timeBlockButtonFilled: {
      backgroundColor: identityColor ?? defaultIdentityColor,
    },
    timeBlockButtonEmpty: {
      backgroundColor: timeBlockBackgroundColor,
    },
    icon: {
      margin: "auto",
    },
  });
};
