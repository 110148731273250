import { Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { TodayPageState } from "./todayPageSlice";
import { BacklogState } from "./backlogSlice";
import {
  PlanRole,
  PlanTaskGroup,
  calculateOrderAfterReorder,
  PlanTask,
  PlannedBlockType,
  RoleBlockType,
  PlanProject,
  DEFAULT_TIME_BLOCK_COUNT,
  ROLE_BLOCK_BREAK,
  createRoleBlockType,
} from "../util/modelTypes";
import { TimerRunningState } from "../util/timerRunningState";
import { timeForTimerType } from "../util/timerType";
import { compareDates } from "../util/dateUtils";
import { TimerState } from "./timerSlice";

export const shouldBlockPlanUIFromState = (todayPageState: TodayPageState, backlogState: BacklogState) => {
  return (
    !todayPageState.initialized ||
    todayPageState.editingInProgress ||
    todayPageState.addTaskInFlight ||
    !backlogState.initialized ||
    backlogState.editingInProgress ||
    backlogState.addTaskInFlight
  );
};

export const shouldBlockUnplannedUIFromState = (todayPageState: TodayPageState, backlogState: BacklogState) => {
  return (
    !todayPageState.initialized ||
    todayPageState.editingInProgress ||
    todayPageState.addTaskInFlight ||
    !backlogState.initialized ||
    backlogState.editingInProgress ||
    backlogState.addTaskInFlight
  );
};

export const generateRolesList = (roles: Dictionary<PlanRole>) => {
  return Object.values(roles).filter((role): role is PlanRole => !!role);
};

export const generateProjectsList = (projects: Dictionary<PlanProject>) => {
  const projectList = Object.values(projects).filter((project): project is PlanProject => !!project);
  projectList.sort((a, b) => a.name.localeCompare(b.name));
  return projectList;
};

export const colorForRoleInState = (state: TodayPageState, roleId: string) => {
  return state.roles[roleId]?.color ?? "#000000";
};

export const colorForRole = (roles: Dictionary<PlanRole>, roleId: string) => {
  return roles[roleId]?.color ?? "#000000";
};

export const calculateTaskGroupOrderAfterReorder = (oldIndex: number, newIndex: number, taskGroups: PlanTaskGroup[]) => {
  return calculateOrderAfterReorder(oldIndex, newIndex, taskGroups);
};

export const calculateTaskOrderAfterReorder = (oldIndex: number, newIndex: number, tasks: PlanTask[]) => {
  return calculateOrderAfterReorder(oldIndex, newIndex, tasks);
};

export function totalTimeBlocksCompleted(state: TodayPageState, role: PlanRole) {
  return state.taskGroups.reduce((sum, group) => {
    if (group.roleId === role.id) {
      return sum + group.completedTimeBlockCount;
    } else {
      return sum;
    }
  }, 0);
}

export function grayTimeBlocksCompleted(state: TodayPageState, role: PlanRole) {
  return state.taskGroups.reduce((sum, group) => {
    if (group.roleId === role.id && group.isGrayTime) {
      return sum + group.completedTimeBlockCount;
    } else {
      return sum;
    }
  }, 0);
}

export function addUnplannedTaskUtil<T extends BacklogState>(
  state: T,
  action: PayloadAction<{ taskId: string; roleId: string; name: string; order: number }>
): PlanTask {
  const task = {
    id: action.payload.taskId,
    name: action.payload.name,
    completed: false,
    order: action.payload.order,
    roleId: action.payload.roleId,
  };
  // Put the task in the unplanned list in the right order
  const index = state.unplannedTasks.findIndex((task) => task.order > action.payload.order);
  if (index >= 0) {
    state.unplannedTasks.splice(index, 0, task);
  } else {
    state.unplannedTasks.push(task);
  }
  return task;
}

export function addTaskToPlanUtil<T extends TodayPageState>(
  state: T,
  action: PayloadAction<{
    taskGroupId: string;
    taskGroupOrder: number;
    task: PlanTask;
    grayIsDefault: boolean;
    recoveryData?: {
      taskGroupName: string | null;
      roleId: string;
      timeBlockCount: number;
      completedTimeBlockCount: number;
      routineId?: string;
      projectId?: string;
      roleIdentityId?: string;
      isGrayTime: boolean;
    };
  }>
) {
  // If the taskGroup exists, use it. Otherwise, create a new one
  const timeBlockCount = action.payload.recoveryData?.timeBlockCount ?? DEFAULT_TIME_BLOCK_COUNT;
  const completedTimeBlockCount = action.payload.recoveryData?.completedTimeBlockCount ?? 0;
  const routineId = action.payload.recoveryData?.routineId;
  const projectId = action.payload.recoveryData?.projectId;
  const roleIdentityId = action.payload.recoveryData?.roleIdentityId;
  const grayIsDefault = action.payload.grayIsDefault;

  const existingTaskGroup = state.taskGroups.find((group) => group.id === action.payload.taskGroupId);
  const taskGroup = existingTaskGroup ?? {
    id: action.payload.taskGroupId,
    name: action.payload.recoveryData?.taskGroupName ?? null,
    roleId: action.payload.recoveryData?.roleId ?? action.payload.task.roleId,
    timeBlockCount,
    completedTimeBlockCount,
    order: action.payload.taskGroupOrder,
    collapsed: false,
    isGrayTime: action.payload.recoveryData?.isGrayTime ?? grayIsDefault,
    tasks: [],
    routineId,
    projectId,
    roleIdentityId,
  };
  // Add the task to the taskGroup in the right order
  const index = taskGroup.tasks.findIndex((task) => task.order > action.payload.task.order);
  if (index >= 0) {
    taskGroup.tasks.splice(index, 0, action.payload.task);
  } else {
    taskGroup.tasks.push(action.payload.task);
  }

  // If the taskGroup didn't exist, add it to the list in the right order
  if (!existingTaskGroup) {
    const index = state.taskGroups.findIndex((group) => group.order > action.payload.taskGroupOrder);
    if (index >= 0) {
      state.taskGroups.splice(index, 0, taskGroup);
    } else {
      state.taskGroups.push(taskGroup);
    }
  }
}

export function removeTaskFromPlanUtil<T extends TodayPageState>(
  state: T,
  action: PayloadAction<{
    taskId: string;
  }>,
  removeEmptyGroup: boolean
): PlanTask | undefined {
  const taskGroup = state.taskGroups.find((group) => group.tasks.find((task) => task.id === action.payload.taskId));
  if (taskGroup) {
    const task = taskGroup.tasks.find((task) => task.id === action.payload.taskId);
    if (!task) {
      return;
    }
    taskGroup.tasks = taskGroup.tasks.filter((task) => task.id !== action.payload.taskId);
    if (removeEmptyGroup) {
      removeTaskGroupIfEmpty(state, taskGroup);
    }
    return task;
  }
}

function removeTaskGroupIfEmpty<T extends TodayPageState>(state: T, taskGroup: PlanTaskGroup) {
  // Remove the group if there are no tasks in it and update guiderail
  if (taskGroup !== undefined && taskGroup.tasks.length === 0) {
    state.taskGroups = state.taskGroups.filter((group) => group.id !== taskGroup.id);
    setGuideRail(state, taskGroup.startTimeBlockNumber, taskGroup.timeBlockCount, ROLE_BLOCK_BREAK);
  }
}

function isBlockInPlannedTime<T extends TodayPageState>(state: T, index: number) {
  return index >= state.startTimeBlockNumber && index <= state.endTimeBlockNumber;
}

export function getRoleCountsForRoleId<T extends TodayPageState>(state: T, roleIds: string[]) {
  return roleIds.reduce((counts, roleId) => {
    counts[roleId] = state.plannedBlocks.filter(
      (block, index) =>
        isBlockInPlannedTime(state, index) &&
        block.role?.id === roleId &&
        (block.blockType === PlannedBlockType.STRATEGIC || block.blockType === PlannedBlockType.AUTO_MEETING)
    ).length;
    return counts;
  }, {} as Dictionary<number>);
}

export function recalculateRoleCountsFromDictionary<T extends TodayPageState>(state: T, roles: Dictionary<PlanRole>) {
  state.roleBlockCounts = getRoleCountsForRoleId(
    state,
    Object.values(roles)
      .filter((role): role is PlanRole => !!role)
      .map((role) => role.id)
  );
}

export const allTasksCompletedInTaskGroup = (taskGroup: PlanTaskGroup) => {
  return taskGroup.tasks.every((task) => task.completed);
};

export const hasIncompleteTasksOrEmpty = (taskGroup: PlanTaskGroup) => {
  return taskGroup.tasks.length === 0 || !allTasksCompletedInTaskGroup(taskGroup);
};

export const allTasksInSelectedRoles = (taskGroup: PlanTaskGroup, selectedRoles: Dictionary<PlanRole>) => {
  const tasksNotInSelectedRole = taskGroup.tasks.filter((task) => !selectedRoles[task.roleId]);
  return selectedRoles[taskGroup.roleId] !== undefined && tasksNotInSelectedRole.length === 0;
};

export const allTasksInRoleFilter = (state: TodayPageState, taskGroup: PlanTaskGroup) => {
  return allTasksInSelectedRoles(taskGroup, state.selectedRoles);
};

export const groupIndexThatWillBeGrouped = (
  state: TodayPageState,
  index: number,
  taskGroup: PlanTaskGroup,
  inFilter: (taskGroup: PlanTaskGroup) => boolean
): number | undefined => {
  if (index === 0) {
    return undefined;
  }
  for (let i = index; i > 0; i--) {
    const previousGroup = state.taskGroups[i - 1];
    if (inFilter(previousGroup)) {
      if (previousGroup.roleId === taskGroup.roleId) {
        return i - 1;
      } else {
        return undefined;
      }
    }
  }
  return undefined;
};

export const groupThatWillBeGrouped = (
  state: TodayPageState,
  index: number,
  taskGroup: PlanTaskGroup,
  inFilter: (taskGroup: PlanTaskGroup) => boolean
): PlanTaskGroup | undefined => {
  const groupIndex = groupIndexThatWillBeGrouped(state, index, taskGroup, inFilter);
  return groupIndex === undefined ? undefined : state.taskGroups[groupIndex];
};

export const firstGroupWithIncompleteTasksInTimerRole = (state: TodayPageState) => {
  return state.taskGroups.find((group) => {
    return group.tasks.find((task) => !task.completed && task.roleId === state.timerRoleId);
  });
};

export const onCanBeGrouped = (state: TodayPageState, index: number, taskGroup: PlanTaskGroup, inFilter: (taskGroup: PlanTaskGroup) => boolean) => {
  return groupThatWillBeGrouped(state, index, taskGroup, inFilter) !== undefined;
};

export const blocksInGuiderailForRole = (
  state: TodayPageState,
  role: PlanRole,
  filter: (block: RoleBlockType, index: number, role: PlanRole) => boolean
) => {
  return state.plannedBlocks.filter((block, index) => {
    return filter(block, index, role);
  }).length;
};
export const blocksLeftForRole = (state: TodayPageState, today: Date, time: Date, role: PlanRole) => {
  const currentTimeInMinutes = Math.max(time.getHours() * 60 + time.getMinutes(), 0);
  const currentBlock = Math.floor(currentTimeInMinutes / 30);
  const cmp = compareDates(today, time);
  if (cmp === 0) {
    return blocksInGuiderailForRole(
      state,
      role,
      (block, index, role) =>
        isBlockInPlannedTime(state, index) &&
        block.role?.id === role.id &&
        (block.blockType === PlannedBlockType.STRATEGIC || block.blockType === PlannedBlockType.AUTO_MEETING) &&
        index >= currentBlock
    );
  } else if (cmp < 0) {
    return 0;
  } else {
    return state.plannedBlocks.filter((block, index) => {
      return (
        isBlockInPlannedTime(state, index) &&
        block.role?.id === role.id &&
        (block.blockType === PlannedBlockType.STRATEGIC || block.blockType === PlannedBlockType.AUTO_MEETING)
      );
    }).length;
  }
};

export const blocksPastForRole = (state: TodayPageState, today: Date, time: Date, role: PlanRole) => {
  const currentTimeInMinutes = Math.max(time.getHours() * 60 + time.getMinutes(), 0);
  const currentBlock = Math.floor(currentTimeInMinutes / 30);
  const cmp = compareDates(today, time);
  if (cmp === 0) {
    return blocksInGuiderailForRole(
      state,
      role,
      (block, index, role) =>
        isBlockInPlannedTime(state, index) &&
        block.role?.id === role.id &&
        (block.blockType === PlannedBlockType.STRATEGIC || block.blockType === PlannedBlockType.AUTO_MEETING) &&
        index < currentBlock
    );
  } else if (cmp < 0) {
    return state.plannedBlocks.filter((block, index) => {
      return (
        isBlockInPlannedTime(state, index) &&
        block.role?.id === role.id &&
        (block.blockType === PlannedBlockType.STRATEGIC || block.blockType === PlannedBlockType.AUTO_MEETING)
      );
    }).length;
  } else {
    return 0;
  }
};

export const blocksLeftForRoleInPlan = (state: TodayPageState, role: PlanRole) => {
  return blocksLeftForRoleInTaskGroups(state.taskGroups, role);
};

export const blocksLeftForRoleInTaskGroups = (taskGroups: PlanTaskGroup[], role: PlanRole) => {
  return taskGroups.reduce((sum, group) => {
    const allTasksCompleted = group.tasks.every((task) => task.completed);
    if ((group.tasks.length === 0 || !allTasksCompleted) && group.roleId === role.id) {
      return sum + Math.max(0, group.timeBlockCount - group.completedTimeBlockCount);
    } else {
      return sum;
    }
  }, 0);
};

export const guideRailIsFree = (todayPageState: TodayPageState, startTimeBlockNumber: number | undefined, timeBlockCount: number) => {
  if (startTimeBlockNumber === undefined || timeBlockCount === 0) {
    return true;
  }
  for (let blockNumber = startTimeBlockNumber; blockNumber < startTimeBlockNumber + timeBlockCount; blockNumber++) {
    if (todayPageState.plannedBlocks[blockNumber].name !== ROLE_BLOCK_BREAK.name) {
      return false;
    }
  }
  return true;
};

export const setGuideRail = <T extends TodayPageState>(
  state: T,
  startTimeBlockNumber: number | undefined,
  timeBlockCount: number,
  roleBlock: RoleBlockType
) => {
  if (startTimeBlockNumber === undefined || timeBlockCount === 0) {
    return;
  }
  for (let blockNumber = startTimeBlockNumber; blockNumber < startTimeBlockNumber + timeBlockCount; blockNumber++) {
    state.plannedBlocks[blockNumber] = roleBlock;
  }
};

/**
 * Trys to move a meeting by updating the guide rail.
 * Returns true if the meeting was successfully updated, false otherwise.
 *
 * @param state the today page state
 * @param role the role to update the guide rail for
 * @param startTimeBlockNumber the new start time block number for the role
 * @param oldStartTimeBlockNumber the old start time block number for the role
 * @param timeBlockCount the new time block count for the role
 * @param oldTimeBlockCount the old time block count for the role
 *
 * @returns true if the guide rail was successfully updated, false otherwise.
 */
export const tryChangeMeeting = <T extends TodayPageState>(
  state: T,
  role: PlanRole,
  startTimeBlockNumber: number | undefined,
  oldStartTimeBlockNumber: number | undefined,
  timeBlockCount: number,
  oldTimeBlockCount: number
) => {
  // clear the old auto meeting blocks
  setGuideRail(state, oldStartTimeBlockNumber, oldTimeBlockCount, ROLE_BLOCK_BREAK);

  const autoMeetingBlock = createRoleBlockType(role, PlannedBlockType.AUTO_MEETING);
  // if the new auto meeting blocks are free, set them
  const canUpdate = guideRailIsFree(state, startTimeBlockNumber, timeBlockCount);
  if (canUpdate) {
    setGuideRail(state, startTimeBlockNumber, timeBlockCount, autoMeetingBlock);
  } else {
    // otherwise set the old auto meeting blocks back
    setGuideRail(state, oldStartTimeBlockNumber, oldTimeBlockCount, autoMeetingBlock);
  }
  return canUpdate;
};
