import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { NavigationContainer, NavigatorScreenParams } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "frontend-shared/apollo/apolloClient";
import { setAccessToken, setSiteAdmin } from "frontend-shared/util/auth";
import { LoginScreen } from "./app/loginScreen";
import { store } from "frontend-shared/store/store";
import { BottomTabs } from "./app/bottomTabs";
import { PlanRoleIdentity, PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { TimerProvider } from "./app/timerProvider/timerProvider";

const Stack = createStackNavigator();

export type IdentitiesStackParamList = {
  IdentitiesMain: undefined;
  IdentityScoreboard: { identity: PlanRoleIdentity };
};

export type PlansStackParamList = {
  PlansMain: undefined;
  TimeCard: { taskGroup: PlanTaskGroup };
};

export type CommunitiesStackParamList = {
  CommunitiesMain: undefined;
};

export type MomentumTabParamList = {
  Identities: NavigatorScreenParams<IdentitiesStackParamList>;
  Plans: undefined;
};

export type RootStackParamList = {
  Login: undefined;
  RTG: NavigatorScreenParams<MomentumTabParamList>;
};

function App() {
  const [loading, setLoading] = useState(true);

  // When we first load, try to get a new access token from the refresh token route
  // If we have logged in, a refresh token cookie will have been set. This route
  // will turn that into an access token. It will also let us know if the user is a
  // site admin. This only affects some UI, but won't give you any data access since that
  // will also be checked by any route or GQL request based on your authorization.
  useEffect(() => {
    fetch("/refresh-token", { method: "POST", credentials: "include" })
      .then(async (response) => {
        const { accessToken, isSiteAdmin } = await response.json();
        setAccessToken(accessToken);
        setSiteAdmin(isSiteAdmin);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <TimerProvider>
          <NavigationContainer>
            <Stack.Navigator initialRouteName="RTG">
              <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
              <Stack.Screen name="RTG" component={BottomTabs} options={{ headerShown: false }} />
            </Stack.Navigator>
          </NavigationContainer>
        </TimerProvider>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
