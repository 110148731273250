import React from "react";
import { View } from "react-native-web";
import { ViewStyle } from "react-native";
import { IdentityChip, IdentityChipProps } from "./identityChip";
import { PlanRoleIdentity } from "frontend-shared/util/modelTypes";
import { RoutineChip, RoutineChipProps } from "./routineChip";
import { StartTimeChip, StartTimeChipProps } from "./startTimeChip";

export interface TimeCardChipsProps extends IdentityChipProps, RoutineChipProps, StartTimeChipProps {
  roleIdentities: PlanRoleIdentity[];
  setRoleIdentityFor: (objectId: string, roleIdentity?: PlanRoleIdentity) => void;
  setTimeBlockNumberFor: (objectId: string, startTimeBlockNumber?: number) => void;
}

interface Props extends TimeCardChipsProps {
  objectId: string;
  routineId?: string;
  roleIdentityId?: string;
  startTimeBlockNumber?: number;
  timeBlockCount: number;

  containerStyle?: ViewStyle;
  chipsAreEditable?: boolean;
}

export const TimeCardChips: React.FC<Props> = (props) => {
  const { containerStyle } = props;

  return (
    <View style={[{ flexDirection: "row", alignItems: "center", flexShrink: 1, flexWrap: "wrap", gap: 4 }, containerStyle ?? {}]}>
      <IdentityChip {...props} />
      <RoutineChip {...props} />
      <StartTimeChip {...props} />
    </View>
  );
};
