import React from "react";
import { View } from "react-native-web";
import { Button, IconButton } from "react-native-paper";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { selectTodayPageState } from "frontend-shared/store/todayPageSlice";
import { apiDeleteTimeCardFromPlan } from "frontend-shared/api/today.api.taskGroup";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { TodayOptimisticAPIContext } from "frontend-shared/api/today.api.plan";
import { selectBacklogState } from "frontend-shared/store/backlogSlice";
import { selectIdentitiesState } from "frontend-shared/store/identitiesSlice";
import { selectRoutinesState } from "frontend-shared/store/routinesSlice";
import { selectSettingsState } from "frontend-shared/store/settingsSlice";
import { formatDateSimple, formatSecondsAsTimeCompact } from "frontend-shared/util/dateUtils";
import { TimerConsumer } from "src/app/timerProvider/timerConsumer";
import { selectTimerState } from "frontend-shared/store/timerSlice";
import { TimerRunningState } from "frontend-shared/util/timerRunningState";
import { TimerControlButtons } from "src/app/timerProvider/timerControlButtons";

interface Props {
  taskGroupId?: string;
  taskGroupName?: string | null;
}

export const TimeCardScreenHeaderButtons: React.FC<Props> = ({ taskGroupId, taskGroupName }) => {
  const dispatch = useAppDispatch();
  const identitiesState = useAppSelector(selectIdentitiesState);
  const todayPageState = useAppSelector(selectTodayPageState);
  const backlogState = useAppSelector(selectBacklogState);
  const routinesState = useAppSelector(selectRoutinesState);
  const settingsState = useAppSelector(selectSettingsState);
  const timerState = useAppSelector(selectTimerState);
  const makeAPIContext = (client: ApolloClient<object>): TodayOptimisticAPIContext => {
    return { client, todayPageState, backlogState, routinesState, settingsState, identitiesState, dispatch };
  };

  if (!taskGroupId) {
    return <></>;
  }
  const taskGroup = todayPageState.taskGroups.find((tg) => tg.id === taskGroupId);
  if (!taskGroup) {
    return <></>;
  }
  const isToday = formatDateSimple(new Date()) === todayPageState.date;

  const showStartTimer = isToday && timerState.timerRunningState === TimerRunningState.Stopped;

  const timeCardName = taskGroupName ?? "<unnamed time card>";

  return (
    <ApolloConsumer>
      {(client) => {
        const ctx = makeAPIContext(client);
        return (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <TimerConsumer>
              {(startTimer, pauseTimer) => (
                <>
                  {showStartTimer && (
                    <Button
                      icon={timerState.timerRunningState === TimerRunningState.Stopped ? "play" : "play"}
                      textColor="white"
                      onPress={() => startTimer(timerState, { id: taskGroupId, name: timeCardName })}
                    >
                      {formatSecondsAsTimeCompact(timerState.secondsLeftOnTimer)}
                    </Button>
                  )}
                  <TimerControlButtons timerState={timerState} />
                </>
              )}
            </TimerConsumer>
            <IconButton
              onPress={() => {
                apiDeleteTimeCardFromPlan(ctx, taskGroup);
              }}
              icon={"trash-can-outline"}
              iconColor="white"
            />
          </View>
        );
      }}
    </ApolloConsumer>
  );
};
