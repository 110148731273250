import React, { useState } from "react";
import { View } from "react-native";
import { Dialog, Button, Portal } from "react-native-paper";
import { PortalDialogOverlay } from "../components/portalDialogOverlay";

interface Props {
  title: string;
  visible: boolean;
  dateString: string;
  onDismiss: () => void;
  onConfirm: (dateString: string) => void;
  isDateDisabled?: (dateString: string) => boolean;
}

export const DatePickerDialog: React.FC<Props> = ({ title, visible, dateString, onDismiss, onConfirm, isDateDisabled }) => {
  const [selectedDateString, setSelectedDateString] = useState(dateString);

  React.useEffect(() => {
    setSelectedDateString(dateString);
  }, [dateString]);

  const isDisabled = isDateDisabled ? isDateDisabled(selectedDateString) : false;

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={onDismiss}>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Content>
          <View style={{ flexGrow: 1 }}>
            <input
              type="date"
              value={selectedDateString}
              onChange={(e) => {
                setSelectedDateString(e.target.value);
              }}
              style={{
                width: "100%",

                padding: 8,
                fontSize: 16,
                borderRadius: 4,
                flexGrow: 1,
                border: "1px solid #ccc",
              }}
            />
          </View>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={onDismiss}>Cancel</Button>
          <Button mode="contained" onPress={() => onConfirm(selectedDateString)} disabled={isDisabled}>
            OK
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
