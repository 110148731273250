import React from "react";
import { Pressable } from "react-native-gesture-handler";
import { IconButton, Menu, TextInput } from "react-native-paper";
import { View } from "react-native-web";
import { range } from "frontend-shared/util/arrayUtils";

interface Props {
  label: string;
  index: number;
  count: number;
  setIndex: (index: number) => void;
  menuLabel: (index: number) => string;
  iconSource: string;
}

export const DropDownSelectMenu: React.FC<Props> = ({ label, index, setIndex, menuLabel, count, iconSource }) => {
  const [menuVisible, setMenuVisible] = React.useState(false);
  return (
    <View style={{ flexDirection: "row", gap: "8px", alignItems: "center" }}>
      <Pressable onPress={() => setMenuVisible(true)}>
        <TextInput label={label} value={menuLabel(index)} editable={false} />
      </Pressable>
      <Menu
        anchor={
          <IconButton
            size={20}
            icon={iconSource}
            iconColor="white"
            mode="contained"
            style={{ backgroundColor: "#aaa" }}
            onPress={() => setMenuVisible(true)}
          />
        }
        visible={menuVisible}
        onDismiss={() => setMenuVisible(false)}
      >
        {range(count).map((index) => (
          <Menu.Item
            key={index}
            title={menuLabel(index)}
            onPress={() => {
              setIndex(index);
              setMenuVisible(false);
            }}
          />
        ))}
      </Menu>
    </View>
  );
};
