import React from "react";
import { FAB, useTheme } from "react-native-paper";

interface BottomFabGroupAction {
  icon: string;
  label?: string;
  onPress: () => void;
}

interface Props {
  closedIcon: string;
  openIcon: string;
  extended?: boolean;
  actions: BottomFabGroupAction[];
}

export const BottomFabGroup: React.FC<Props> = ({ openIcon, closedIcon, actions, extended }) => {
  const [state, setState] = React.useState({ open: false });
  const { open } = state;

  const theme = useTheme();

  return (
    <FAB.Group
      visible
      open={open}
      icon={open ? openIcon : closedIcon}
      actions={actions.map((a) => {
        return { ...a, style: { backgroundColor: theme.colors.primary } };
      })}
      onStateChange={setState}
    />
  );
};
