import React from "react";
import { Text, Card, Icon, Chip, IconButton, useTheme } from "react-native-paper";
import { SharedObjectForScreen } from "frontend-shared/util/modelTypes";

import { TimeBlocks } from "./timeBlocks";
import { View } from "react-native-web";

export interface SharedTimeCardProps {
  reactToSharedObject: (sharedObjectId: string, reactionType: string) => void;
  unreactToSharedObject: (sharedObjectId: string, reactionType: string) => void;
}

interface Props extends SharedTimeCardProps {
  sharedObject: SharedObjectForScreen;
}

export const SharedTimeCard: React.FC<Props> = (props) => {
  const { sharedObject, reactToSharedObject, unreactToSharedObject } = props;
  const theme = useTheme();

  const currentReactions = sharedObject.selfReactions;
  const liked = currentReactions.some((reaction) => reaction.type === "Like");
  const likeCount = sharedObject.reactionSummary.find((reaction) => reaction.type === "Like")?.count ?? 0;

  const sharedObjectData = JSON.parse(sharedObject.sharedObjectDataJSON);

  return (
    <Card style={{ marginLeft: "16px", marginRight: "16px", paddingBottom: "16px" }}>
      <Card.Title
        title={
          <Text style={{ userSelect: "none", WebkitUserSelect: "none" }} variant="titleLarge">
            {sharedObjectData.name ?? ""}
          </Text>
        }
        right={(cardProps) => (
          <TimeBlocks
            showCompleted={true}
            containerStyle={{ marginRight: "16px" }}
            roleIdentities={[]}
            fallbackIdentityColor={sharedObjectData.identityColor}
            objectId={sharedObject.id}
            timeBlockCount={sharedObjectData.timeBlockCount}
            completedTimeBlockCount={sharedObjectData.completedTimeBlockCount}
          />
        )}
      />
      <Card.Content style={{ gap: 14, position: "relative" }}>
        <View>
          {sharedObjectData.identityName && (
            <Chip
              style={{ marginBottom: "8px", backgroundColor: sharedObjectData.identityColor, alignSelf: "flex-start" }}
              icon={() => <Icon size={16} source={"account-circle-outline"} color="white" />}
            >
              {sharedObjectData.identityName}
            </Chip>
          )}
          <View style={{ gap: "4px" }}>
            {sharedObjectData.tasks.map((task: { id: string; name: string; completed: boolean }) => (
              <View key={task.id} style={{ flexDirection: "row", gap: "2px", alignItems: "flex-start" }}>
                <Icon source={task.completed ? "checkbox-marked" : "square-outline"} size={20} color="#ccc" />
                <Text>{task.name}</Text>
              </View>
            ))}
          </View>
        </View>
        {
          <View style={{ position: "absolute", bottom: "-16px", right: "0px", flexDirection: "row", gap: "1px", alignItems: "center" }}>
            <Text>{likeCount === 0 ? "" : likeCount}</Text>
            {sharedObjectData.isSharedBySelf ? (
              likeCount > 0 ? (
                <View style={{ marginRight: "16px", marginBottom: "16px", marginTop: "16px", marginLeft: "8px" }}>
                  <Icon source="thumb-up" size={16} color={"#aaa"} />
                </View>
              ) : (
                <></>
              )
            ) : (
              <IconButton
                size={16}
                iconColor={liked ? theme.colors.primary : "#aaa"}
                icon="thumb-up"
                style={{
                  backgroundColor: "#ddd",
                }}
                onPress={() => {
                  if (liked) {
                    unreactToSharedObject(sharedObject.id, "Like");
                  } else {
                    reactToSharedObject(sharedObject.id, "Like");
                  }
                }}
              />
            )}
          </View>
        }
      </Card.Content>
    </Card>
  );
};
