import React from "react";
import { Button, Dialog, Portal, TextInput } from "react-native-paper";
import { PortalDialogOverlay } from "../../components/portalDialogOverlay";

export interface RoutineDialogProps {
  visible: boolean;
  cancel: () => void;
  save: (routineName: string) => void;
}

export interface Props extends RoutineDialogProps {
  routineName: string;
}

export const RoutineDialog: React.FC<Props> = ({ routineName, visible, cancel, save }) => {
  const [newRoutineName, setNewRoutineName] = React.useState(routineName);
  const [initialized, setInitialized] = React.useState(false);

  if (!visible && initialized) {
    setNewRoutineName("");
    setInitialized(false);
    return <></>;
  } else if (visible && !initialized) {
    setNewRoutineName(routineName);
    setInitialized(true);
    return <></>;
  }

  return (
    <Portal>
      <PortalDialogOverlay visible={visible} />
      <Dialog visible={visible} onDismiss={cancel}>
        <Dialog.Title>Create Routine</Dialog.Title>
        <Dialog.Content style={{ gap: "8px" }}>
          <TextInput
            label="Routine Name"
            autoFocus={true}
            value={newRoutineName}
            onChangeText={(text) => setNewRoutineName(text)}
            onSubmitEditing={() => {
              if (newRoutineName !== "") {
                save(newRoutineName);
              }
            }}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === "Escape") {
                cancel();
              }
            }}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={cancel}>Cancel</Button>
          <Button
            mode="contained"
            onPress={() => {
              save(newRoutineName);
            }}
            disabled={newRoutineName === ""}
          >
            Create Routine
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
