import Cookies from "js-cookie";
import { formatTimerState, parseTimerState, TimerRunningState } from "frontend-shared/util/timerRunningState";
import { TimerType, formatTimerType, parseTimerType } from "frontend-shared/util/timerType";
import { TimerState } from "../store/timerSlice";

export const DEFAULT_COOKIE_EXPIRATION = 365 * 10;

export interface TimerCookies {
  timerRunningState: TimerRunningState;
  secondsLeft: number;
  dateOfLastTickISOString: string;
  timerType: TimerType;
  timeCardId: string;
  timeCardName: string;
}

export const getTimerCookies = (): TimerCookies => {
  const timerRunningState = parseTimerState(Cookies.get("timerRunningState"));
  const secondsLeft = parseInt(Cookies.get("timerSecondsLeft") || "-1");
  const timerType = parseTimerType(Cookies.get("timerType") || formatTimerType(TimerType.Work));
  const dateOfLastTickISOString = Cookies.get("timerDateOfLastTick") ?? new Date().toISOString();
  const timeCardId = Cookies.get("timerTimeCardId") ?? "";
  const timeCardName = Cookies.get("timerTimeCardName") ?? "";
  return { timerRunningState, secondsLeft, dateOfLastTickISOString, timerType, timeCardId, timeCardName };
};

export const setTimerCookies = (timerState: TimerState) => {
  if (timerState.initialized) {
    Cookies.set("timerRunningState", formatTimerState(timerState.timerRunningState), { expires: DEFAULT_COOKIE_EXPIRATION });
    Cookies.set("timerSecondsLeft", timerState.secondsLeftOnTimer.toString(), { expires: DEFAULT_COOKIE_EXPIRATION });
    Cookies.set("timerType", formatTimerType(timerState.timerType), { expires: DEFAULT_COOKIE_EXPIRATION });
    Cookies.set("timerDateOfLastTick", timerState.dateOfLastTickISOString, { expires: DEFAULT_COOKIE_EXPIRATION });
    Cookies.set("timerTimeCardId", timerState.timeCard?.id ?? "", { expires: DEFAULT_COOKIE_EXPIRATION });
    Cookies.set("timerTimeCardName", timerState.timeCard?.name ?? "", { expires: DEFAULT_COOKIE_EXPIRATION });
  }
};

export interface PlanToolbarCookies {
  selectedRoleIds: string[] | undefined;
  roleView: string;
  roleViewCompleted: string;
  defaultRoleId: string;
}

export const getPlanToolbarCookies = (): PlanToolbarCookies => {
  const roleView = Cookies.get("roleView") ?? "Interspersed";
  const roleViewCompleted = Cookies.get("roleViewCompleted") ?? "Normal";
  const defaultRoleId = Cookies.get("defaultRoleId") ?? "";
  const selectedRoleIds = Cookies.get("selectedRoleIds")?.split(",");
  // When we read "" cookie as an array, use [], not [""]
  if (selectedRoleIds?.length === 1 && selectedRoleIds[0] === "") {
    return { selectedRoleIds: [], roleView, roleViewCompleted, defaultRoleId };
  }
  return { selectedRoleIds, roleView, roleViewCompleted, defaultRoleId };
};

export const setPlanToolbarCookies = (cookies: PlanToolbarCookies) => {
  Cookies.set("selectedRoleIds", cookies?.selectedRoleIds?.join(",") ?? "", { expires: DEFAULT_COOKIE_EXPIRATION });
  Cookies.set("roleView", cookies.roleView, { expires: DEFAULT_COOKIE_EXPIRATION });
  Cookies.set("roleViewCompleted", cookies.roleViewCompleted, { expires: DEFAULT_COOKIE_EXPIRATION });
  Cookies.set("defaultRoleId", cookies.defaultRoleId ?? "", { expires: DEFAULT_COOKIE_EXPIRATION });
};

export const setRoleViewCookies = (roleView: string) => {};
