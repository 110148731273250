import React from "react";
import { Text, Card, IconButton } from "react-native-paper";
import { EditablePlanTaskGroup, PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { TaskList } from "./taskList";
import { TimeBlocks } from "./timeBlocks";
import { TaskProps } from "./taskWithCheckbox";
import { EditTimeCardDialog } from "./editTimeCardDialog";
import { TimeCardChips, TimeCardChipsProps } from "./timeCardChips";
import { View } from "react-native-web";
import { TimeCardMenu, TimeCardMenuProps } from "./timeCardMenu";

export interface TimeCardProps extends TaskProps, TimeCardChipsProps, TimeCardMenuProps {
  timeCardTapped: (taskGroup: PlanTaskGroup) => void;
  editTimeCard: (originalTimeCard: PlanTaskGroup, timeCard: EditablePlanTaskGroup) => void;
}

interface Props extends TimeCardProps {
  taskGroup: PlanTaskGroup;
  isDragging: boolean;
}

export const TimeCardEmbedded: React.FC<Props> = (props) => {
  const { taskGroup, roleIdentities, timeCardTapped, editTimeCard, isDragging } = props;
  const [editTimeCardDialogVisible, setEditTimeCardDialogVisible] = React.useState(false);

  const showCollapsed = taskGroup.tasks.length > 1 && taskGroup.collapsed;

  return (
    <View style={{ position: "relative" }}>
      <Card
        style={{ marginLeft: "16px", marginRight: "16px", paddingBottom: showCollapsed ? "32px" : "16px" }}
        onPress={() => (isDragging ? null : timeCardTapped(taskGroup))}
      >
        <Card.Title
          title={
            taskGroup.name ? (
              <Text style={{ userSelect: "none", WebkitUserSelect: "none" }} variant="titleLarge" onPress={() => setEditTimeCardDialogVisible(true)}>
                {taskGroup.name}
              </Text>
            ) : (
              <IconButton icon="pencil" onPress={() => setEditTimeCardDialogVisible(true)} />
            )
          }
          right={(cardProps) => (
            <TimeBlocks
              {...props}
              objectId={taskGroup.id}
              roleIdentityId={taskGroup.roleIdentityId}
              timeBlockCount={taskGroup.timeBlockCount}
              completedTimeBlockCount={taskGroup.completedTimeBlockCount}
              showCompleted={true}
              containerStyle={{ marginRight: "16px" }}
              setTimeBlockCount={() => timeCardTapped(taskGroup)}
            />
          )}
        />
        <Card.Content>
          <View style={{ flexDirection: "row" }}>
            <TimeCardChips
              {...props}
              objectId={taskGroup.id}
              routineId={taskGroup.routineId}
              roleIdentityId={taskGroup.roleIdentityId}
              startTimeBlockNumber={taskGroup.startTimeBlockNumber}
              timeBlockCount={taskGroup.timeBlockCount}
            />
            <View style={{ flexGrow: 1 }}></View>
            <TimeCardMenu {...props} />
          </View>
          <TaskList {...props} tasks={taskGroup.tasks} objectId={taskGroup.id} collapsed={taskGroup.collapsed} />
        </Card.Content>

        <EditTimeCardDialog
          timeCard={taskGroup}
          objectId={taskGroup.id}
          roleIdentities={roleIdentities}
          visible={editTimeCardDialogVisible}
          cancel={() => setEditTimeCardDialogVisible(false)}
          save={(editedTimeCard) => {
            editTimeCard(taskGroup, editedTimeCard);
            setEditTimeCardDialogVisible(false);
          }}
        />
      </Card>
      {showCollapsed && (
        <>
          <View
            style={{
              backgroundColor: "#ddd",
              border: "1px solid #999",
              position: "absolute",
              zIndex: -10,
              height: 20,
              bottom: -4,
              left: 16,
              right: 16,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            }}
          />
          <View
            style={{
              backgroundColor: "#ddd",
              border: "1px solid #999",
              position: "absolute",
              zIndex: -12,
              height: 20,
              bottom: -6,
              left: 24,
              right: 24,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            }}
          />
        </>
      )}
    </View>
  );
};
