import React, { useEffect } from "react";
import { ScrollView, View } from "react-native-web";
import { Icon, Text, useTheme } from "react-native-paper";
import { range } from "frontend-shared/util/arrayUtils";
import { PlanRoleIdentity, PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { formatSecondsTimeMinimum, compareDates, formatSecondsTimeSimple, SECONDS_IN_A_TIMEBLOCK } from "frontend-shared/util/dateUtils";
import { GuideRailDialog } from "./guideRailDialog";
import {
  GUIDERAIL_BETWEEN_BLOCK_PADDING,
  GUIDERAIL_BLOCK_SIZE,
  createBlockPropsFromTaskGroups,
  lastBlockInGroup,
  numBlocksOnGuideRail,
  GUIDERAIL_TIMEBLOCK_PADDING,
  timeHeightOnGuideRail,
  firstBlockInGroup,
} from "./guideRailUtils";
import { timerEffectFn } from "frontend-shared/store/timerEffect";

export interface GuideRailProps {
  planDate: Date;
  taskGroups: PlanTaskGroup[];
  roleIdentities: PlanRoleIdentity[];
  startTimeBlockNumber: number;
  endTimeBlockNumber: number;
  defaultStartTimeBlockNumber: number;
  defaultEndTimeBlockNumber: number;
  showGuideRailTimeInterval: number;
  setGuideRailSettings(
    startTimeBlockNumber: number,
    endTimeBlockNumber: number,
    defaultStartTimeBlockNumber: number,
    defaultEndTimeBlockNumber: number,
    showGuideRailTimeInterval: number
  ): void;
}

export const GuideRail: React.FC<GuideRailProps> = ({
  planDate,
  taskGroups,
  roleIdentities,
  startTimeBlockNumber,
  endTimeBlockNumber,
  defaultStartTimeBlockNumber,
  defaultEndTimeBlockNumber,
  showGuideRailTimeInterval,
  setGuideRailSettings,
}) => {
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogKey, setDialogKey] = React.useState(0);
  const [timeHeight, setTimeHeight] = React.useState(0);
  const [startTimeUsed, setStartTimeUsed] = React.useState(0);
  const [endTimeUsed, setEndTimeUsed] = React.useState(0);

  const blockProps = createBlockPropsFromTaskGroups(startTimeBlockNumber, taskGroups, roleIdentities);

  const theme = useTheme();

  const updateTimeHeight = (currentTime: Date) => {
    const isToday = compareDates(currentTime, planDate) === 0;
    if (!isToday) {
      return;
    }
    const newHeight = timeHeightOnGuideRail(currentTime, blockProps, startTimeBlockNumber, endTimeBlockNumber);
    if (newHeight === timeHeight) {
      return;
    }
    setTimeHeight(newHeight);
    setStartTimeUsed(startTimeBlockNumber);
    setEndTimeUsed(endTimeBlockNumber);
  };

  useEffect(() => {
    return timerEffectFn(true, 1000, updateTimeHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockProps, startTimeBlockNumber, endTimeBlockNumber, setTimeHeight, setStartTimeUsed, setEndTimeUsed, startTimeBlockNumber]);

  if (startTimeBlockNumber !== startTimeUsed || endTimeBlockNumber !== endTimeUsed) {
    updateTimeHeight(new Date());
  }

  return (
    <ScrollView contentContainerStyle={{ flex: 1, flexGrow: 0, width: GUIDERAIL_BLOCK_SIZE + 44, alignItems: "center", gap: 8 }}>
      <Text variant="bodySmall" onPress={() => setDialogVisible(true)}>
        {formatSecondsTimeSimple(startTimeBlockNumber * SECONDS_IN_A_TIMEBLOCK)}
      </Text>
      <View
        style={{
          width: GUIDERAIL_BLOCK_SIZE + 24,
          position: "relative",
          padding: GUIDERAIL_TIMEBLOCK_PADDING,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {range(numBlocksOnGuideRail(startTimeBlockNumber, endTimeBlockNumber)).map((i, _, array) => {
          const thickness = blockProps[i]?.startTimeBlockNumber ? 2 : 1;
          const topThickness = firstBlockInGroup(blockProps, i) ? thickness : thickness / 2;
          const bottomThickness = lastBlockInGroup(blockProps, i) || i === array.length - 1 ? thickness : thickness / 2;

          return (
            <View
              key={i}
              style={{
                position: "relative",
                height: GUIDERAIL_BLOCK_SIZE,
                width: GUIDERAIL_BLOCK_SIZE,
                backgroundColor: blockProps[i]?.color,
                borderLeft: `${thickness}px solid black`,
                borderRight: `${thickness}px solid black`,
                borderTop: `${topThickness}px solid black`,
                borderBottom: `${bottomThickness}px solid black`,
                marginBottom: lastBlockInGroup(blockProps, i) ? GUIDERAIL_BETWEEN_BLOCK_PADDING : 0,
              }}
            >
              <View style={{ visibility: blockProps[i]?.icon ? "visible" : "hidden", margin: "auto" }}>
                <Icon size={GUIDERAIL_BLOCK_SIZE - 6} source="circle" color={blockProps[i]?.iconColor} />
              </View>
              <View
                style={{
                  position: "absolute",
                  top: -GUIDERAIL_BLOCK_SIZE / 2,
                  left: -12,
                  alignItems: "center",
                }}
              >
                {showGuideRailTimeInterval !== 0 && (i + startTimeBlockNumber) % (showGuideRailTimeInterval * 4) === 0 && i !== 0 && (
                  <Text
                    variant="labelSmall"
                    style={{
                      backgroundColor: theme.colors.surface,
                      border: "0.5px solid black",
                      borderRadius: 4,
                      padding: 1,
                      boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 1)",
                    }}
                  >
                    {formatSecondsTimeMinimum((i + startTimeBlockNumber) * SECONDS_IN_A_TIMEBLOCK)}
                  </Text>
                )}
              </View>
            </View>
          );
        })}
        <View style={{ position: "absolute", backgroundColor: "#cecece", top: 0, width: "100%", height: timeHeight, zIndex: -5 }}></View>
      </View>
      <Text variant="bodySmall" onPress={() => setDialogVisible(true)}>
        {formatSecondsTimeSimple((endTimeBlockNumber + 1) * SECONDS_IN_A_TIMEBLOCK)}
      </Text>
      <GuideRailDialog
        key={dialogKey}
        planDate={planDate}
        visible={dialogVisible}
        save={(startTimeBlockNumber, endTimeBlockNumber, defaultStartTimeBlockNumber, defaultEndTimeBlockNumber, showGuideRailTimeInterval) => {
          setGuideRailSettings(
            startTimeBlockNumber,
            endTimeBlockNumber - 1,
            defaultStartTimeBlockNumber,
            defaultEndTimeBlockNumber - 1,
            showGuideRailTimeInterval
          );
          setDialogKey(dialogKey + 1);
          setDialogVisible(false);
        }}
        cancel={() => {
          setDialogKey(dialogKey + 1);
          setDialogVisible(false);
        }}
        startTimeBlockNumber={startTimeBlockNumber}
        endTimeBlockNumber={endTimeBlockNumber + 1}
        defaultStartTimeBlockNumber={defaultStartTimeBlockNumber}
        defaultEndTimeBlockNumber={defaultEndTimeBlockNumber + 1}
        showGuideRailTimeInterval={showGuideRailTimeInterval}
      />
    </ScrollView>
  );
};
