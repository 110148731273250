import { ApolloClient } from "@apollo/client";

import { AppDispatch } from "../store/store";
import {
  initializeUserCommunitiesFullState,
  reactToCommunitySharedObject,
  unreactToCommunitySharedObject,
  UserCommunitiesState,
} from "../store/userCommunitiesSlice";
import {
  requestCommunitiesScreenFull,
  requestCommunitiesScreenFullAllResults,
  requestReactToSharedObject,
  requestShareTaskGroupToCommunity,
  requestUnreactToSharedObject,
} from "../generated/graphqlWrappers";
import { optimisticUpdateAndServerRequestWithUndo } from "./api.utils";

export interface UserCommunityOptimisticAPIContext {
  client: ApolloClient<object>;
  userCommunitiesState: UserCommunitiesState;
  dispatch: AppDispatch;
}

export const apiShareTaskGroupToCommunity = (
  apiContext: UserCommunityOptimisticAPIContext,
  communityId: string,
  taskGroupId: string,
  onSuccess: () => void,
  onError: (err: string) => void
) => {
  const { client } = apiContext;

  // There is no need to do an optimistic update because we don't keep track of shares.
  requestShareTaskGroupToCommunity(client, { communityId, taskGroupId }, onSuccess, onError);
};

export const apiLoadUserCommunitiesFull = async (
  apiContext: UserCommunityOptimisticAPIContext,
  onSuccess: () => void,
  onError: (err: string) => void
) => {
  const { client, dispatch } = apiContext;
  requestCommunitiesScreenFullAllResults(
    client,
    {},
    (data) => {
      dispatch(initializeUserCommunitiesFullState({ data }));
      onSuccess();
    },
    onError
  );
};

export const apiReactToSharedObject = async (
  apiContext: UserCommunityOptimisticAPIContext,
  communityId: string,
  sharedObjectId: string,
  reactionType: string
) => {
  const { client, dispatch } = apiContext;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: reactToCommunitySharedObject({ communityId, sharedObjectId, reactionType }),
    request: requestReactToSharedObject,
    variables: { communityId, sharedObjectId, reactionType },
    undo: unreactToCommunitySharedObject({ communityId, sharedObjectId, reactionType }),
  });
};

export const apiUnreactToSharedObject = async (
  apiContext: UserCommunityOptimisticAPIContext,
  communityId: string,
  sharedObjectId: string,
  reactionType: string
) => {
  const { client, dispatch } = apiContext;
  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: unreactToCommunitySharedObject({ communityId, sharedObjectId, reactionType }),
    request: requestUnreactToSharedObject,
    variables: { communityId, sharedObjectId, reactionType },
    undo: reactToCommunitySharedObject({ communityId, sharedObjectId, reactionType }),
  });
};
