import React, { useEffect } from "react";
import { useCommunitiesScreenQuery } from "frontend-shared/generated/graphql";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { clearUserCommunitiesState, initializeUserCommunitiesState, selectUserCommunitiesState } from "./userCommunitiesSlice";

export const CommunitiesStateLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, loading, error } = useCommunitiesScreenQuery({
    variables: {},
    fetchPolicy: "network-only",
  });
  const communitiesState = useAppSelector(selectUserCommunitiesState);

  // You need an effect to deal with loading state because you want to change state
  // via a dispatch while loading. You can't do that in the render function.
  useEffect(() => {
    if (loading) {
      dispatch(clearUserCommunitiesState());
    } else if (data && !communitiesState.initialized) {
      dispatch(initializeUserCommunitiesState({ data }));
    }
  });

  if (loading) {
    return <></>;
  } else if (error) {
    return <></>;
  } else if (!data) {
    return <></>;
  } else if (!communitiesState.initialized) {
    return <></>;
  }

  return <></>;
};
