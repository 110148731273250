import { GestureHandlerRootView } from "react-native-gesture-handler";
import "react-native-reanimated";
import "./index.css";
import App from "./App";
import { AppRegistry } from "react-native";
import { MD3LightTheme, PaperProvider } from "react-native-paper";
import { Platform } from "react-native";
import appConfig from "./app.json";
import { momentumTheme } from "./momentumTheme";

export default function Main() {
  const lightThemeColors = momentumTheme["schemes"]["light-medium-contrast"];
  const theme = {
    ...MD3LightTheme,
    colors: {
      ...lightThemeColors,
    },
  };
  return (
    <PaperProvider theme={theme}>
      {Platform.OS === "web" ? (
        <style type="text/css">{`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require("react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf")}) format('truetype');
        }
      `}</style>
      ) : null}
      <GestureHandlerRootView>
        <App />
      </GestureHandlerRootView>
    </PaperProvider>
  );
}

AppRegistry.registerComponent(appConfig.name, () => Main);

const rootTag = document.getElementById("root") || document.createElement("div");
AppRegistry.runApplication(appConfig.name, {
  initialProps: {},
  rootTag,
});
