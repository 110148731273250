import React from "react";
import { Button, Checkbox, Dialog, Portal, Text } from "react-native-paper";
import { PortalDialogOverlay } from "../components/portalDialogOverlay";
import { View } from "react-native-web";

interface Props {
  open: boolean;
  markTimeBlockAsCompleted: () => void;
  startTimerOnTimeCard: () => void;
  onClose: () => void;
  timeCard?: { id: string; name: string };
}

export const EndTimerPromptDialog: React.FC<Props> = ({ open, onClose, timeCard, markTimeBlockAsCompleted, startTimerOnTimeCard }) => {
  const [autoCompleteTimeBlock, setAutoCompleteTimeBlock] = React.useState(false);
  const [startAnotherTimer, setStartAnotherTimer] = React.useState(false);

  return (
    <Portal>
      <PortalDialogOverlay visible={open} />
      <Dialog visible={open} onDismiss={onClose}>
        <Dialog.Title>What would you like to do now?</Dialog.Title>

        <Dialog.Content>
          {timeCard && (
            <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
              <Checkbox
                status={autoCompleteTimeBlock ? "checked" : "unchecked"}
                onPress={() => {
                  setAutoCompleteTimeBlock(!autoCompleteTimeBlock);
                }}
              />
              <Text style={{ flexWrap: "wrap", flexShrink: 1, marginTop: 9 }} selectable={false} onPress={() => {}}>
                Mark the next time block for <strong>{timeCard.name}</strong> as completed
              </Text>
            </View>
          )}
          <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
            <Checkbox
              status={startAnotherTimer ? "checked" : "unchecked"}
              onPress={() => {
                setStartAnotherTimer(!startAnotherTimer);
              }}
            />
            <Text style={{ flexWrap: "wrap", flexShrink: 1, marginTop: 9 }} selectable={false} onPress={() => {}}>
              Start another timer for this time card
            </Text>
          </View>
          <Dialog.Actions>
            <Button onPress={onClose}>Cancel</Button>
            <Button
              mode="contained"
              onPress={() => {
                if (autoCompleteTimeBlock) {
                  markTimeBlockAsCompleted();
                }
                if (startAnotherTimer) {
                  startTimerOnTimeCard();
                }
                onClose();
              }}
            >
              OK
            </Button>
          </Dialog.Actions>
        </Dialog.Content>
      </Dialog>
    </Portal>
  );
};
