import React from "react";
import { EditablePlanRoleIdentity } from "frontend-shared/util/modelTypes";
import { IdentityDialog, IdentityDialogProps } from "./identityDialog";

interface Props extends IdentityDialogProps {
  identity: EditablePlanRoleIdentity;
}

export const EditIdentityDialog: React.FC<Props> = (props) => {
  return <IdentityDialog title="Edit Identity" saveButtonLabel={"Save Identity"} {...props} />;
};
